











































































































































thead tr {
    background-color: #FAFAFA;
    border-bottom: 1px solid #e5e5e5;
}

.list-headline {
    display: grid;
    grid-template-columns: 90px 110px 130px 190px 140px;
    padding: 10px 0;

    th {
        text-align: right;
        font-size: 12px;
        color: #555555;

        &:first-child {
            text-align: left;
            padding-left: 10px;
        }

        &:last-child {
            text-align: left;
            padding-right: 10px;
        }
    }
}

.table-column {
    display: grid;
    cursor: pointer;
    grid-template-columns: 90px 110px 130px 190px 140px;
    grid-template-rows: 48px auto;
    grid-template-areas: "td1 td2 td3 td4 td5" "dr dr dr dr dr";

    td {
        font-size: 12px;
        text-align: right;
        font-weight: 700;
        color: #888888;
        padding: 16px 0 16px 0;

        &:first-child {
            text-align: left;
            padding-left: 10px;
        }
    }

    .table-column-link {
        display: flex;
        justify-content: flex-end;
        grid-area: td5;
        padding-right: 10px;

        div {
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
            min-width: 40px;
            max-width: 120px;
            text-align: right;


            div {
                display: inline-block;
                flex: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            svg {
                height: 12px;
                width: 15px;
                margin-right: -4px;
                stroke: #787878;
                stroke-width: 50px;
                cursor: pointer;
                transition: transform 0.24s ease 0s;
                text-align: center;
                text-transform: none;
                vertical-align: -0.125em;
                position: relative;
                top: 4px;
            }

            .is-expanded {
                transform: rotate(90deg);
            }
        }
    }

    .dropdown {
        grid-area: dr;
        font-size: 12px;
        color: #787878;
        line-height: 1.5;
        letter-spacing: .3px;
        min-height: 2px;
        max-height: 100%;
        padding: 0;
        border-bottom: 1px solid #e5e5e5;


        &-container-list {
            padding: 10px 0 22px 12px;
            margin: 0;
            list-style: none;
            font-size: 12px;
            color: #787878;
            line-height: 1.5;
            letter-spacing: .3px;

            li {
                margin-bottom: 12px;
                padding-left: 30px;
                position: relative;
                text-align: left;

                &:before {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    left: 0px;
                    top: 4px;
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAAAXNSR0IArs4c6QAAAfZJREFUKBV9Uk1IVFEUPufdaYZRCAmRQCg3Ek2g49BOHWeQiPHZomWrgjYlSOAiahEELly1UDCirWSrWkTP16JwnBIipjcjtZA2ZVQE0w/B2MOYuafvjlx5SnY359zvnO+793z3MkWW/2LtmDT0FWI6SUIplD4J0XPk825+oGpbvVLlAokMsQWWipWrwjQNIG6xSIQGzRWy6Sm/VD2HfIFI3rbI/kpwTYhnIs37pHwLpCnc5DMLDStvNTgqmh9BZecW+zCX6tn0xfjG1x4QzxfyAx8cbjiTYDmGgLu9Q/ixlyxCT+ud8bOHXq134Ixvhmh6QJKCbWbtjCqtBiHy02Iw5mWbdJxJhmFsKwyfCemJcrl8YJssdMQ2CuuJ0/m+dSI1YgSE5HVSEqdqXb+1qiuPmfuZOPl9M3F4mxyZlZmue6XgkjvS90a0k42369FaLhW21/48gCM5ewiRjpk8JiwfoXbcFlj4tlcMfrm5/vsG84qVRYiO27qINJvdbV/MPgZFH07tkIEZ1xe8lepBFn0CO/OukcXLY729WwZwSNEc5tORKmE2xSR3kExG8VaueNZijjuY2WCRmxb4X4SBD93h9GPb03rfsVxmGj/sBp6lYQv/iHc3OxO7Rtj1q56U1jJNrS8zSwY+pPDX38PMAKL3cIC/V/AvJoe10PZ+ikkAAAAASUVORK5CYII=');
                }

                a {
                    color: #888888;
                }
            }

            &.is-download li:before {
                width: 18px;
                height: 21px;
                top: -2px;
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAWCAYAAAAinad/AAAAAXNSR0IArs4c6QAAArBJREFUOBGVVF9IU2EUP+fbvUm1/urrXiKiXsorC4bN6SBDZyE99BpBkQ9ZQVFBb0YUIgRRD/15iIgeQ8ici4g59aFoco0IgmEEgaWUBi6n7t57Ot/Vb21uuvk97Hzn/H7n95193zkXoMyKJsye6KDZW4bmwlp5EhmEuKs8D0BUQqqUg6sRXyVG9wFgPQqaBFssIFCA0Bm3PN6+9uDe2VJ5BWLRVKoKJmY7mXiRhXwyQQhqtkD7Khx7XPoElEHAhxsd6AqHjT8yplZO7E0yuS371xNnwFCgtCvFchjRbwGe4y1NB4ZVzL2zZDKpZ9MixkFXiADeK0KxpU9c3RwgVjtovx2IjwUVxxWbSnvuMhhYDn5oazQCXPIpFnUU0bUEj1tDRi3vny3FUXfQ6Y3HP3ulL2Lx0d2E0LEEur8Ho0Njna2NxlMQ2E6WmEdh2UR0OdJknI2NjIWYdVLxEbEmI7LnpK85As9wFYUtQnSPBSHSUHtfJbG9MzBsNpENMRaoyovzozgn2O/G/kHTRARZevFCPB8JLQkyr5kP7QOEAqHlJEpPpnTsT5jTTNpRrKQi9IDbZISb4glbXUVX2rSe2YQ8d/OrnLaSv6bvmRdeAUhTa7IqBL9VZxc1IvzCd+Z2+3KeuV2vOVRf78uU0uFXFdEh8xFPwen/OP3o8PuzGgp8CUTNOYBgz8ziry6+y7lcLG8zkDDlyx/mq8lfL6SjOSieI9m3GNviogibeX8ln1mw579RoEOwoGlwW3LE0Yb9M4xeKkhYn3PjSLBuQqbkDuF76Obuu7o+HXrN49XKTcyTl9f5kZBxjQf4ZoVi/A7Qkw4ZbUpI5uUqUyLR+McAoH2dkZYSTcoV0Dty4EJbuC6pcpQtElOA/Cz9zGzwoWXtFAg6OPa0tRW/H/P7S76yzPsHG7LxNZdUQX0AAAAASUVORK5CYII=');
            }
        }
    }
}

.table-column:hover .table-column-link {
    color: #51709c;

    svg {
        stroke: #51709c;
    }
}

.table-column:hover td {
    background-color: #f1f5fd;
}

.dropdown-container-list > li:hover a {
    color: #51709c;
}

.expanded {
    display: flex;
}

.panel {
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    background-color: #fff;
    margin-top: 35px;

    &-content {
        transition: all .8s ease;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: .3px;
        padding: 40px 50px;
        width: 100%;
    }

    &-header {
        display: flex;
        justify-content: space-between;
    }

    &-title {
        font-family: "Oswald", sans-serif;
        font-size: 19px;
        color: #545454;
        max-width: 500px;
        line-height: 1.2;
        letter-spacing: .3px;
    }

    &-list {
        list-style: none;
        font-size: 14px;
        color: #787878;
        line-height: 1.5;
        letter-spacing: .3px;
        padding-left: 0px;
        display: flex;
        justify-content: space-between;

        li {
            margin-bottom: 20px;
            position: relative;
            display: inline;
        }

        ul {
            padding: 0;
        }

    }
}

@media screen and (max-width: 820px) {
    .panel {

        &-content {
            padding-left: 20px;
            padding-right: 20px;
            transition: all .8s ease;
            overflow: hidden;
            font-size: 8px;
            line-height: 1.5;
            letter-spacing: .3px;
            width: 100%;

            .is-expanded & {
                padding-left: 20px;
                padding-right: 20px;
            }

        }
    }

    .list-headline {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

        th {
            padding-right: 10px;

            &:first-child {
                padding: 0 0 0 10px;
            }

            &:last-child {
                text-align: left;
            }
        }
    }

    .table-column {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 48px auto;
        grid-template-areas: "td1 td2 td3 td4 td5" "dr dr dr dr dr";

        td {
            padding: 16px 10px 16px 0;

            &:first-child {
                text-align: left;
                padding-left: 10px;
            }
        }
    }

}

@media screen and (max-width: 620px) {
    .panel {
        &-content {
            padding: 20px;
        }
    }

    .list-headline {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 10px 0;

        th {


        }
    }

    .table-column {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 48px auto;
        grid-template-areas: "td1 td2 td3 td5" "dr dr dr dr";
    }

    .ishidden {
        display: none;
    }
}


@media screen and (max-width: 440px) {
    .list-headline {
        th {
            font-size: 11px;
        }
    }

    .table-column {
        td {
            font-size: 11px;
        }

        .table-column-link {
            display: flex;
            justify-content: flex-end;
            grid-area: td5;

            div {
                display: flex;
                justify-content: flex-end;
                width: 60px;
                text-align: right;


                div {
                    display: inline-block;
                    flex: 1;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                svg {
                    height: 12px;
                    width: 15px;
                    margin-right: -4px;
                    stroke: #787878;
                    stroke-width: 50px;
                    transition: transform 0.24s ease 0s;
                    text-align: center;
                    text-transform: none;
                    vertical-align: -0.125em;
                    position: relative;
                    top: 4px;
                }

                .is-expanded {
                    transform: rotate(90deg);
                }
            }
        }


        .dropdown {
            &-container-list {
                padding: 10px;

                li {
                    a {
                        font-size: 11px;
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 440px) {
    .list-headline {
        th {
            font-size: 10px;
        }
    }

    .table-column {
        td {
            font-size: 10px;
        }

        .dropdown {
            &-container-list {
                padding: 10px;

                li {
                    a {
                        font-size: 10px;
                    }
                }

            }
        }
    }
}

