









































.spinner {
    margin: 30px auto 0;
    text-align: center;
    svg {
        width: 500px;
        max-width: 100%;
        margin: 0 auto;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

