<template>
  <div v-if="!isRead && isUpdate" class="dot">1 <span class="tooltiptext">Neues Update verfügbar</span></div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import ExporoVue from '@/components/ExporoVue';
import {Prop} from 'vue-property-decorator';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import DateFormatHelper from '@/components/Helper/DateFormatHelper';
import Events from '@/events';

@Component
class UnreadUpdateDot extends ExporoVue {
  @Prop() private title!: string;
  @Prop() private date!: string;

  private isRead: boolean = false;
  private isUpdate: boolean = true;

  constructor() {
    super();
  }

  mounted() {
    this.isUpdate = 'Keine Updates' !== this.date;

    this.updateDot();

    Events.$on('updateDots', () => {
      this.updateDot();
      this.$forceUpdate();
    });
  }

  updateDot() {
    // @TODO by click on PageDetail.vue for the project title is a date saved in the localStorage
    const lastRead = LocalStorage.get('UPDATES_' + this.title, null);
    const dateY = +new Date(DateFormatHelper.stringToAmericanDate(this.date));

    this.isRead = this.isUpdate && dateY < +new Date('2020-09-13');

    if (lastRead && this.isUpdate) {
      const dateX = +new Date(DateFormatHelper.stringToAmericanDate(lastRead));

      this.isRead = dateX > dateY;
    }
  }
}

export default UnreadUpdateDot;
</script>
<style>
.vgt-right-align span {
  position: relative;
}
</style>
<style lang="scss">
.dot {
  position: absolute;
  top: -15px;
  right: -15px;
  padding: 0 5px;
  border-radius: 50%;
  background: red;
  color: white;
}

.dot {
  display: inline-block;
}

/* Tooltip text */
.dot .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  left: -130px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.dot:hover .tooltiptext {
  visibility: visible;
}
</style>