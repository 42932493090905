













































































































































































































































































































































































































































































































































































































































































/*/deep/ .mdc-linear-progress {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*}*/


.text-center {
  p {
    margin-top: 25vh;
    font-size: 28px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;

  :before,
  *:after {
    box-sizing: border-box;
  }
}

.exporo-cockpit-root {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #626262;
  font-size: 18px;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  padding-top: 250px;
  opacity: 0;
  overflow: scroll;
  background: transparent;
  transition: opacity .5s;

  &.open {
    z-index: 999999;
    opacity: 1;
  }

  &.white {
    background: #f4f5f8;
  }

  /deep/ img {
    vertical-align: middle;
  }

  /deep/ h1 {
    hyphens: auto;
    color: #626262;
    font-size: 44px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
    line-height: 1.3;
    text-transform: uppercase;
  }

  /deep/ h2 {
    hyphens: auto;
    color: #626262;
    font-size: 24px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
    line-height: 1.3;
    text-transform: none;
  }

  /deep/ a {
    color: #274a7f;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

@keyframes close {
  0% {
    z-index: 1;
    opacity: 1;
  }
  49% {
    z-index: 1;
    opacity: 1;
  }
  90% {
    z-index: 1;
    opacity: 1;
  }
  99% {
    z-index: 1;
    opacity: 1;
  }
  100% {
    z-index: -1;
    opacity: 0;
  }
}

.exporo-cockpit-tab-bar-root {
  // max-width: 1200px;
  // margin: 0 auto;
  // width: auto;
}

.shared-element {
  position: absolute;
  z-index: 15;
  transition-property: transform;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.content {
  //padding-top: 50px;
}

.exporo-invest-list-root, .detail-view-root {
  position: absolute;
  // width: 100%;
  top: 0;
  // overflow: hidden;
  // padding-left: 10px;
  // padding-right: 10px;
}

.open.close2 {
  position: absolute;
  right: 0;
  top: -30px;
  color: #fff0;
  transition: all .3s linear;

  &:after {
    content: 'X';
    color: #fff;
    margin-left: -17px;
    font-size: 20px;
  }
}

.ghost-layer {
}
