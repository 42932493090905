
























































.video {
    margin-top: 60px;
    padding: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);

    &-wrapper {
        box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    }

    img {
        max-width: 100%;
    }
}
@media screen and (max-width: 640px) {

    .video {
        width: 100%;
        &-wrapper {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 25px;
            height: 0;
            width: 100%;
            max-width: 640px;
        }

        .wistia_embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

