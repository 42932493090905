



















































































.root {
    margin: 40px 0 50px 0;
    width: 100%;
    position: relative;
}
.carousel {
    height: 100%;
}
.prev,
.next {
    position: absolute;
    top: 65%;
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:hover {
        transform: scale(1.2);
    }
}
.next {
    right: 0;
    margin-left: auto;
    text-indent: 2px;
}
.slides {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

}
.more-reviews {
    display: block;
    margin-right: 45px;
    margin-top: -25px;
    text-align: right;
    text-decoration: underline;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}
.more-reviews.mobile{
    text-align: right;
    margin-right: unset;
    margin-top: 5px;
}
.dots {
    display: block;
    width: 100%;
    text-align: center;
}
.list-item {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 15%;
    left: 0;
}
.list-enter-active, .list-leave-active {
    transition: all 1s;
}
.list-enter, .list-leave-to {
    opacity: 0;
}
