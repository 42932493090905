











































































































































































































.form-group {
    position: relative !important;
    margin-bottom: 30px;

    .error {
        display: block;
        color: red;
    }

    label {
        line-height: 17px;
        padding: 5px 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        color: #565656;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
        font-weight: 300;
        width: 25%;
        float: left;

        &.styled:before {
            content: ' ';
            margin-right: 5px;
            display: inline-block;
            background: #ededf3;
            border: 1px solid #878787;
            width: 15px;
            height: 15px;
            position: relative;
            top: 2px;
            -webkit-font-smoothing: antialiased;
            font-size: 10px;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
        }

        .full-width {
            width: 100%;
        }

        a {
            color: #1e73be;
            font-weight: normal;
            line-height: 17px;
            text-decoration: none;
        }

        &[for="email"] {
            width: 22%;
        }

        &[for="vib-file"]:before,
        &[for="debitMandate"]:before,
        &[for="exporo-investment-gmbh"]:before {
            content: ' ';
            margin-right: 5px;
            display: inline-block;
            background: #ededf3;
            border: 1px solid #878787;
            width: 15px;
            height: 15px;
            position: relative;
            top: 2px;
            -webkit-font-smoothing: antialiased;
            font-size: 10px;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
        }
    }

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #ededf3 inset;
    }

    input {
        &[type="email"] {
            border-radius: 0;
            background-color: #ededf3;
            box-shadow: none;
            font-size: 14px;
            line-height: 17px;
            margin-right: 0;
            outline: 0;
            padding: 5px 10px;
            max-width: 100%;
            border: none;
            border-right: 1px solid #878787;
            border-bottom: 1px solid #878787;
            width: 65%;
        }

        &[type="checkbox"]:focus {
            outline-offset: -2px;
        }

        &[type="checkbox"] {
            position: absolute;
            opacity: 0;
            background-color: #fff;
            border: 1px solid #ededf3;
            border-radius: 5px;
            float: left;
            margin: 4px 10px 0 0;
            box-shadow: none;
            font-size: 14px;
            line-height: 17px;
            outline: 0;
            padding: 5px 10px;
            max-width: 100%;

            &:checked + .styled:after,
            &:checked + [for="vib-file"]:after,
            &:checked + [for="debitMandate"]:after,
            &:checked + [for="exporo-investment-gmbh"]:after {
                font-family: 'icomoon', serif;
                content: '\e627';
                position: absolute;
                left: 13px;
                top: 6px;
            }
        }
    }

    &__inline {
        display: block;

        label {
            width: 100%;
        }

        & > *,
        & > div {
            display: inline-block;
            width: calc(49% - 3em);
            vertical-align: top;
            @media screen and (max-width: 900px) {
                display: block;
                width: 100%;
            }

            ~ * {
                margin-left: 3em;
                @media screen and (max-width: 900px) {
                    margin-left: 0;
                }
            }
        }
    }
}

.in-container {
    margin-top: 15px;
}

/deep/ .label {
    opacity: 0.8;
    margin-bottom: 5px;
}

div + .label {
    margin-top: 15px;
}

.exploration-btn {
    padding-top: 1em;

    label {
        width: 50%;
        @media screen and (max-width: 900px) {
            width: 90%;
        }
    }

    /deep/ input[type='checkbox'],
    /deep/ input[type='radio'] {
        position: absolute;
        opacity: 0;
    }

    /deep/ input[type='checkbox'] + label,
    /deep/ input[type='radio'] + label {
        color: #626262;
    }

    /deep/ input[type='checkbox']:checked + label,
    /deep/ input[type='radio']:checked + label {
        color: lighten(#626262, 30);
    }

    /deep/ input[type='checkbox']:checked + label::before,
    /deep/ input[type='checkbox']:not(:checked) + label::before,
    /deep/ input[type='radio']:checked + label::before,
    /deep/ input[type='radio']:not(:checked) + label::before {
        border: 1px solid #b8c1ce;
        background: white;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, .1);
        content: ' ';
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        margin-right: 1em;
        vertical-align: text-top;
        top: 0.5em;
        left: 0.2em;
    }

    /deep/ input[type='checkbox'] + label,
    /deep/ input[type='radio'] + label {
        color: #626262;
        display: block;
        padding-left: 1.7em;
        position: relative;

        ul {
            margin: 0;

            li {
                font-size: 12px;
                list-style: square;
                margin: 0;
                margin-left: 15px;
                margin-bottom: 3px;
                padding: 0;

                &:first-child {
                    margin-top: 5px;
                }

                &:before {
                    content: "";
                }
            }
        }
    }

    /deep/ input[type=radio]:checked + label::before,
    /deep/ input[type=radio]:not(:checked) + label::before {
        border-radius: 30px;
    }

    /deep/ input[type='checkbox']:hover + label::before,
    /deep/ input[type='radio']:hover + label::before {
        box-shadow: inset 0 0 1px 0 #626262;
    }

    /deep/ input[type='checkbox']:checked + label::before,
    /deep/ input[type='radio']:checked + label::before {
        background: var(--color-branding-dark, #274a7f);
        box-shadow: inset 0 0 0 4px white;
    }

    .mobile & {
        input {
            min-width: auto !important;
        }

    }

    /deep/ input[type="radio"][disabled],
    /deep/ input[type="checkbox"][disabled],
    /deep/ input[type="checkbox"]:disabled,
    /deep/ input[type="radio"].disabled,
    /deep/ input[type="checkbox"].disabled,
    /deep/ fieldset[disabled] input[type="radio"],
    /deep/ fieldset[disabled] input[type="checkbox"] {
        cursor: not-allowed;

        + label {
            opacity: 0.8;
        }
    }

    input[type="text"][disabled],
    input[type="text"]:disabled {
        cursor: not-allowed;
        background: white;
        color: #91908f;
    }

}

