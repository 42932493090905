





























































$color-primary: #3968AF;

.landing {
    margin-top: 30px;
    margin-bottom: 70px;
    padding: 60px 30px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    text-align: center;
    color: #545454;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    animation: fade-in .3s forwards;
}

.header {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.emblem {
    width: 128px;
    border-radius: 128px;
    background-color: $color-primary;
    overflow: hidden;
}

.title {
    text-align: center;
    font-family: "Oswald", sans-serif;
    font-size: 76px;
    text-transform: uppercase;
    line-height: 1.25;
    color: #545454;
    margin-left: 30px;
    font-weight: 400;
}

.subtitle {
    margin-top: 5px;
    font-size: 20px;
    max-width: 650px;
    font-weight: 300;
}

.description {
    margin-top: 25px;
    font-size: 18px;
    max-width: 650px;
    line-height: 1.5;
    letter-spacing: .6px;
}

.label {
    margin-top: 65px;
    background-color: $color-primary;
    color: #fff;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-size: 20px;
    padding: 20px;
    font-weight: 600;
    max-width: 500px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:after {
        position: absolute;
        background-color: $color-primary;
        content: "";
        display: block;
        width: 50px;
        height: 50px;
        transform: rotate(45deg);
        bottom: -12px;
        z-index: -1;
    }
}

.textheadline {
    margin-top: 70px;
    font-size: 44px;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
}

.text {
    margin-top: 20px;
    max-width: 830px;
    font-size: 18px;
    max-width: 650px;
    line-height: 1.5;
    letter-spacing: .6px;
}

.actionbutton {
    margin-top: 40px;
    width: 600px;
    border-radius: 30px;
    background-color: $color-primary;
    color: #fff !important;
    text-align: center;
    padding: 10px;
    text-decoration: none;
    display: block;
    transition: all .5s ease-out;
    font-weight: 700;
    max-width: 100%;

    &:hover {
        text-decoration: none !important;
        background-color: lighten($color-primary, 10);
    }
}

@media screen and (max-width: 900px) {
    .header {
        flex-direction: column;
    }
    .emblem {
        width: 96px;
    }
    .title {
        font-size: 50px;
        margin-left: 0;
        margin-top: 10px;
    }
    .description {
        font-size: 16px;
    }
    .textheadline {
        font-size: 30px;
    }
    .text {
        font-size: 16px;
    }
}

@media screen and (max-width: 400px) {

    .landing {
        padding-left: 15px;
        padding-right: 15px;
    }

    .title {
        font-size: 38px;
    }
    .description {
        font-size: 16px;
    }
    .textheadline {
        font-size: 26px;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

