




































































































































































































































































































































.header {
  height: 50px;
  background: white;

  svg {
    display: block;
    height: 100%;
    margin: auto auto;
    padding: 5px;
  }
}

.alternate {
  height: 160px !important;
  background-color: #9e9e9dd9 !important;

  .header {
    display: none;
  }

  .closePP {
    z-index: 99999;
    color: #ffffff;
    right: 0;
    top: 0;
  }
}

.second {
  background-color: #9e9e9dd9 !important;
}

.modal-content {
  z-index: 9999999999;
  position: absolute;
  background-color: #222222;
  margin: auto;
  border: 1px solid #888;
  height: 200px;
  width: 450px;
  top: 20px;
  right: 20px;

  .content-pp {
    padding: 20px;
    position: relative;

    img {
      width: 110px;
      border-radius: 4px;
      float: left;
    }

    .text {
      float: right;
      width: 275px;

      h6 {
        font-size: 20px;
        line-height: 18px;
        font-family: Oswald, sans-serif;
      }

      span {
        margin-top: 10px;
        font-size: 14px;
        width: 300px;
        line-height: 14px;
        font-family: "Open Sans", sans-serif;
        font-weight: normal;
      }

      h6, span {
        color: #ffffff;
        text-align: left;
        text-transform: initial;
      }
    }

    span, p {
      color: white;
    }

    a {
      display: block;
      border-style: none;
      border-radius: 100px;
      left: 150px;
      top: 90px;
      z-index: 5;
      width: 170px;
      height: 40px;
      position: absolute;
      box-shadow: none;
      text-shadow: none;
      color: #ffffff;
      font-size: 18px !important;
      line-height: 24px;
      font-weight: 300;
      font-family: Oswald, sans-serif;
      font-style: normal;
      text-align: center;
      background: rgba(210, 153, 56, 1) no-repeat;

      span {
        font-size: 14px;
        line-height: 40px;
      }

      &:hover {
        background: rgba(198, 157, 91, 1);
      }
    }

    p {
      height: 36px;
      padding-left: 40px;
      position: relative;
      margin: auto auto;
      line-height: 30px;
      width: 580px;
      color: rgb(255, 255, 255);
      font-weight: bold;
      font-size: 18px;
      font-style: normal;
      font-family: "Open Sans", sans-serif;

      svg {
        left: 0;
        position: absolute;
      }
    }

    > .bak_span:first-child {
      width: 552px;
    }

    span {
      display: block;
      font-family: Oswald, sans-serif;
      color: rgb(255, 255, 255);
      font-weight: bold;
      font-style: normal;
      font-size: 18px;
      line-height: 30px;
      margin: auto auto;
      text-align: center;
    }
  }
}


@media (max-width: 450px) {
  .modal-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999;
    width: 100%;
    margin-top: 200px;

    .content-pp {
      padding: 10px;

      a {
        left: 50%;
        margin-left: -150px;
        z-index: 5;
        width: 300px;
        position: absolute;
        bottom: 0;
      }

      > span:first-child {
        width: 100%;
        font-size: 24px;
      }

      p {
        font-size: 14px;
        line-height: 22px;
        height: 55px;
        width: 100%;
      }
    }
  }
}

.closePP {
  width: 30px;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  text-decoration: none;
}

.closePP:hover,
.closePP:focus {
  color: #000;
  cursor: pointer;
}

.btn-container {
  text-align: right;

  .btn {
    display: inline-block;
    background: var(--color-branding-dark, #274a7f);
    position: relative;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 10px;
    padding: 16px 30px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .5s ease-out;
  }
}
