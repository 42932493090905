


























































































.scrollbar-root {
    position: absolute;
    right: 10px;
    top: 60px;
    width: 10px;
    border-radius: 10px;
    height: calc(100% - 130px);
    background: #ededf3;
    cursor: pointer;
    opacity: 0;

    &.shouldShow {
        opacity: 1;
    }

    span {
        height: 30px;
        width: 10px;
        border-radius: 10px;
        background: var(--color-branding, #274875);
        display: block;
        will-change: margin-top;
    }
}
