






















































































.notification {
    border-radius: 5px;
    background-color: #335586;
    color: #fff;
    padding: 20px 30px 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    opacity: 0;
    animation: fade-in .3s forwards;

    &-headline {
        font: 20px "Oswald", sans-serif;
    }
    &-text {
        margin-top: 4px;
        font-size: 14px;
    }
    &-state {
        color: #fff;
        font-size: 16px;
        display: flex;
        align-self: flex-start;
        margin-top: 30px;
        cursor: pointer;
        width: 200px;
        &-label {
            padding: 4px 0;
        }
        &-description {
            padding: 4px 0 4px 6px;
        }
    }

    &-underline {
        margin-top: 20px;
        font-size: 11px;
    }

    &-body {
        display: flex;
        flex-direction: column;
    }
    &-side {
        flex-grow: 1;
        min-width: 200px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-left: 30px;
    }
    
    &-progressbar {
        margin-top: 15px;
    }

    &-button {
        align-self: flex-start;
        margin-top: 20px;
    }

}



@media screen and (max-width: 640px) {

    .notification {
        flex-direction: column;

        &-side {
            margin-left: 0;
            margin-top: 30px;
        }
    }

}



@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


