


























































































.cockpit-menu-root {
    // float: right;
    // top: -59px;
    // position: relative;
    z-index: 3;
    pointer-events: none;
    user-select: none;

    .menu-wrapper {
        display: inline-block;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .menu-button {
        font-size: 13px;
        text-transform: uppercase;
        border: 1px solid #dbdbdb;
        display: inline-block;
        border-radius: 30px;
        padding: 4px;
        background-color: #fff;
        cursor: pointer;
        box-shadow: none;
        pointer-events: auto;
        transition: background-color .3s ease, box-shadow .3s ease;

        &:hover {
            .menu-button-inner {
                background: #eff2f5;
            }
        }

        &-inner {
            padding: 8px 15px 6px;
            border-radius: 30px;
            transition: all .3s ease;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .menu-items {
        margin-top: 5px;
        border-radius: 5px;
        border: 1px solid #dbdbdb;
        box-shadow: 0 0 20px rgba(0, 0, 0, .12);
        background-color: #fff;
        font-size: 14px;
        padding: 4px;
        transition: opacity .3s ease, transform .3s ease;

        opacity: 0;
        transform: translateY(-30px) scale(.9);


        li {
            padding: 15px 25px;
            cursor: pointer;
            transition: all .3s ease;

            &:hover {
                background-color: #eff2f5;
            }

            & + li {
                border-top: 1px solid #dbdbdb;
            }
        }

    }

    .is-open {
        .menu-button {
            color: #fff;
            box-shadow: 0 0 20px rgba(0, 0, 0, .12);

            .menu-button-inner,
            &:hover .menu-button-inner {
                background-color: #335586;
            }
        }
        .menu-fields {
            pointer-events: auto;

            li {
                position: relative;

                &:nth-child(1) {
                    z-index: 4;
                    ul {
                        transition-delay: 0s;
                    }
                }
                &:nth-child(2) {
                    z-index: 3;
                    ul {
                        transition-delay: .1s;
                    }
                }
                &:nth-child(3) {
                    z-index: 2;
                    ul {
                        transition-delay: .2s;
                    }
                }
                &:nth-child(4) {
                    z-index: 1;
                    ul {
                        transition-delay: .3s;
                    }
                }

            }
        }
        .menu-items {
            opacity: 1;
            transform: translateY(0) scale(1);
        }
    }

}
