












































.exporo-gallery-thumbnails-button-root {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: 300px;
    grid-auto-rows: 300px;
    grid-gap: 16px;
    margin-bottom: 16px;


    img {
        object-fit: cover;
        cursor: pointer;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
}

