











































































































.passwordresetForm {
    h1 {
        font-size: 18px;
        margin: 0 auto;
        text-align: center !important;

        &:after {
            background: var(--color-branding-dark, #274a7f);
            content: "";
            display: block;
            height: 2px;
            margin: 0.5em auto;
            width: 30px;
        }
    }
}

.btn-container {
    padding: 1em;
    text-align: center;

    button {
        padding: 15px 40px !important;
        text-align: center;

        &:hover {
            background: var(--color-branding-dark, #274a7f);
            color: white;
        }
    }
}
.link-container {
    text-align: center;
}
.error {
    color: tomato;
}
