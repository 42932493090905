






























































































.exporo-invest-entry {
    width: 100%;
    height: 60px;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    letter-spacing: .6px;
    font-weight: bold;

    .title p {
        will-change: opacity;
        transition: opacity .1s ease-in-out;
        opacity: 1;
    }

    span {
        float: left;
        padding: 5px;
    }

    img {
        will-change: height;
        transition: height .5s ease-in-out;
        width: 70px;
        height: 50px;
        object-fit: cover;
        margin: 0 !important;
        border-radius: 3px;
        background-color: #ccc;
    }
    span + p {
        margin-right: 10px;
    }

    td {
        &:first-child {
            display: flex;
            border-radius: 5px 0 0 5px;
        }
        &:last-child {
            border-radius: 0 5px 5px 0;
        }
    }

    .td {
        display: inline-block;

        p {
            margin: 0 !important;
            line-height: 60px;
            height: 60px;
            font-size: 18px;
            color: #626262;

            &.label {
                opacity: .6;
                font-size: 12px;
            }
        }

        &.top, &.bottom {
            transform: translateY(0) translateX(0);
            transition-duration: .4s;
        }
        
    }

    &.expanding {
        position: relative;
        transition: height .5s ease-in-out;
        height: 200px !important;

        .title p {
            opacity: 0;
        }

        .td {
            //width: 210px !important;

            float: left;

            p {
                margin: 0 !important;
                line-height: 20px;
                height: 20px;
            }

            &.top {
                transform: translateY(30px) translateX(100px);
            }

            &.bottom {
                transform: translateY(120px) translateX(-400px);
            }
        }

        img, span {
            height: 190px;
            width: 190px;
        }
    }

    &.collapsing {
        transition: height .5s ease-in-out;
        height: 60px !important;

        .td {
            position: relative !important;
            top: initial !important;
            left: initial !important;

            p {
                margin: 0 !important;
                line-height: 60px;
                height: 60px;
            }

            p:last-child {
                display: none;
            }

            &.top, &.bottom {
                transform: translateY(0) translateX(0);
            }
        }

        img, span {
            height: 100%;
            width: 60px;
        }
    }
}

.detail-view-root .exporo-invest-entry {
    opacity: 0;
    z-index: 11;
    position: absolute;
    border-radius: 3px;
    top: 100px;
    width: calc(100% - 40px);
    height: 60px;
    background: #fff;
    text-align: left;
}


.detail-view-root .exporo-invest-entry {
    &.collapsing {
        left:0;
        width: 100% !important;
        height: 70px !important;

        img {
            width: 60px;
            height: 60px;
        }
    }

}


.visible .exporo-invest-entry {
    //animation-name: fadein;
    //animation-duration: 50ms;
    //animation-fill-mode: forwards;
    opacity: 1;
}

.leaving .exporo-invest-entry {
    opacity: 1;
}

.content {
    .exporo-invest-entry {
        transition-property: transform, opacity;
        transition-duration: 500ms;
        transform: translateY(0) scale(1);
        opacity: 0;
        margin-bottom: 1px;
        will-change: transform, opacity;
        width: 100%;
        border-collapse: unset;
        border-spacing: 0 3px;
    }
}

.appear .exporo-invest-entry {
    animation-name: appear;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
}
