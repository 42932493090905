































































































































































































































































































































































































.equity_invest__payment-form {
    max-width: 100%;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    background-color: white;
    background-color: var(--color-background, white);
    border-radius: 3px;
    border: 1px solid #ededf3;
    padding: 1em;

    margin: 20px 0;

    &:focus-within {
        background: #f9f9f9;
    }
}

.exporo-iban {
    .form-group {
        align-items: center;
        display: flex;
        margin-bottom: 15px;

        span {
            color: red;
            margin-bottom: 15px;
            display: block;
        }

        & > * {
            flex: 2;
        }

        & > div {
            display: flex;

            & > * {
                flex: 1;

                &.flex-2 {
                    flex: 2;
                }
            }

            & > :first-child {
                margin-left: 0;
            }
        }

        label {
            flex: 1;
            font-family: 'Oswald', sans-serif;
            font-weight: 400;
            font-size: 18px;
            color: #86909d;
        }

        input[type="radio"],
        input[type="checkbox"] {
            ~ label {
                font-family: 'Open Sans', sans-serif;
                color: #86909d;
            }

        }

        &__inline {
            display: block;

            & > *,
            & > div {
                display: inline-block;

                ~ * {
                    margin-left: 3em;
                }
            }
        }
    }
}

.equity-invest_sum {
    background: #b8c1ce;
    border-radius: 3px;
    color: white;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    min-width: 5em;

    .mobile & {
        width: 50%;
        margin-top: 15px;
    }

    @media screen and (max-width: 900px) {
        width: 50%;
        margin-top: 15px;
    }
}

#pieces {
    background: transparent;
    box-shadow: none;
    border: 1px solid #91908f;
    border-radius: 3px;
    color: #91908f;
    font-size: 20px;
    font-family: inherit;
    font-weight: bold;
    line-height: 20px;
    margin-right: 0;
    outline: 0;
    padding: 10px;
    text-align: center;
    width: 130px;

    &:focus, &:hover, &.active {
        background: rgb(242, 244, 247);
        box-shadow: inset 0 0 0 4px white;
    }

    .mobile & {
        width: 50%;
    }

    @media screen and (max-width: 900px) {
        width: 50%;
    }
}

.mb-20 {
    margin-bottom: 20px;
}
