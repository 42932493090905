


























































































































.tooltip-container {
    display: inline-block;
    position: relative;
    width: 40px;

    [data-tooltip] {
        position: absolute;
        background-color: rgb(255, 255, 255);
        color: rgb(136, 145, 156);
        border-radius: 4px;
        border: 0.1em solid rgb(186, 193, 205);
        box-shadow: 0 0 .5em rgb(128, 128, 128);
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-family: 'Open Sans', sans-serif;
        padding: 16px;
        z-index: 3;
    }
}

.arrow {
    color:transparent;
    background-color: inherit;
    box-shadow: 3px 3px 2px 0 rgba(178, 178, 178, .4);
    display: block;
    height: 20px;
    position: relative;
    top: 24px;
    border-radius: 4px;
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.cornerLeftArrow {
    position: relative;
    top: 42px;
    height: 0;
    width: 0;
    background-color: transparent;
    transform: unset;
    border-radius: unset;
    box-shadow: unset;
    border-style: solid;
    border-width: 25px 25px 0 0;
    border-color: rgba(178, 178, 178, .4) transparent transparent transparent;
    &:after {
        position: absolute;
        top: -27px;
        left: 3px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 18px 18px 0 0;
        border-color: white transparent transparent transparent;
    }
}

.slideIn-enter-active, .slideIn-leave-active {
    transition: transform 300ms ease;
}

.slideIn-leave-active {
    transition: transform 150ms ease;
}

.slideIn-enter, .slideIn-leave-to {
    transform: translate(0, -2rem) scale(1);

}

svg {
    height: auto;
    width: 18px;
    stroke: #BAC1CD;
    stroke-width: 6px;
    cursor: pointer;
}

