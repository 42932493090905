
































































.section-container {
    margin-bottom: 40px;
    position: relative;
}

pre {
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word;
    margin: 0;

    /deep/ p {
        margin: 0;
    }
}

.panel-title {
    font-size: 19px;
    font-family: Oswald, sans-serif;
    color: #545454;
    max-width: 500px;
    line-height: 1.2;
    letter-spacing: .3px;
    padding-bottom: 12px;
}

.card {

    &-container {
        display: grid;
        grid-template: "1fr 1fr";
        grid-column-gap: 80px;
        padding: 5px 0;
    }

    &-entry {
        display: flex;
        padding: 10px 20px;
        justify-content: space-between;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: .3px;
        border-top: 1px solid #e5e5e5;

        &:nth-child(-n+2) {
            border-top: none;
        }
    }

    &-key {
        color: #888;
    }

    &-value {
        color: #555;
        text-align: right;

        a {
            color: #51709c;
        }

        &-disabled {
            color: #aaa;
        }
    }
}

li {
    list-style: none;
}

.kpi-description {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    max-width: 840px;
    padding: 0 30px;
    color: #888;
    line-height: 1.6;
}

@media screen and (max-width: 900px) {
    .card-container {
        display: none;
    }
    .kpi-description {
        padding: 0 20px 50px 20px;
    }
}

.printbtn {
    transition: all 4s;
    position: absolute;
    padding: 13px;
    right: -30px;
    top: -20px;
    z-index: 1;
}

