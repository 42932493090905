





















































































.wrapper {
    border-radius: 5px;
    padding: 11px;
    display: flex;
    color: white;
    font-weight: bold;
}
