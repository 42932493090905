












































.image {
    margin-top: 90px;
    border-radius: 5px;

    img {
        border-radius: 5px;
        max-width: 100%;
        width: 703px;
        height: auto;
    }
}
