





















































































































































































































.exporo-cockpit-tab-bar-root {
    transform: translateX(0.001px);
    user-select: none;
}

ul {
    list-style: none;
    border: 1px solid #dbdbdb;
    background-color: white;
    display: inline-block;
    border-radius: 30px;
    text-transform: uppercase;
    letter-spacing: .5px;
    padding: 4px;
    position: relative;
    margin: 0;
    overflow: hidden;

    .is-small & {
        padding: 2px
    }
}

li {
    padding: 8px 15px 6px;
    font-size: 13px;
    display: inline-block;
    transition: all .3s ease;
    margin: 0 5px;
    z-index: 2;
    position: relative;
    transform: translateZ(0);
    cursor: pointer;
    list-style-type: none;
    border-radius: 30px;
    text-align: center;

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }

    &:not(.active):hover {
        background: #eff2f5;
    }

    &.active {
        color: #fff;
        transition: background-color 0s, color .3s ease-out;
    }

    .is-small & {
        padding: 2px 15px 1px;
        margin: 0 2px;
    }
}

.bubble {
    background-color: #335586;
    height: 20px;
    top: 4px;
    position: absolute;
    transition: all .3s ease;
    z-index: 1;
    border-radius: 30px;
    display: block;
    will-change: left, top, width, height;

    &.invisible {
        opacity: 0;
    }
}

