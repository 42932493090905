

























































.dot {
  position: absolute;
  top: -15px;
  right: -15px;
  padding: 0 5px;
  border-radius: 50%;
  background: red;
  color: white;
}

.dot {
  display: inline-block;
}

/* Tooltip text */
.dot .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  left: -130px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.dot:hover .tooltiptext {
  visibility: visible;
}
