






































.keyfigures {
    margin-top: 5px;
    &-row {
        display: flex;
    }
    &-panel {
        background-color: #fff;
        border-radius: 10px;
        position: relative;
        padding: 50px 20px 20px;
        flex-grow: 1;
        max-width: 380px;
        min-width: 125px;
        transition: box-shadow .3s ease;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
        margin: 10px;
        // opacity: 0;
        // animation: slide-in-bottom .3s forwards;

        &:nth-child(1) {
            animation-delay: .0s;
        }
        &:nth-child(2) {
            animation-delay: .1s;
        }
        &:nth-child(3) {
            animation-delay: .2s;
        }
        &:nth-child(4) {
            animation-delay: .3s;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 45px;
            height: 35px;
            color: #fff;
            border-radius: 6px 0 8px 0;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
            background-repeat: no-repeat;
            background-position: center center;
        }
        &:nth-child(1):before {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAYAAAARfGZ1AAAAAXNSR0IArs4c6QAAAj5JREFUSA2tlc9LVFEUx3sSmGCERTqTGk5DE6G0GFxkoS5np9FC+7UMdGjXv9AucedKXAm5CXIRCLMQh0kRtEWEglADtUiRKBqisAhfn++bd+OO7zk+ZzrwmXPvPfd873vn3XvHcV1378T/tXuO47yQ5ElfN4sv+u163BzJ7UbAiBdYrW5xqrBuhOUb7I5pM+kRLMCwGavFB8QRvIFQH0h4pBZRkxMQJ3AHvsElSnXfTIzoTzGvx8wNE+8imIQEb3HWTIzozzDvFnnPNT9M/Cnjv+EVLELASL4OszAJKWvCLu0JyDCeDIhTimWCL2EKHkKFkaStlgfldkOOsUa8sV80tiBttqIJeJ4FZmiIMEsz+IY5DxBV/nvohRUwtkMjHnhyE63im4i5irPAH9wH6ADbtum0O6yu468D9MmO+u0YXq9Zgn1/TB9NO+m8+uQv4VpB+QPwGM5BSq81Bp0QZtrzbaDttQY6gS1wDYxJVAsXIA/zMAQ34Wjj6S7AGmh3NMEeeHcI/glM2yr0R2E1Us0pgWp4F7R7LoPKFAfZZ/BK5PXKP824UuhusSb9a7JAkafRVXobvoMRz9HeANsG6WzaA0e2EdeF9hpUFn2PgDF+GkrQH/nJfRUdlqtwhTf5GFAuD4zj3hHXCY9uPE0W3lbLIK7y6fILvVuq5X4h+POwCYhmiOljen9zkXaLJaZSJKz+waZ20zNKoovveMaTxUAfU6e0whjTh/wBuq5rM5K/Qiosm3FdarUbAhejZv8FDWDtYRuMW2UAAAAASUVORK5CYII='), linear-gradient(to bottom, #2DC3F3, #23ADDA);
        }
        &:nth-child(2):before {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAYAAAARfGZ1AAAAAXNSR0IArs4c6QAAAj5JREFUSA2tlc9LVFEUx3sSmGCERTqTGk5DE6G0GFxkoS5np9FC+7UMdGjXv9AucedKXAm5CXIRCLMQh0kRtEWEglADtUiRKBqisAhfn++bd+OO7zk+ZzrwmXPvPfd873vn3XvHcV1378T/tXuO47yQ5ElfN4sv+u163BzJ7UbAiBdYrW5xqrBuhOUb7I5pM+kRLMCwGavFB8QRvIFQH0h4pBZRkxMQJ3AHvsElSnXfTIzoTzGvx8wNE+8imIQEb3HWTIzozzDvFnnPNT9M/Cnjv+EVLELASL4OszAJKWvCLu0JyDCeDIhTimWCL2EKHkKFkaStlgfldkOOsUa8sV80tiBttqIJeJ4FZmiIMEsz+IY5DxBV/nvohRUwtkMjHnhyE63im4i5irPAH9wH6ADbtum0O6yu468D9MmO+u0YXq9Zgn1/TB9NO+m8+uQv4VpB+QPwGM5BSq81Bp0QZtrzbaDttQY6gS1wDYxJVAsXIA/zMAQ34Wjj6S7AGmh3NMEeeHcI/glM2yr0R2E1Us0pgWp4F7R7LoPKFAfZZ/BK5PXKP824UuhusSb9a7JAkafRVXobvoMRz9HeANsG6WzaA0e2EdeF9hpUFn2PgDF+GkrQH/nJfRUdlqtwhTf5GFAuD4zj3hHXCY9uPE0W3lbLIK7y6fILvVuq5X4h+POwCYhmiOljen9zkXaLJaZSJKz+waZ20zNKoovveMaTxUAfU6e0whjTh/wBuq5rM5K/Qiosm3FdarUbAhejZv8FDWDtYRuMW2UAAAAASUVORK5CYII='), linear-gradient(to bottom, #2DC3F3, #23ADDA);
        }
        &:nth-child(3):before {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAYCAYAAAD+vg1LAAAAAXNSR0IArs4c6QAAAqVJREFUSA2VlUtIFVEYx500SC0wtbKQII1SetiDHka1cFNQIEGLSBeCLxJXmVAggmQvW7SpZSDRTlCiEAQ3LcJoEUQvg0jFiz3wEWT2Wtx+/7kzc89czgx14Mf3PN/MnPnOOU5WzEgmk/sI18IRqIRsmIG3cNdxnBGkdTg2LwX34r8Mu2EUVEj8gY2wB07DFLTygKfI+EHRC/Ad+qEwKptYPlyHJeiKynP9JFyBcTgcm2gEya2Db3DTcKdVArUwD+vS3n/TmHMIPsOZ0AwcuTANHaHAfxjMrYcJyA2mYXTDG8jxneiFcBR2+D5J7FXQZPo8fzb+KWgPYhjHQZ3gDnRNfg8/PfYbsR58H33blPgvwQvTF9IJ6oeo6DH4BANKQK6Hr2BdMvxFoC7ZuixUMW2s8dRJ5CwUe3Yvch5ue3ZI0M9zOF5CdbCmoYxU48u1HYrgOW+xC1kHDRT4hYwaWoqd1iBFlsMz0HIkoAoewxishTY4ZZuM/xrcs8VcH8E8qIYVcAL0EPXrB0+X3ZlZAF8f9EetcRafuwRjTPwNN+Ah6GzZAA3wBBohc5TiSEQWNrJb0TfBRfB/4gL6Iui0yxw6oBJqoTKYBK3bajMLW/08A7fkRypXS6DPHYfhjPwKL16l5EbP0K7RYXLeT0bvgC8QPBBdm0C9qn6u8XMlsTtBXeEa9zEGQWfFNih3A6nYFuyDvh0nySsBvcQ5Nw9F7aS//giuxk2OizF3BF5D6r+hnAUdIOWgz2uLK2CLMacFdDkEZ0ooj0ANzMIdsF5b5gRy8qAXFiH+hUjQuuqPP4ADZiFTJ6aLQZtFX9lsxqRb34rEAmJ9oBthGvSnJ+AHrISTsBm0abrYSO+QoWEt7GfwAJ1y9aAi2lG6HXRT61YeouArpHX8BV4GUVSOV56TAAAAAElFTkSuQmCC'), linear-gradient(to bottom, #79A3E9, #2677CD);
        }
        &:nth-child(4):before {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAYCAYAAAALQIb7AAAAAXNSR0IArs4c6QAAAbFJREFUSA3dlTEvBFEQx3cvCqGwHYmCQtSIhoKt6CQK6vcFJBJf4OITaHVXKVSrQHuFD3BXaESBRKdQIC5x1m/c7ubt7DsKL1uY5G9m/nNv5r3/vl1BMMTSNH12leATEOsanAEtzdt5w05UPKHyPI3yQPlZcsFwYzfL4Bw8Ad/Wp+E12PveAcEVOASlHZOnri1Ct0Gsa3BNqdk8eQPMAxm4O0JxBWyHYeh8RvbiX2JZX+pBz0+4Gwad4ldlmDy3V6BtURNZbvClphnfwidZrN0LxFTIVJFrE/T0LzzmW/Qay4d57Ots9Q57IjKKjaPv2yD0/xfxDug699N75n1qrcNyGYtTcGRDMlMQg+AMF4F1xXeRXz5fBr6yhlrH/r3rZHKtQwXhXHzey1UTrmT5bazlgrhkXGA7+iPchYuAluoeqe6Q0bmGWul0lWE0NEAW23ZEEgFjk8Rt0AQGuNYk8IXVKqPrghQ78R1UhqG/XGVtMcS+JslF3gDvXKM3+79lfOS4+iZpBf6aL9HgQWTcITgGF+AW+DR5tTbAKFiTz5I84Gmc/IOblNyjfdCrAy55wftf4iBMhH7bwsYAAAAASUVORK5CYII='), linear-gradient(to bottom, #2DC3F3, #52E5CC);
        }

    }
    &-value {
        font-size: 26px;
        color: #2E4B7B;
        letter-spacing: .6px;
    }
    &-key {
        font-size: 12px;
        margin-left: 2px;
        margin-top: 4px;
    }

}


@media screen and (max-width: 960px) {

    h2 {
        text-align: center;
    }

    .keyfigures {
        &-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        &-panel {
            max-width: none;
            padding: 15px 15px 20px 60px;
        }
    }
}

@media screen and (max-width: 520px) {
    .keyfigures {
        &-row {
            grid-template-columns: 1fr;
        }
    }
}

@keyframes slide-in-bottom {
    from {
        opacity: 0;
        transform: translateY(-40px);
    }
    to {
        opacity: 1;
        transform: translateY(0px)
    }
}
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

