




































.list-option {
    height: 30px;
}

.list-button {
    border: 1px solid #2E4B7B;
    color: #2E4B7B;
    border-radius: 40px;
    font-size: 16px;
    text-transform: uppercase;
    padding: 5px 30px;
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
        background-color: #2E4B7B;
        color: #fff;
    }
}

ul {
    list-style: none;
    margin: 5px 0 0;
    padding: 0;
    border-radius: 5px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 0 20px rgba(0, 0, 0, .12);
    background-color: #fff;
    font-size: 14px;
    padding: 4px;
    transition: opacity .3s ease, transform .3s ease;
    pointer-events: none;
    z-index: 2;
    position: relative;
    will-change: opacity;
    opacity: 0;
    transform: translateY(-30px) scale(.9);

    li {
        padding: 15px 25px;
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
            background-color: #eff2f5;
        }

        & + li {
            border-top: 1px solid #dbdbdb;
        }
    }

}

.is-open {
    .list-button {
        color: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, .12);
        background-color: #335586;
    }
    ul {
        pointer-events: auto;

        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

