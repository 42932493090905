







































.expander-content {
    transition: all .3s ease-in-out;
    overflow: hidden;
}

.expander-inner {


    &:before,
    &:after {
        content: " ";
        display: table;
    }
}

