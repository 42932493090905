























































































.exporo-input-root{
    /deep/ input[type="text"],
    /deep/ input[type="date"],
    /deep/ input[type="number"],
    /deep/ input[type="email"],
    /deep/ input[type="password"]{
        background: white;
        box-shadow: none;
        padding: 10px;
        border: 1px solid #b8c1ce;
        border-radius: 3px;
        color: #91908f;
        font-size: 14px;
        font-family: inherit;
        font-weight: bold;
        line-height: 17px;
        margin-right: 0;
        outline: 0;
        width: 100%;
        &:focus, &:hover, &.active {
            background: rgb(242, 244, 247);
            box-shadow: inset 0 0 0 4px white;
        }
        &::placeholder{
            color: #ccc;
        }
    }

    /deep/ input[type='checkbox'],
    /deep/ input[type='radio']{
        position: absolute;
        opacity: 0;
    }

    /deep/ input[type='checkbox'] + label,
    /deep/ input[type='radio'] + label{
        color: #626262;
    }
    /deep/ input[type='checkbox']:checked + label,
    /deep/ input[type='radio']:checked + label{
        color: lighten(#626262, 30);
    }

    /deep/ input[type='checkbox']:checked + label::before,
    /deep/ input[type='checkbox']:not(:checked) + label::before,
    /deep/ input[type='radio']:checked + label::before,
    /deep/ input[type='radio']:not(:checked) + label::before {
        border: 1px solid #b8c1ce;
        background: white;
        box-shadow: inset 0 1px 1px 0 rgba(0,0,0,.1);
        content:' ';
        display: block;
        width: 16px;
        height:16px;
        position: absolute;
        margin-right: 1em;
        vertical-align: text-top;
        top: 0.2em;
        left: 0.3em;
    }
    /*/deep/ input[type='radio']:checked + label::before,
    /deep/ input[type='radio']:not(:checked) + label::before{
        top: 0.4em;
    }*/
    /deep/ input[type='checkbox'] + label,
    /deep/ input[type='radio'] + label{
        color: #626262;
        display: block;
        padding-left: 1.7em;
        position: relative;
        ul{
            margin:0;
            li{
                font-size: 12px;
                list-style: square;
                margin: 0;
                margin-left: 15px;
                margin-bottom: 3px;
                padding: 0;
                &:first-child{
                    margin-top: 5px;
                }
                &:before{
                    content: "";
                }
            }
        }
    }

    /deep/ input[type=radio]:checked + label::before,
    /deep/ input[type=radio]:not(:checked) + label::before {
        border-radius: 30px;
    }

    /deep/ input[type='checkbox']:hover  + label::before,
    /deep/ input[type='radio']:hover  + label::before {
        box-shadow: inset 0 0 1px 0 #626262;
    }

    /deep/ input[type='checkbox']:checked  + label::before,
    /deep/ input[type='radio']:checked  + label::before {
        background: var(--color-branding-dark,#274a7f);
        box-shadow: inset 0 0 0 4px white;
    }
    .mobile &{
        input{
            min-width: auto !important;
        }

    }
    /deep/ input[type="radio"][disabled],
    /deep/ input[type="checkbox"][disabled],
    /deep/ input[type="checkbox"]:disabled,
    /deep/ input[type="radio"].disabled,
    /deep/ input[type="checkbox"].disabled,
    /deep/ fieldset[disabled] input[type="radio"],
    /deep/ fieldset[disabled] input[type="checkbox"] {
        cursor: not-allowed;
         + label{
             opacity: 0.8;
         }
    }
    input[type="text"][disabled],
    input[type="text"]:disabled{
        cursor: not-allowed;
        background: white;
        color: #91908f;
        -webkit-text-fill-color:#91908f;
        opacity:1;
    }

}
