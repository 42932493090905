






























.starBarRoot {
    height: 40px;
    margin: 10px;
    svg{
        width: 10%;
    }
}

