































































































.exporo-listview-root {
    padding: 20px;

    .stock {
        transition-property: transform, opacity;
        transition-duration: 500ms;
        transform: translateY(0) scale(1);
        opacity: 0;
        margin: 16px 0;
        will-change: transform, opacity;
        width: calc(100% - 40px);
    }


    tr.open {
        height: 400px;
        width: 72%;
        position: fixed;
        top: 50%;
        margin-top: -200px;
        transition: all 300ms ease;

        .open {
            position: absolute;
            right: 0;
        }

        img {
            width: 200px;
            height: 200px;
        }
    }

    span {
        height: 60px;
        display: block;
        float: left;
        line-height: 60px;
        padding-left: 20px;
    }

    div.open {
        width: 40px;
        height: 40px;
        background: #335586;
        color: white;
        font-size: 30px;
        float: right;
        box-shadow: 0 1px 10px 2px #00000059;
        line-height: 36px;
        text-align: center;
        margin-right: 10px;
        margin-top: 10px;
        border-radius: 20px;
    }

    table {
        width: 100%;
        border-radius: 0 0 3px 3px;
        background: #f3f5f8;

        thead {
            th:first-child {
                border-radius: 3px 0 0 3px;
            }
            th:last-child {
                border-radius: 0 3px 3px 0;
            }

        }

        tbody {
            tr {
                border-radius: 3px;
                cursor: pointer;
            }
        }

        tr {
            height: 60px;
            background: #fff;
            text-align: left;
            box-shadow: 0 0 4px 0 #00000059;

            &:hover {
                background: #e7eaee;
            }

            &:nth-child(even) {
                background-color: #e7eaee
            }

            &:last-child td:last-child {
                border-bottom-right-radius: 3px;
            }

            &:last-child td:first-child {
                border-bottom-left-radius: 3px;
            }

            td {
                padding-left: 5px;
            }
        }

        th {
            padding-left: 15px;
            padding-right: 15px;
            height: 40px;
            color: white;
            background: #51709c;
            text-transform: uppercase;

            &:nth-child(even) {
                background-color: #90a2be;
            }
        }
    }
}
