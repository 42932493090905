













































































































#exporo-siri-root {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 350px;
    height: 100px;
    z-index: -1;
    background: rgba(98, 98, 98, 0.9);
    border-radius: 30px;
    opacity: 0;
    transition: opacity 0.3s;

    &.show {
        z-index: 99999999;
        opacity: 1;
    }

    p {
        position: absolute;
        top: 0;
        width: 100%;
        text-align: center;
        color: white;
    }
}
