










































































































































































































































































































































































.btn-wrapper {
    display: flex;
    justify-content: flex-end;
}
.btn {
 margin-top: 10px;
}
.form-group__flex {
    margin-bottom: 2px;
}
.suggested-format {
    display: flex;
    flex: 2;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #B5B5B5;
    margin-bottom: 10px;
}

