

































































span {
    opacity: 0;
    transition: opacity .1s ease-in;

    &.show {
        opacity: 1;
    }
}
