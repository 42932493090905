



























































































































































































































































































































































































































































































































































































































.center {
  text-align: center;
  margin-top: 50px;
}

.invest-list {
  /deep/ .vgt-table th:last-child:before,
  /deep/ .vgt-table th:last-child:after,
  /deep/ .vgt-table th:nth-child(2):before,
  /deep/ .vgt-table th:nth-child(2):after {
    content: none;
  }
}

.invest-pending-list {
  /deep/ .vgt-table th:before,
  /deep/ .vgt-table th:after {
    content: none;
  }
}

.exporo-invest-list-menu {
  margin-top: 60px;
}

h2 {
  opacity: 0;
  animation: fade-in .3s forwards;
}

section {
  margin-top: 40px;
}

.table-container {
  margin-bottom: 30px;
}


/deep/ .keyfigures-panel {
  opacity: 0;

  &:nth-child(1) {
    animation: slide-in-bottom .3s .1s forwards;
  }

  &:nth-child(2) {
    animation: slide-in-bottom .3s .2s forwards;
  }

  &:nth-child(3) {
    animation: slide-in-bottom .3s .3s forwards;
  }
}


@keyframes slide-in-bottom {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0px)
  }
}


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.description.description-highlight {
  background: #b8c1ce;
  border-radius: 3px;
  color: #fff !important;
  font-weight: 700;
  padding: 1em 3em 1em 1em;
}

