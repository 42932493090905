








































































































































































































.content {
    height: 100%;
    width: 100%;
    position: relative;
}

.appear .entrycard {
    animation-name: none;
    opacity: 1;
}

@keyframes appear {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes disappear {
    to {
        opacity: 0;
        transform: scale(0);
    }
    from {
        opacity: 1;
        transform: scale(1);
    }
}

