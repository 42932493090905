



































































































.highcharts {
    width: 400px;
    height: 250px;
    margin-bottom: 60px;
    overflow: hidden !important;
}

/deep/ .highcharts-title {
    width: 200px;
    text-align: center !important;
    text-transform: uppercase;
    font-size: 12px !important;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    fill: #565655 !important;
}

/deep/ .highcharts-legend {
    font-size: 6px !important;
    fill: #565655;
    color: #fff;
}

@media (max-width: 425px) {

    .highcharts {
        width: 100%;
    }
}

