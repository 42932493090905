


















































.FinancingListFilterRoot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    opacity: 0;
    animation: fade-in .3s forwards;
    z-index: 2;
    position: relative;
    flex-wrap: wrap;
    line-height: 2;

    h2 {
        width: 100%;
        font-size: 24px;
        text-transform: none;
        line-height: 1.3;
        margin-bottom: 5px;
        opacity: 0;
        animation: fade-in .3s forwards;
    }

    ul {
        width: 100%;
        list-style-type: none;
        margin: 1.5% 0 4% 0;
        padding: 0;
    }

    li {
        float: left;
        margin-right: 15px;
        font-size: 13px;

        &:hover {
            cursor: pointer;
        }
    }
}

.selected {
    text-decoration: underline;
}

