

















































.exporo-toggle-root {
    width: 100%;
    display: inline-block;
    cursor: pointer;
    margin-top: 10px;

    input {
        display: none;
    }

    .switch {
        background: #565656;
        border: 1px solid #f4f3f7;
        position: relative;
        display: inline-block;
        box-sizing: content-box;
        overflow: visible;
        width: 52px;
        height: 20px;
        padding: 0;
        margin: 0;
        border-radius: 20px;
        cursor: pointer;
        transition: .3s ease-out all;
        float: right;

        small {
            background: #fff;
            border-radius: 100%;
            width: 18px;
            height: 18px;
            position: absolute;
            top: 1px;
            left: 1px;
            transition: .3s ease-out all;
        }

        &.checked {
            background: #82bc24;
            border-color: #82bc24;

            small {
                left: 33px;
            }
        }
    }
}
