




































.progress {

    display: flex;
    justify-content: space-between;
    align-self: flex-start;

    &-step {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:first-child {
            align-items: flex-start;
            .progress-point {
                left: -4px;
                right: auto;
                &:before {
                    margin-left: 0;
                    width: 16px;
                }
            }
            .progress-label {
                margin-left: 0;
            }
        }
        &:last-child {
            align-items: flex-end;
            .progress-point {
                left: auto;
                right: -4px;
                &:before {
                    width: 16px;
                }
            }
            .progress-label {
                margin-right: 0;
            }
            .progress-bar.is-here .progress-beam {
                background: #335586;
                background-image: none;
            }
        }
    }
    &-bar {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;

        &.is-here {
            .progress-point {
                background-color: #335586;
                &:before {
                    background-image: linear-gradient(to right, #335586 16px, #dbdbdb 20px);
                }
            }
            .progress-beam {
                background-image: linear-gradient(to right, #335586 49.99%, #dbdbdb 50.01%);
            }
            & ~ .progress-label:before {
                content: "\27A4";
                padding-right: 5px;
            }
        }
        &.is-beyond {
            .progress-point {
                background-color: #335586;
                &:before {
                    background: #335586;
                }
            }
            .progress-beam {
                background-color: #335586;
            }
        }
        &.is-start {
            .progress-point {
                background-color: #335586;
                &:before {
                    background-image: linear-gradient(to right, #335586 16px, #dbdbdb 20px);
                }
            }
            .progress-beam {
                background-image: linear-gradient(to right, #335586 16px, #dbdbdb 20px);
            }
        }
    }
    &-point {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border: 4px solid #fff;
        background-color: #dbdbdb;

        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        &:before {
            content: "";
            background: #dbdbdb;
            width: 20px;
            height: 4px;
            display: block;
            margin-top: 4px;
            margin-left: -4px;
        }
    }

    &-beam {
        height: 12px;
        border-top: 4px solid #fff;
        border-bottom: 4px solid #fff;
        background-color: #dbdbdb;
        width: 100%;
    }

    &-label {
        margin: 10px 10px 0;
        border-radius: 3px;
        padding: 5px 0;
        text-transform: uppercase;
        font-size: 12px;
        display: inline-block;
    }

}

@media screen and (max-width: 400px) {
    .progress {
        &-label {
            padding: 5px 0;
            border: none;
            font-size: 10px;
        }
    }
}

