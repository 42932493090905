
























































































































































































.deactivated-investor {
    cursor: default;
}

.deactivated-investor label {
    color: #aaa;
}
.information {
    display: flex;
    max-width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    margin-top: 5px;
    background-color: white;
    background-color: var(--color-background, white);
    border-radius: 3px;
    border: 1px solid #ededf3;
    padding: 1em;
    position: relative;
    a {
        color: #a5afbd;
    }
    &__left {
        width: 50%;
        padding-right: 1em;
        position: relative;
    }
    &__right {
        width: 50%;
        border-left: 1px solid #ededf3;
        padding-left: 1em;
        position: relative;
    }
    .container-headline {
        font-weight: bold;
        font-family: 'Oswald', sans-serif;
        font-size: 18px;
        color: #86909d;
    }
    .user-edit {
        position: absolute;
        bottom: 0;
        right: 1em;
        a {
            color: #b8c1ce;
            font-size: 10px;
            &:hover {
                color: var(--color-branding-dark, #a5afbd);
            }
        }
    }
    &-headline {
        text-transform: uppercase;
    }
    &-highlight {
        background: #b8c1ce;
        border-radius: 3px;
        border: none;
        box-shadow: none;
        color: white;
        font-weight: bold;
        padding: 1em 3em 1em 1em;
        a {
            color: white;
        }
    }
    &-container {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        border: 1px solid #ededf3;
        margin-bottom: 20px;
        padding: 1em 0 0;
        .information {
            border: none;
            box-shadow: none;
        }
        &-headline {
            font-weight: bold;
            font-size: 18px;
            text-transform: uppercase;
            text-shadow: none;
            padding-left: 1em;
        }
    }
}

.mobile {
    .information {
        display: block;
        &__left,
        &__right {
            border: none;
            padding-left: 0;
            width: 100%;
        }
        &__right {
            margin-top: 1em;
        }
    }
}
@media screen and (max-width: 900px) {
    .information {
        display: block;
        &__left,
        &__right {
            border: none;
            padding-left: 0;
            width: 100%;
        }
        &__right {
            margin-top: 1em;
        }

    }
}

.description {
    display: block;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 0 1em 0 0;
    p {
        display: inline-block;
    }
    &-highlight {
        background: #b8c1ce;
        border-radius: 3px;
        color: white;
        font-weight: bold;
        padding: 1em 3em 1em 1em;
        & * {
            color: white !important;
        }
    }
    &-small {
        font-size: 9px;
    }
}

.investor-root {
    /deep/ input[type='checkbox'],
    /deep/ input[type='radio'] {
        position: absolute;
        opacity: 0;
    }

    /deep/ input[type='checkbox'] + label,
    /deep/ input[type='radio'] + label {
        color: #626262;
    }
    /deep/ input[type='checkbox']:checked + label,
    /deep/ input[type='radio']:checked + label {
        color: lighten(#626262, 30);
    }

    /deep/ input[type='checkbox']:checked + label::before,
    /deep/ input[type='checkbox']:not(:checked) + label::before,
    /deep/ input[type='radio']:checked + label::before,
    /deep/ input[type='radio']:not(:checked) + label::before {
        border: 1px solid #b8c1ce;
        background: white;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, .1);
        content: ' ';
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        margin-right: 1em;
        vertical-align: text-top;
        top: 0.2em;
        left: 0.3em;
    }
    /deep/ input[type='checkbox'] + label,
    /deep/ input[type='radio'] + label {
        color: #626262;
        display: block;
        padding-left: 1.7em;
        position: relative;
        ul {
            margin: 0;
            li {
                font-size: 12px;
                list-style: square;
                margin: 0;
                margin-left: 15px;
                margin-bottom: 3px;
                padding: 0;
                &:first-child {
                    margin-top: 5px;
                }
                &:before {
                    content: "";
                }
            }
        }
    }

    /deep/ input[type=radio]:checked + label::before,
    /deep/ input[type=radio]:not(:checked) + label::before {
        border-radius: 30px;
    }

    /deep/ input[type='checkbox']:hover + label::before,
    /deep/ input[type='radio']:hover + label::before {
        box-shadow: inset 0 0 1px 0 #626262;
    }

    /deep/ input[type='checkbox']:checked + label::before,
    /deep/ input[type='radio']:checked + label::before {
        background: var(--color-branding-dark, #274a7f);
        box-shadow: inset 0 0 0 4px white;
    }
    .mobile & {
        input {
            min-width: auto !important;
        }

    }
    /deep/ input[type="radio"][disabled],
    /deep/ input[type="checkbox"][disabled],
    /deep/ input[type="checkbox"]:disabled,
    /deep/ input[type="radio"].disabled,
    /deep/ input[type="checkbox"].disabled,
    /deep/ fieldset[disabled] input[type="radio"],
    /deep/ fieldset[disabled] input[type="checkbox"] {
        cursor: not-allowed;
        + label {
            opacity: 0.8;
        }
    }
    input[type="text"][disabled],
    input[type="text"]:disabled {
        cursor: not-allowed;
        background: white;
        color: #91908f;
    }

}

