






























.cr-btn-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
        width: 30px;
        height: 50px;
    }
}

