














































































































































































































































section {
    padding-bottom: 100px;
    padding-top: 40px;
}

.hero {

    &-pagination {
        display: flex;
        justify-content: center;
        top: 10px;
        width: 100%;
        position: absolute;
        z-index: 2;
    }

    &-image {
        background: url("../../../assets/Investmentcockpit-1.jpg") no-repeat center center;
        width: 100%;
        min-height: 300px;
        background-size: cover;
        border-radius: 5px 5px 0 0;
        overflow: hidden;

        @media screen and (max-width: 700px) {
            background: url("../../../assets/Investmentcockpit-1.jpg") no-repeat center right;
            width: 100%;
            min-height: 300px;
            background-size: cover;
        }
    }

    &-header {
        max-width: 760px;
        margin: -170px auto 0;
        position: relative;
        z-index: 1;

        .headline {
            font-size: 25px;
            font-family: "Oswald", sans-serif;
            padding-left: 40px;
            text-transform: uppercase;
            color: #fff;
            max-width: 300px;
            line-height: 1.4;

            opacity: 0;
            animation: slidedown .3s forwards .1s;
        }

        .card {
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);
            background-color: #fff;
            margin-top: 35px;
            display: grid;
            grid-template: "1fr 1fr";
            grid-column-gap: 80px;
            padding: 5px 0;

            opacity: 0;
            animation: slidedown .3s forwards .2s;

            &-entry {
                display: flex;
                justify-content: space-between;
                padding: 10px 20px;
                font-weight: bold;
                font-size: 12px;
                letter-spacing: .3px;
                border-top: 1px solid #e5e5e5;

                &:nth-child(1),
                &:nth-child(2) {
                    border-top: none;
                }
            }

            &-key {
                color: #888;
            }

            &-value {
                color: #555;
                text-align: right;
            }
        }
    }

}

.panel {
    opacity: 0;
    animation: fade-in .3s forwards .3s;
    max-width: 760px;
    margin: 30px auto;
}

table {
    width: 100%;
    table-layout: fixed;

    thead {
        font: 16px "Oswald", sans-serif;
        color: #555;
        text-align: left;
    }

    tbody {
        font: 14px sans-serif;
        color: #888;
        letter-spacing: 1px;
    }

    tr {
        &:nth-child(even) {
            background-color: #f5f5f5;
        }
    }

    th {
        padding-bottom: 8px;
        font-weight: 400;

        &:last-child {
            width: 150px;
        }
    }

    td {
        padding: 10px 0;
    }
}


@media screen and (max-width: 800px) {

    .hero-header {
        .card {
            grid-column-gap: 0;

            &-entry {
                flex-direction: column-reverse;
            }

            &-key {
                margin-top: 5px;
                color: #999;
            }

            &-value {
                font-size: 14px;
                text-align: left;
            }
        }
    }


    table {
        table-layout: auto;

        thead {
            font-size: 14px;
        }

        tr {
            &:nth-child(even) {
                background-color: #f5f5f5;
            }
        }

        th {
            line-height: 1.2;

            &:last-child {
                width: auto;
                text-align: right;
            }
        }

        td {
            padding-left: 5px;
            padding-right: 5px;

            &:last-child {
                text-align: right;
            }
        }
    }

}


@keyframes slidedown {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


