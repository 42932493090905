
















































































































































.root {
    padding-bottom: 80px;
}

h2 {
    opacity: 0;
    animation: fade-in .3s forwards;
}

section {
    margin-top: 25px;
    opacity: 0;
    animation: fade-in .3s forwards .3s;
}

/deep/ .investor-create-root {
    h1 {
        display: none;
    }
}

.investor-entry {
    padding: 0 20px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    line-height: 60px;
    display: flex;
    justify-content: space-between;

    &:nth-child(2n+1) {
        background: #fff;
    }

    &-controls {
        display: flex;
        align-items: center;
        div {
            border: 1px solid #ccc;
            border-radius: 100px;
            width: 40px;
            height: 40px;
            line-height: 1;
            font-size: 16px;
            cursor: pointer;
            transition: all .3s ease;
            &:hover {
                background: #ccc;
            }
        }
    }
    &-edit {
        padding: 12px 13px;
    }
    &-delete {
        padding: 11px 14px;
        margin-left: 8px;
    }
    &-disabled {
        cursor: not-allowed !important;
        background: #ccc;
        opacity: 0.25;
    }
}
.investor-addbutton {
    padding: 10px 15px;
    margin-top: 25px;
    background: #314e79;
    color: #fff;
    border-radius: 5px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}
.investor-addinvestor {
    margin-top: 25px;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

