




































































.image-upload {
    input.image-upload {
        background: white;
        box-shadow: none;
        padding: 10px;
        border: 1px solid #b8c1ce;
        border-radius: 3px;
        color: #91908f;
        font-size: 14px;
        font-family: inherit;
        font-weight: bold;
        line-height: 17px;
        margin-right: 0;
        outline: 0;
        width: 100%;
        margin-left: 10px;
        &:focus, &:hover, &.active {
            background: rgb(242, 244, 247);
            box-shadow: inset 0 0 0 4px white;
        }
        .mobile &{
            margin-left: 0;
        }
        @media screen and (max-width: 900px) {
            margin-left: 0;
        }
    }
}
