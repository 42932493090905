













































































































































































.force-email-checkbox {
    position: relative;

    input[disabled] + label {
        color: #b8b8b8 !important;
    }

    label {
        line-height: 17px;
        padding: 5px 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        color: #565656;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
        font-weight: 300;
        width: 100% !important;

        a {
            color: #1e73be;
            font-weight: normal;
            line-height: 17px;
            text-decoration: none;
        }

        &:before {
            content: ' ';
            margin-right: 5px;
            display: inline-block;
            background: #ededf3;
            border: 1px solid #878787;
            width: 15px;
            height: 15px;
            position: relative;
            top: 2px;
            -webkit-font-smoothing: antialiased;
            font-size: 10px;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
        }
    }

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #ededf3 inset;
    }

    input {
        &[type="email"] {
            border-radius: 0;
            background-color: #ededf3;
            box-shadow: none;
            font-size: 14px;
            line-height: 17px;
            margin-right: 0;
            outline: 0;
            padding: 5px 10px;
            max-width: 100%;
            border: none;
            border-right: 1px solid #878787;
            border-bottom: 1px solid #878787;
            width: 65%;
        }

        &[type="checkbox"]:focus {
            outline-offset: -2px;
        }

        &[type="checkbox"] {
            position: absolute;
            opacity: 0;
            background-color: #fff;
            border: 1px solid #ededf3;
            border-radius: 5px;
            float: left;
            margin: 4px 10px 0 0;
            box-shadow: none;
            font-size: 14px;
            line-height: 17px;
            outline: 0;
            padding: 5px 10px;
            max-width: 100%;

            &:checked + label:after {
                font-family: 'icomoon', serif;
                content: '\e627';
                position: absolute;
                left: 0px;
                top: 6px;
            }
        }
    }
}

