




















































































































































































































































































































.secondary-market-no-image {
    top: -100px;
    margin-bottom: -75px;
    padding-bottom: -75px;
    position: relative;
    border-radius: 4px;
    background-image: url(https://exporo-de-prod.s3.eu-central-1.amazonaws.com/public/handelsplatz_blur.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 980px) {
        margin-left: -10%;
        margin-right: -10%;
        padding-top: 10px !important;
    }
    @media screen and (min-width: 1180px) {
        margin-left: -18%;
        margin-right: -18%;
    }
}

.login-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 200px;
    background: white;
    box-shadow: 0 6px 20px 3px #00000059;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 10px;
}

.secondary-market {
    /deep/ li {
        margin: 0;

        /deep/ .selected:before {
            padding-right: 0;
            left: 20px;
            top: 24px;
        }
        &:before {
            content: "";
        }
    }

    @media screen and (min-width: 980px) {
        margin-left: -10%;
        margin-right: -10%;
    }
    @media screen and (min-width: 1180px) {
        margin-left: -18%;
        margin-right: -18%;
    }
    @media screen and (min-width: 1180px) {
        margin-left: -25%;
        margin-right: -25%;
    }
}

.header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    @media screen and (max-width: 992px) {
        justify-content: center;
    }
}


