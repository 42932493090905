



















































.exporo-accordion.mobile {
    .exporo-header {
        line-height: unset;
        height: auto;

        & > div {
            line-height: unset;
            padding: 0.5em 0;
        }
    }
}

.exporo-accordion {
    max-width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    margin-top: 5px;
    background-color: white;
    background-color: var(--color-background, white);
    border-radius: 3px;
    border: 1px solid #ededf3;

    .exporo-header {
        height: 50px;
        line-height: 50px;
        padding: 0 40px 0 23px;
        position: relative;
        color: var(--color-branding-dark, #86909d);
        cursor: pointer;
        font-weight: bold;
        font-size: 14px;
        transition: 0.4s all ease;

        &:hover {
            background: var(--color-branding-dark, #b8c1ce);
            color: white;

            .toggle-icon {
                &:before,
                &:after {
                    background: white;
                }
            }
        }

        .toggle-icon {
            display: block;
            width: 1em;
            height: 1em;
            position: absolute;
            right: 1em;
            top: 1.2em;
            @media screen and (max-width: 992px) {
                top: 0.7em;
            }

            &:before {
                content: "";
                display: block;
                background: var(--color-branding-dark, #a5afbd);
                width: 100%;
                height: 0.1em;
                top: calc(50% - 0.05em);
                position: absolute;
            }

            &:after {
                content: "";
                display: block;
                background: var(--color-branding-dark, #a5afbd);
                height: 100%;
                width: 0.1em;
                right: calc(50% - 0.08em);
                position: absolute;
                -webkit-transition: all .35s;
                -o-transition: all .35s;
                transition: all .35s;
            }

            &.active {
                &:after {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .body {
        overflow: hidden;
        border-radius: 0;
        height: auto;
        max-height: 600px;
        transition: max-height 150ms ease-in;
        transition: 0.4s all ease;

        &.active {
            transition: max-height 150ms ease-out;
            max-height: 0;
        }
    }

    .body-inner {
        overflow-wrap: break-word;
        padding: 18px 20px;

        p {
            margin: 0;
        }
    }
}


