


























































































.options {
    z-index: 3;
    user-select: none;
}

ul {
    white-space: nowrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

li [data-key="eie"] {
  font-weight: bold;
  color: #274a7f;
}

ul {
    > li {

        &.disabled {

            opacity: .3;

            &:hover {
                background-color: white;
            }
        }

        > ul {
            margin-top: 5px;
            border-radius: 5px;
            border: 1px solid #dbdbdb;
            box-shadow: 0 0 20px rgba(0, 0, 0, .12);
            background-color: #fff;
            font-size: 14px;
            padding: 4px;
            transition: opacity .3s ease, transform .3s ease;
            opacity: 0;
            transform: translateY(-30px) scale(.9);

            > li {
                padding: 15px 25px;
                cursor: pointer;
                transition: all .3s ease;

                &:hover {
                    background-color: #eff2f5;
                }
                & + li {
                    border-top: 1px solid #dbdbdb;
                }

                &.selected:before {
                    content: "";
                    display: inline-block;
                    background: #375488;
                    height: 5px;
                    width: 5px;
                    border-radius: 10px;
                    margin: 0 7px 2px -13px;
                }

            }

        }
    }
}


.visible {

    > ul {
        pointer-events: auto;

        > li {
            position: relative;

            &:nth-child(1) {
                z-index: 4;
                ul {
                    transition-delay: 0s;
                }
            }
            &:nth-child(2) {
                z-index: 3;
                ul {
                    transition-delay: .1s;
                }
            }
            &:nth-child(3) {
                z-index: 2;
                ul {
                    transition-delay: .2s;
                }
            }
            &:nth-child(4) {
                z-index: 1;
                ul {
                    transition-delay: .3s;
                }
            }

            ul {
                opacity: 1;
                transform: translateY(0) scale(1);
            }

        }
    }
}


