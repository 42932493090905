



























































































































































































































































































































































































.invest-list {
    padding-bottom: 120px;

    /deep/ .vgt-table th:last-child:before,
    /deep/ .vgt-table th:last-child:after,
    /deep/ .vgt-table th:nth-child(2):before,
    /deep/ .vgt-table th:nth-child(2):after {
        content: none;
    }
}

.payment-list {
    /deep/ .vgt-table th:last-child:before,
    /deep/ .vgt-table th:last-child:after {
        content: none;
    }
}


.exporo-cockpit-tab-bar-root {
    margin-top: 60px;
}

.exporo-cockpit-tab-bar-root {
    margin-bottom: 20px;
}

/deep/ h2 {
    opacity: 0;
    animation: fade-in .3s forwards;

    hyphens: auto;
    color: #626262;
    font-size: 24px;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
    line-height: 1.3;
    text-transform: none;
    margin-bottom: 5px;
}

section {
    margin-top: 40px;
}

/deep/ .keyfigures-panel {
    opacity: 0;

    &:nth-child(1) {
        animation: slide-in-bottom .3s .1s forwards;
    }

    &:nth-child(2) {
        animation: slide-in-bottom .3s .2s forwards;
    }

    &:nth-child(3) {
        animation: slide-in-bottom .3s .3s forwards;
    }

    &:nth-child(4) {
        animation: slide-in-bottom .3s .4s forwards;
    }
}

@keyframes slide-in-bottom {
    from {
        opacity: 0;
        transform: translateY(-40px);
    }
    to {
        opacity: 1;
        transform: translateY(0px)
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

