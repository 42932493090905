






























































































































h1,
h2 {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 30px;
    color: var(--color-branding-dark, #274a7f);
    line-height: 1.1;
    -webkit-font-smoothing: antialiased;
    text-align: left;
}

p {
    margin: 5px 0;
}

.form-group {
    align-items: center;
    display: flex;
    margin-bottom: 15px;

    & > * {
        flex: 2;
    }

    & > div {
        display: flex;

        & > * {
            flex: 1;
            margin-left: 10px;

            &.flex-2 {
                flex: 2;
            }
        }

        & > :first-child {
            margin-left: 0;
        }
    }

    label {
        flex: 1;
        font-family: 'Oswald', sans-serif;
        font-weight: 400;
        font-size: 18px;
        color: #86909d;
    }

    input[type="radio"],
    input[type="checkbox"] {
        ~ label {
            font-family: 'Open Sans', sans-serif;
            color: #91908f;
        }

    }

    /deep/ input.error {
        border: 1px solid red !important;
    }

    &__inline {
        display: block;

        & > *,
        & > div {
            display: inline-block;

            ~ * {
                margin-left: 3em;
            }
        }
    }

    &.description-rules {
        & > div {
            display: block;

            & > * {
                margin-left: 0;
            }
        }

        .description {
            margin-top: 15px;
        }
    }
}

.btn-container {
    text-align: right;

    .btn {
        display: inline-block;
        background: var(--color-branding-dark, #274a7f);
        position: relative;
        border-radius: 50px;
        color: #fff;
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 10px;
        padding: 16px 30px;
        text-decoration: none;
        text-transform: uppercase;
        transition: all .5s ease-out;
    }
}

.description {
    display: block;
    margin-bottom: 15px;
    padding: 0 1em 0 0;

    &-highlight {
        background: #b8c1ce;
        border-radius: 3px;
        color: white;
        padding: 1em 3em 1em 1em;
    }
}

.error-message {
    display: block;
    margin-top: -13px;
    margin-bottom: 15px;
    text-align: right;
    color: tomato;
}
