
















































































































































.form-success {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 30px;
    left: 50%;
    margin-left: -250px;


    &-side {
        flex: 1;
    }

    &-main {
        flex: 2;
        display: flex;
        justify-content: flex-start;
    }

    &-text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 3px;
        border: 1px solid #FC1908;
        font-size: 13px;
        color: #FC1908;
        padding-right: 7px;

        &:before {
            content: "\26A0";
            font-size: 18px;
            line-height: 1;
            padding: 3px 6px 3px 5px;
            color: #fff;
            background: #FF2410;
            display: flex;
            align-items: center;
            margin-right: 7px;
        }
    }
}

.additional-link {
    margin: 0 auto;
    max-width: 1200px;
    width: auto;
    padding: 0 10px;
    font-size: 14px;
    z-index: 13;
    text-decoration: underline;
    text-decoration-color: #1e73be;
    text-align: end;
}

.exporo-cockpit-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 250px;
    background: #fff;
    transition-duration: 400ms;
    transform: translateY(-100%);
    z-index: 2;

    &.open {
        transform: translateY(0%);
    }

    .wrapper-navigation {
        max-width: 1200px;
        width: 100%;
        padding: 40px 10px 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        position: absolute;
        z-index: 2;
        display: flex;
        justify-content: flex-end;
        pointer-events: none;

        a {
            margin-top: 50px;
            pointer-events: all;
            text-decoration: underline;
            font-size: 12px;
        }

        .backbutton {
            pointer-events: auto;
            background: #2E4B7B;
            color: #fff;
            border-radius: 40px;
            font-size: 16px;
            text-transform: uppercase;
            padding: 5px 30px;
            cursor: pointer;
            letter-spacing: normal;
            line-height: normal;
            height: 30px;
            border: none;
        }
    }

    .wrapper-content {
        position: relative;
        max-width: calc(100% - 20px);
        margin: 0 auto;
        width: 1100px;
        padding: 0 10px;

        .logo {
            width: 180px;
            height: 40px;
            margin-top: 40px;
            margin-left: -10px;
            a {
                display: block;
            }
            &-text {
                fill: #333;
            }

            &.center {
                margin: 20px auto !important;
            }
        }

        .welcome {
            margin-top: 38px;
            .name {
                font-size: 14px;
                color: #626262;
            }
            .headline {
                font-family: 'Oswald', sans-serif;
                font-size: 34px;
                color: #2E4B7B;
                line-height: 1.1;
                font-weight: 400;
                text-transform: uppercase;
            }
            .uid {
                display: inline-block;
                color: #358;
                font-size: 11px;
                &:before {
                    content: attr(data-nr);
                }
            }

        }

    }


}

@media screen and (max-width: 400px) {

    .exporo-cockpit-header {
        .wrapper-content {
            .logo {
                width: 150px;
            }
            &.center {
                .headline {
                    text-align: center !important;
                }
                text-align: center !important;
            }
        }
    }

}


