









































































.cockpit-menu-root {
    z-index: 3;
    pointer-events: none;
    height: 40px;
    user-select: none;

    .menu-wrapper {
        display: inline-block;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .checkbox {
        width: 100%;
        text-align: center;
    }

}

