














































































































































thead {
    opacity: 0
}
.detail-view-root {
    padding: 0 30px 20px 20px;
    width: 100%;
    height: auto;
    position: relative;
}

.infodetails {
    opacity: 0;
    margin-top: 320px;
}

.open.close {
    color: transparent;
}

#leave {
    width: 30px;
    height: 30px;
    padding: 3px;
    border-radius: 20px;
    text-align: center;
    color: #5b7ba6;
    background-color: #fff;
    position: absolute;
    right: 25px;
    top: 10px;
    cursor: pointer;
    font-weight: bold;
}

.detailsheader {
    background-color: #5b7ba6;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    transition-property: transform;
    transition-duration: 500ms;
    transform: translateY(-100%);
    border-radius: 3px;

    h6 {
        text-align: left;
        width: 100%;
        max-width: unset;
        color: #fff;
        margin-top: 20px;
        margin-left: 30px;
        font-size: 30px;
        font-weight: bold;
        font-family: Helvetica, serif;

    }
}

.visible .detailsheader {
    transform: translateY(0%);
}

.visible .fabs {
    animation-name: rise;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.visible .infodetails {
    animation-name: drop;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
}

.leaving .fablike, .leaving .fabwallet{
    animation-name: drown;
    animation-duration: 100ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

.leaving .infodetails {
    animation-name: rise;
    animation-duration: 300ms;
    animation-fill-mode: backwards;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes drop {
    from {
        opacity: 0;
        transform: translateY(-10px)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes rise {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes drown {
    from {
        opacity: 1;
        transform: translateY(0px);
    }
    to {
        opacity: 0;
        transform: translateY(50px);
    }

}

td {
    p {
        margin: 0 !important;
        line-height: 60px;
        height: 60px;
    }
}

div.open {
    width: 40px;
    height: 40px;
    background: #335586;
    color: white;
    font-size: 30px;
    float: right;
    box-shadow: 0 1px 10px 2px #00000059;
    line-height: 36px;
    text-align: center;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 20px;
}

h1 {
    background-color: var(--color-branding, #274a7f);
    color: var(--color-branding-text, #fff);
    text-transform: uppercase;
    border-radius: 3px 3px 0 0;
    margin: 0;
    font-size: 20px;
    padding: 14px;
    height: 50px;
}

table {
    width: 100%;
    border-radius: 0 0 3px 3px;
    background: #f3f5f8;

    thead {
        th:first-child {
            border-radius: 3px 0 0 3px;
        }
        th:last-child {
            border-radius: 0 3px 3px 0;
        }

    }

    tbody {
        tr {
            border-radius: 3px;
            cursor: pointer;
        }
    }

    tr {
        height: 60px;
        background: #fff;
        text-align: left;
        box-shadow: 0 0 4px 0 #00000059;

        &:hover {
            background: #e7eaee;
        }

        &:nth-child(even) {
            background-color: #e7eaee
        }

        &:last-child td:last-child {
            border-bottom-right-radius: 3px;
        }

        &:last-child td:first-child {
            border-bottom-left-radius: 3px;
        }

        td {
            padding-left: 5px;
        }
    }

    th {
        padding-left: 15px;
        padding-right: 15px;
        height: 40px;
        color: white;
        background: #51709c;
        text-transform: uppercase;

        &:nth-child(even) {
            background-color: #90a2be;
        }
    }
}
