


























































.root-log-to-see{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /deep/
    .exporo-modal[name="login-to-see-financing"] {
        top: 10%;
    }
}
.root-logtosee::after{
    width: 100%;
}
.content-text{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    display: inline-block;
    text-align: center;
    width: 100%;
    @media screen and (max-width: 760px) {
        font-size: 12px;
    }
    @media screen and (max-width: 321px) {
        font-size: 10px;
    }
}

