

































































































































































































































































































































































































































































































































































































































































































































































































































































.exporo-user-edit {
    color: #626262;
    font-size: 14px;

    #user-edit-form:after {
        content: "";
        display: table;
    }

    /deep/ .tab {
        &__menue {
            padding-left: 0;
            li {
                display: inline-block;
                font-family: 'Oswald', sans-serif;
                font-size: 18px;
                list-style: none;
                margin: 0;
                padding-right: 2em;
                text-transform: uppercase;
                transition: all 0.4s;

                &:before {
                    content: "";
                }

                &:hover,
                &.active {
                    text-align: center;

                    a {
                        color: var(--color-branding-dark, #274a7f);
                    }

                    &:after {
                        background: var(--color-branding-dark, #274a7f);
                        content: "";
                        display: block;
                        height: 2px;
                        margin: 0.5em auto;
                        width: 30px;
                    }
                }

                a {
                    color: #91908f;
                    font-weight: 400;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }
                }
            }
        }

        &__content {
            .form-success {
                display: flex;
                overflow: hidden;
                margin-top: 30px;

                &-side {
                    flex: 1;
                }

                &-main {
                    flex: 2;
                    display: flex;
                    justify-content: flex-start;
                }

                &-text {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-radius: 3px;
                    border: 1px solid #1f9a31;
                    font-size: 14px;
                    color: #1f9a31;
                    padding-right: 7px;

                    &:before {
                        content: "\2713";
                        font-size: 14px;
                        line-height: 1;
                        padding: 3px 6px 3px 5px;
                        color: #fff;
                        background: #1f9a31;
                        display: flex;
                        align-items: center;
                        margin-right: 7px;
                    }
                }
            }
        }
    }

    .description {
        display: inline-block;
        margin-bottom: 15px;
        padding: 0;

        &-highlight {
            background: #b8c1ce;
            border-radius: 3px;
            color: white;
            font-weight: bold;
            padding: 1em 3em 1em 1em;

            &:after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                border-top: 2px solid white;
                border-right: 2px solid white;
                transform: rotate(45deg);
                float: right;
                margin-right: -2em;
                margin-top: 5px;
            }
        }

        &-hint {
            display: block !important;
            font-size: 14px;
        }
    }

    a.description-highlight {
        cursor: pointer;
        color: white;

        &:hover {
            background: darken(#b8c1ce, 2);
            color: white;
        }
    }

    .form-group {
        align-items: center;
        display: flex;
        margin-bottom: 15px;

        & > * {
            flex: 2;
        }

        & > div {
            display: flex;

            & > * {
                flex: 1;
                margin-left: 10px;

                &.flex-2 {
                    flex: 2;
                }
            }

            & > :first-child {
                margin-left: 0;
            }
        }

        label {
            flex: 1;
            font-family: 'Oswald', sans-serif;
            font-weight: 400;
            font-size: 18px;
            color: #86909d;
        }

        input[type="radio"],
        input[type="checkbox"],
        input.checkbox {
            ~ label {
                font-family: 'Open Sans', sans-serif;
                color: #91908f;
                font-size: 14px;
            }

        }

        /deep/ input.error {
            border: 1px solid red !important;
        }

        &__inline {
            display: block;

            & > *,
            & > div {
                display: inline-block;

                ~ * {
                    margin-left: 3em;
                }
            }
        }

        &.description-rules {
            & > div {
                display: block;

                & > * {
                    margin-left: 0;
                }
            }
        }
    }

    .checkbox-tooltip {
        margin-left: 12px;
    }

    .btn-container {
        text-align: right;

        .btn {
            display: inline-block;
            background: var(--color-branding-dark, #274a7f);
            position: relative;
            border-radius: 50px;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 1px;
            line-height: 10px;
            padding: 16px 30px;
            text-decoration: none;
            text-transform: uppercase;
            transition: all .5s ease-out;
        }
    }

    &.mobile {
        padding: 0;
        overflow: hidden;

        /deep/ .tab {
            &__menue {
                li {
                    padding-right: 0.5em;
                }
            }

            &__content {
                padding: 0;
            }
        }

        .form-group {
            display: block;
        }

        label:empty {
            margin: 0;
        }

    }

    @media screen and (max-width: 900px) {
        padding: 0;
        overflow: hidden;
        p {
            text-align: center;
        }

        /deep/ .tab {
            &__menue {
                width: 100%;
                text-align: center;
                margin-bottom: 2vh;
                li {
                    display: inline-block;
                    padding-right: 1rem;
                }
            }

            &__content {
                :nth-child(1) :first-child :first-child label {
                    align-content: flex-start;
                    margin-left: 0;
                }
            }
        }
        .form-group {
            display: block;
        }
        label:empty {
            margin: 0;
        }
        .form-success-side {
            display: none;
        }
    }

    .miles-and-more {
        opacity: var(--miles-and-more-visibility, 1);
        height: var(--miles-and-more-visibility, auto);
    }

}

.success-anim-leave-active {
    animation: success-animation 0.2s linear forwards;
}

.success-anim-enter-active {
    animation: success-animation 0.2s linear reverse;
}

@keyframes success-animation {
    0% {
        opacity: 1;
        margin-top: 30px;
        max-height: 30px;
    }
    100% {
        opacity: 0;
        margin-top: 0px;
        max-height: 0;
    }
}

.Warning {
    @media screen and (min-width: 901px) {
        margin-left: 33%;
        margin-bottom: 1%;
    }
}

