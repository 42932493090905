

























































































































































/deep/ .exporo-overlay {
    width: 100% !important;
}

.hero {
    position: relative;

    &-close {
        padding: 15px;
        top: 0;
        right: 0;
        position: absolute;
        z-index: 2;
        cursor: pointer;

        &-inner {
            color: #fff;
            font-size: 20px;
            line-height: 1.3;
            border-radius: 100px;
            width: 30px;
            height: 30px;
            text-align: center;
            border: 1px solid #fff;
            transition: all .3s ease;
        }

        &:hover .hero-close-inner {
            background-color: #fff;
            color: #555;
        }
    }

    &-image {
        margin-top: 20px;
        background-color: #000;
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        opacity: 0;
        animation: slidedown .3s forwards;

        img {
            display: block;
            height: 300px;
            width: 100%;
            object-fit: cover;
            object-position: center center;
            opacity: .7;
        }
    }

    &-header {
        max-width: 760px;
        /* margin: -170px auto 0; */
        position: relative;
        z-index: 1;

        .headline {
            font-size: 25px;
            font-family: "Oswald", sans-serif;
            padding-left: 40px;
            text-transform: uppercase;
            color: #fff;
            max-width: 270px;
            line-height: 1.4;

            opacity: 0;
            animation: slidedown .3s forwards .1s;
        }

        .card {
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);
            background-color: #fff;
            margin-top: 35px;
            display: grid;
            grid-template: "1fr 1fr";
            grid-column-gap: 80px;
            padding: 5px 0;

            opacity: 0;
            animation: slidedown .3s forwards .2s;

            &-entry {
                display: flex;
                justify-content: space-between;
                padding: 10px 20px;
                font-weight: bold;
                font-size: 12px;
                letter-spacing: .3px;
                border-top: 1px solid #e5e5e5;

                &:nth-child(1),
                &:nth-child(2) {
                    border-top: none;
                }
            }

            &-key {
                color: #888;
            }

            &-value {
                color: #555;
                padding: 0px 0 0 10px;
                text-align: left;

                a {
                    color: #51709c;
                }

                &-disabled {
                    color: #aaa;
                }
            }


            &.bigkpis {
                grid-template: "1fr 1fr 1fr";
                grid-column-gap: 0;
                grid-row-gap: 15px;
                padding: 20px 60px;

                .card {
                    &-entry {
                        border-top: none;
                        flex-direction: column-reverse;
                        /* justify-content: flex-start; */
                        align-items: flex-start;
                    }

                    &-value {
                        font-size: 18px;
                        font-weight: 600;
                    }
                }
            }

            @media screen and (max-width: 800px) {
                grid-template: "1fr";
                .card {
                    &-entry {
                        flex-direction: column;
                    }
                    &-key {
                        padding-bottom: 0.5rem;
                    }

                    &-value {
                        color: #555;
                        padding: 0px 0 0 0px;
                        text-align: left;
                    }
                }
            }
        }
    }

}

.hero-header.hidden2 {
    animation: slide-out .2s linear;
}

.panel {
    opacity: 0;
    animation: fade-in .3s forwards .3s;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    max-width: 760px;
    margin: 35px auto 0;


    &-content {
        padding: 35px 60px;
    }

    &-title {
        font-family: "Oswald", sans-serif;
        font-size: 19px;
        color: #545454;
        max-width: 500px;
        line-height: 1.2;
        letter-spacing: .3px;
    }

    &-date {
        font-size: 12px;
        color: #A1A1A1;
        margin-top: 8px;
        font-weight: bold;
        letter-spacing: .3px;
    }

    p {
        margin-top: 10px;
        font-size: 14px;
        color: #787878;
        line-height: 1.5;
        letter-spacing: .3px;
    }

    &-list {
        margin-top: 25px;
        list-style: none;
        // list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAAAXNSR0IArs4c6QAAAfZJREFUKBV9Uk1IVFEUPufdaYZRCAmRQCg3Ek2g49BOHWeQiPHZomWrgjYlSOAiahEELly1UDCirWSrWkTP16JwnBIipjcjtZA2ZVQE0w/B2MOYuafvjlx5SnY359zvnO+793z3MkWW/2LtmDT0FWI6SUIplD4J0XPk825+oGpbvVLlAokMsQWWipWrwjQNIG6xSIQGzRWy6Sm/VD2HfIFI3rbI/kpwTYhnIs37pHwLpCnc5DMLDStvNTgqmh9BZecW+zCX6tn0xfjG1x4QzxfyAx8cbjiTYDmGgLu9Q/ixlyxCT+ud8bOHXq134Ixvhmh6QJKCbWbtjCqtBiHy02Iw5mWbdJxJhmFsKwyfCemJcrl8YJssdMQ2CuuJ0/m+dSI1YgSE5HVSEqdqXb+1qiuPmfuZOPl9M3F4mxyZlZmue6XgkjvS90a0k42369FaLhW21/48gCM5ewiRjpk8JiwfoXbcFlj4tlcMfrm5/vsG84qVRYiO27qINJvdbV/MPgZFH07tkIEZ1xe8lepBFn0CO/OukcXLY729WwZwSNEc5tORKmE2xSR3kExG8VaueNZijjuY2WCRmxb4X4SBD93h9GPb03rfsVxmGj/sBp6lYQv/iHc3OxO7Rtj1q56U1jJNrS8zSwY+pPDX38PMAKL3cIC/V/AvJoe10PZ+ikkAAAAASUVORK5CYII=');
        font-size: 14px;
        color: #787878;
        line-height: 1.5;
        letter-spacing: .3px;
        padding-left: 0px;

        li {
            margin-bottom: 20px;
            padding-left: 30px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                display: inline-block;
                width: 15px;
                height: 14px;
                left: 0px;
                top: 4px;
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAAAXNSR0IArs4c6QAAAfZJREFUKBV9Uk1IVFEUPufdaYZRCAmRQCg3Ek2g49BOHWeQiPHZomWrgjYlSOAiahEELly1UDCirWSrWkTP16JwnBIipjcjtZA2ZVQE0w/B2MOYuafvjlx5SnY359zvnO+793z3MkWW/2LtmDT0FWI6SUIplD4J0XPk825+oGpbvVLlAokMsQWWipWrwjQNIG6xSIQGzRWy6Sm/VD2HfIFI3rbI/kpwTYhnIs37pHwLpCnc5DMLDStvNTgqmh9BZecW+zCX6tn0xfjG1x4QzxfyAx8cbjiTYDmGgLu9Q/ixlyxCT+ud8bOHXq134Ixvhmh6QJKCbWbtjCqtBiHy02Iw5mWbdJxJhmFsKwyfCemJcrl8YJssdMQ2CuuJ0/m+dSI1YgSE5HVSEqdqXb+1qiuPmfuZOPl9M3F4mxyZlZmue6XgkjvS90a0k42369FaLhW21/48gCM5ewiRjpk8JiwfoXbcFlj4tlcMfrm5/vsG84qVRYiO27qINJvdbV/MPgZFH07tkIEZ1xe8lepBFn0CO/OukcXLY729WwZwSNEc5tORKmE2xSR3kExG8VaueNZijjuY2WCRmxb4X4SBD93h9GPb03rfsVxmGj/sBp6lYQv/iHc3OxO7Rtj1q56U1jJNrS8zSwY+pPDX38PMAKL3cIC/V/AvJoe10PZ+ikkAAAAASUVORK5CYII=');
            }
        }

        &.is-download li:before {
            width: 18px;
            height: 21px;
            top: -2px;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAWCAYAAAAinad/AAAAAXNSR0IArs4c6QAAArBJREFUOBGVVF9IU2EUP+fbvUm1/urrXiKiXsorC4bN6SBDZyE99BpBkQ9ZQVFBb0YUIgRRD/15iIgeQ8ici4g59aFoco0IgmEEgaWUBi6n7t57Ot/Vb21uuvk97Hzn/H7n95193zkXoMyKJsye6KDZW4bmwlp5EhmEuKs8D0BUQqqUg6sRXyVG9wFgPQqaBFssIFCA0Bm3PN6+9uDe2VJ5BWLRVKoKJmY7mXiRhXwyQQhqtkD7Khx7XPoElEHAhxsd6AqHjT8yplZO7E0yuS371xNnwFCgtCvFchjRbwGe4y1NB4ZVzL2zZDKpZ9MixkFXiADeK0KxpU9c3RwgVjtovx2IjwUVxxWbSnvuMhhYDn5oazQCXPIpFnUU0bUEj1tDRi3vny3FUXfQ6Y3HP3ulL2Lx0d2E0LEEur8Ho0Njna2NxlMQ2E6WmEdh2UR0OdJknI2NjIWYdVLxEbEmI7LnpK85As9wFYUtQnSPBSHSUHtfJbG9MzBsNpENMRaoyovzozgn2O/G/kHTRARZevFCPB8JLQkyr5kP7QOEAqHlJEpPpnTsT5jTTNpRrKQi9IDbZISb4glbXUVX2rSe2YQ8d/OrnLaSv6bvmRdeAUhTa7IqBL9VZxc1IvzCd+Z2+3KeuV2vOVRf78uU0uFXFdEh8xFPwen/OP3o8PuzGgp8CUTNOYBgz8ziry6+y7lcLG8zkDDlyx/mq8lfL6SjOSieI9m3GNviogibeX8ln1mw579RoEOwoGlwW3LE0Yb9M4xeKkhYn3PjSLBuQqbkDuF76Obuu7o+HXrN49XKTcyTl9f5kZBxjQf4ZoVi/A7Qkw4ZbUpI5uUqUyLR+McAoH2dkZYSTcoV0Dty4EJbuC6pcpQtElOA/Cz9zGzwoWXtFAg6OPa0tRW/H/P7S76yzPsHG7LxNZdUQX0AAAAASUVORK5CYII=');
        }
    }

    &-morebutton {
        font-size: 10px;
        margin-top: 15px;
        border: 1px solid #979797;
        border-radius: 100px;
        padding: 5px 12px;
        display: inline-block;
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
            color: #fff;
            background-color: #335586;
            border-color: #335586;
        }

        &:before {
            content: "+";
            margin-right: 5px;
            font-size: 1.2em;
        }
    }

    &-actionbutton {
        padding: 12px;
        text-align: center;
        font-size: 12px;
        border-radius: 100px;
        border: 1px solid #979797;
        margin-top: 30px;
        font-weight: 600;
        cursor: pointer;
    }

    &-fullbutton {
        margin-top: 30px;
    }

    &-gallery {
        display: grid;
        grid-template: "a a b c" "a a d e";
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        margin-top: 30px;
        margin-left: -40px;
        margin-right: -40px;
        height: 250px;

        &-entry {
            &:first-child {
                grid-area: a;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 3px;
                object-fit: cover;
            }
        }
    }
}


.video {

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
    max-width: 760px;
    max-height: 160px;
    transition: all .4s ease;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
        max-width: 860px;

        &:before {
            background-color: rgba(0, 0, 0, .2);
        }
    }

    &:before {
        content: "";
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .4);
        transition: all .4s ease;
    }

    &:after {
        content: "";
        position: absolute;
        z-index: 3;
        display: inline-block;
        width: 43px;
        height: 43px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAArCAYAAADhXXHAAAAAAXNSR0IArs4c6QAABGVJREFUWAnFmUtIFVEch7sh4qZ2UTexgggFqW4WbYIemxQNNSjKfBS1aBGBQUJU9lpFGFoQQYtKxSQoKKmkNi6iTeCLCpQWEpJK1kYXkT3s+93y5p3pjnPOnXs98Dkz5/wfv3tmzmPG0IIkyvT0dBbupVAA6yAMy2AA8mAMRqEfeuBxKBSa4mhVQjZeiDyI3wHYCh3wECZBwsYQNIaNRAv9gEWwB8qgC+5h08IxdQUBlfARzsJOm0z4FUI9DEOFTQxPH4JuhDfQCuqppAtxsqEN+mF90gEVgECHoAPyAwnoCELctdAJ1Y4ms0sCNMFtMy87a/I0Q4OX98JEjTiepG2cgXA4kU2Q9eTRoJ0kb61RXBzUo2eMnAIyJu858OzhWCoM9Yym5dbHkjpOyN8CVY7q+EsMNOo1b857QcczSDxL0KjpKSWj3vTXSyj0zvaLDTAaKmno40F/N9tgvs7RoSV6AF37XBqo1MpkPOHjo0enxBUwgAri5sCHmVDRnqVC08ZNfo3Wdt8Fv1UY18F2zntgm29nH4boGcbsDnH/LRhcPAfjtR6fi3BEeTlGoAu02q32ocWXCbGK4WnUmJMs+OrL02GEXyPETeJc74L3cAMyHS5Wl8T5Dhl6DLQfDWy64tY9gTXEfAsTJDnFMdkifaUSWwDajwZaEKwxoM35YgSPg2Yb2/IAxwKJ1Q5fG+eUFASfJrDeGooQbDsIJ/CPZPBH05XRLIC9UUHwFxyqERvhqOdcnVNH/aDPQNIXVs/q1UPvSikviNOis4NEt+A6opf6TBoTO0AAW7HjJNMtMirke4KDpjw9i3MW7CV2UD2bxy9U79qUJTgtNnUkXzk+d2G3H1/s9ajmSqx61Vasn1wxG5JugpdU1MBReuxzrNH7JDquNMCizwPHPm97+1YE6tlsAq1sJxD5yjDacuxH1LPa3ei9PiUFoVcIrI54hMjNFkKlS/r6JVZfSvQBItCCyOPwg6CfEBiG+0kk2ItvtzYgmfDNJhB+/9sblFM/BNdAnZF0Ic5PxcrgF09x0gWFnD83jKxpKzp14b+J80bQdLadWLF9KNfWhbjaK3cS71c0CBU1UG8aEZ8VcB+uwmvYYhpjLntiXoD4fQUV+vaUPZezsx0fvSsVOeuDuCbuShhyxaKyAtpcDfNYgR7dNQ0ud6FBH8nWulvSX4OODdCdMDONuqWdCQ3S2ICOF5DvmRIDbeWaPY1S3Eh+fQqtcKZxzYNMEa0YaWd/3mmcjmvyXiLPMDrafefDqRbO+XYIwFBC4ZhVKBwboMXK2dCJPLr1lw3d4s0JUAXeH8niXYyuiK1Rr8HkekaNAs0YE0izRC+0Q85MfTJH4qwEzaPd4D3qbRIRdB98AC2BxZYxSv76D3H8/4SfIHAoQb1nNUn07Wk/6JNTB+hdShsabeRHGcmj2IQ5F9o4az8qYWWgebwdG+PV0kosyaIFQXrT0BedAojAjMBBznNB4kdAG3ytRvoP45/dExem5TdBrsHOYSuwegAAAABJRU5ErkJggg==');
    }


    img {
        object-fit: cover;
        width: 100%;
    }

}

.panel,
.video {
    animation: slidedown .3s forwards;
    opacity: 0;
    @for $i from 1 through 8 {
        &:nth-child( #{$i} ) {
            animation-delay: $i * .15s;
        }
    }
}

.panel-buttons {
    position: absolute;
    top: 100px;
    right: 50px;
    left: 50px;
    display: flex;
    justify-content: space-between;

    .prevButton, .nextButton {
        color: white;
    }
}


@media screen and (max-width: 800px) {

    .hero-header {
        .card {
            grid-column-gap: 0;

            &-entry {
                flex-direction: column-reverse;
            }

            &-key {
                margin-top: 5px;
                color: #999;
            }

            &-value {
                font-size: 14px;
                text-align: left;
            }
        }
    }

}


@media screen and (max-width: 640px) {

    .hero-header {
        .bigkpis.card {
            grid-template: "1fr 1fr";
            grid-row-gap: 0;
            padding: 20px 0;

            .card-entry {
                // flex-direction: column-reverse;
                border-top: 1px solid #e5e5e5;

                &:nth-child(2n) {
                    align-items: flex-end;
                    text-align: right;
                    justify-content: flex-end;

                    .card-key,
                    .card-value {
                        text-align: right;
                    }
                }

                &:nth-child(1),
                &:nth-child(2) {
                    border-top: none;
                }
            }

            .card-key {
                margin-top: 5px;
                color: #999;
                font-size: 12px;
            }

            .card-value {
                font-size: 14px;
                // text-align: left;
            }
        }
    }

    .panel-gallery {
        grid-template: "a a" "a a" "b c" "d e";
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        height: auto;
    }

    .panel-content {
        padding: 35px 25px;
    }

}


@keyframes slidedown {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slide-out {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }
    to {
        transform: translate(-100%, 0);
        display: none;
        opacity: 0;
    }
}
