







































































































































































.agb-container {
    margin: 0 0 10px 30px;
}

.exporo-buy-transaction {
    .block {
        display: block;
        width: 100%;
        margin-top: 10px;

        /deep/ input[type="number"] {
            min-width: 200px;
        }
    }

    .headline {
        font-family: 'Oswald', sans-serif;
        font-size: 34px;
        color: #2E4B7B;
        line-height: 1.1;
        font-weight: 400;
        text-transform: uppercase;
    }

    label, .exporo-input-root {
        display: inline-block;
    }

    .exporo-input-root {

        margin-left: 20px;
    }

    label {
        width: 400px;
        text-align: left;
    }

    .btn, small {
        float: right;
        display: block;
        margin: 20px 0;
    }

    .btn {
        color: white;
    }

    .error-msg {
        color: red;
        transition: all 0.4s;
    }

}
