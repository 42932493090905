











































































































* {
    margin: 0;
    padding: 0;
}

.panel {
    display: flex;
    flex-direction: column;
    opacity: 0;
    animation: fade-in .3s forwards .3s;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    padding: 30px 45px;
    margin: 35px auto 0;
    max-width: 760px;
    min-height: 100px;
    transition: all .3s ease;

    body {
        padding: 35px 0 25px;
    }

    .panel-title {
        font-size: 19px;
        font-family: Oswald, sans-serif;
        color: #555555;
        max-width: 500px;
        line-height: 1.2;
        letter-spacing: .3px;
    }

    .panel-subtitle {
        white-space: pre-line;
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
        letter-spacing: .3px;
    }

    .content-container {
        margin-top: 25px;
        list-style: none;
        font-size: 14px;
        color: #787878;
        line-height: 1.5;
        letter-spacing: .3px;
    }

    .timeline {
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 1.5;
        font-feature-settings: "tnum";
        font-variant: tabular-nums;
        margin: 30px 0 20px 94px;
        list-style: none;

        .timeline-item {
            font-size: 13px;
            position: relative;
            margin: 0;
            padding: 0 0 20px;
            list-style: none;

            &-head {
                position: absolute;
                color: rgb(24, 144, 255);
                border-color: #4A7199;
                width: 12px;
                height: 12px;
                margin-top: -1px;
                background-color: rgb(255, 255, 255);
                border-width: 2px;
                border-style: solid;
                border-image: initial;
                border-radius: 100px;
            }

            &-tail {
                position: absolute;
                top: 0.75em;
                left: 5px;
                height: 100%;
                border-left: 2px solid rgb(232, 232, 232);
            }

            &-title {
                position: relative;
                top: -5px;
                margin: 0 0 0 35px;
                cursor: pointer;
                font-weight: bold;
                color: #888888;
                line-height: 20px;
                letter-spacing: 0.3px;

                svg {
                    height: auto;
                    width: 11px;
                    margin: 2px 12px 0;
                    stroke: #787878;
                    stroke-width: 38px;
                    cursor: pointer;
                    transition: transform 0.24s ease 0s;
                    text-align: center;
                    text-transform: none;
                    vertical-align: -0.125em;
                    position: relative;
                    top: 2px;
                }

                .is-expanded {
                    transform: rotate(90deg);
                }
            }

            &-date {
                font-weight: bold;
                text-align: right;
                color: #888888;
                left: -110px;
                line-height: 20px;
                cursor: pointer;
                position: absolute;
                letter-spacing: 0.3px;
                top: -5px;
                margin: 0 0 0 18px;
            }
        }

        .content {
            margin-left: 36px;
            font-size: 13px;
            color: #787878;
            line-height: 1.5;
            letter-spacing: .3px;
            max-width: 85%;
            max-height: 100%;

            &-teaser {
                margin: 20px 0 0;
                color: #787878;
                text-align: justify;
                word-break: break-word;
                hyphens: auto;
                overflow: hidden;

                ul {
                    margin-top: 20px;
                    list-style-type: square;
                    margin-left: 18px;

                    li {
                        margin-top: 10px;
                    }
                }
            }

            &-gallery {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-auto-rows: 200px;
                grid-column-gap: 8px;
                grid-row-gap: 8px;
                max-width: 100%;
                margin-top: 25px;

                img {
                    max-width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                    border-radius: 3px;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .panel {
        padding: 45px 20px;

        .timeline {

            .content {
                &-gallery {
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }
}

@media screen and (max-width: 539px) {
    .panel {

        .timeline {

            .timeline-item {

                &-title {
                    svg {
                        margin: 0 0 0 0;
                    }
                }
            }

            .content {
                &-gallery {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-auto-rows: unset;
                    grid-template-rows: 150px;
                    grid-column-gap: 0;
                    grid-row-gap: 0;
                }
            }
        }

    }
}


@media screen and (max-width: 420px) {
    .panel {
        .timeline {
            margin: 30px 0 20px 0;

            .timeline-item {
                margin-top: 10px;

                &-title {
                    top: 10px;
                }

                &-date {
                    left: 18px;
                }
            }

            .content {
                max-width: 85%;

                &-gallery {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 325px) {
    .panel {
        .timeline {
            margin: 30px 0 20px 0;

            .timeline-item {
                margin-top: 10px;

                &-title {
                    top: 10px;
                }

                &-date {
                    left: 18px;
                }
            }
        }

        .content {
            max-width: 75%;
        }
    }
}

