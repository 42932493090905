























































































































































































































































































































.sign-up-button {
  @media screen and (min-width: 990px) {
    margin-left: 10px;
  }

  @media screen and (max-width: 990px) {
    display: block;
    margin: 5px auto;
  }
}

.sentry-error-embed {
  header {
    position: relative;
    line-height: initial;

    h2 {
      line-height: initial;
      max-width: initial;
      text-align: center;
    }
  }

  #id_email, #id_name, label {
    display: none !important;
  }

  .btn {
    display: initial;
    padding: initial;
    letter-spacing: initial;
  }

  .close {
    position: relative;
    right: initial;
    width: initial;
    height: initial;
    box-shadow: none;
    line-height: initial;

    &:before {
      display: none;
    }
  }
}
