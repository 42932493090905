




































































.slider-component {
    overflow: hidden;
    margin-top: -175px;
    max-width: 1180px;
}

.vis {
    opacity: 1;
    z-index: 0;
}

.invis {
    display: none;
    opacity: 0;
    transform: translateX(-100vw);
    position: absolute;
    z-index: -99;
}

.slide-out-next {
    animation: fade-out-next 0.5s forwards;
    @media screen and (max-width: 425px) {
        height: 0;
    }
    display: none;
}

.slide-in-next {
    animation: fade-in-next 0.5s forwards;
}

.slide-out-prev {
    animation: fade-out-prev 0.5s forwards;
    @media screen and (max-width: 425px) {
        height: 0;
    }
    display: none;
}

.slide-in-prev {
    animation: fade-in-prev 0.5s forwards;
}

@keyframes fade-out-next {
    0% {
        opacity: 100%;
    }
    100% {
        opacity: 0;
        transform: translateX(-100vw);
        position: absolute;
    }
}

@keyframes fade-in-next {
    0% {
        opacity: 0;
        transform: translateX(100vw);
        position: absolute;
    }
    100% {
        opacity: 100;
    }
}

@keyframes fade-out-prev {
    0% {
        opacity: 100%;
    }
    100% {
        opacity: 0;
        transform: translateX(100vw);
        position: absolute;
    }
}

@keyframes fade-in-prev {
    0% {
        opacity: 0;
        transform: translateX(-100vw);
        position: absolute;
    }
    100% {
        opacity: 100;
    }
}
