








































































.reminder-text {
    margin-bottom: 20px;
}

/deep/ .reminder-text > a {
    background: none;
    border: none;
    border-radius: 0;
    color: darkblue !important;
    display: inline-block;
    margin-right: 0;
    margin-top: 0;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300 !important;
    letter-spacing: 1px;
    line-height: 10px;
    padding: 0;
    position: relative;
    transition: all 0.5s ease-out;

    &:hover {
        text-decoration: underline darkblue !important;
    }

    &:before {
        all: initial
    }
}
