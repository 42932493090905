@charset "UTF-8";
.equity_invest__payment-form {
  border-top: 1px solid #ededf3;
  border-bottom: 1px solid #ededf3;
  padding-top: 15px; }

.relative {
  position: relative;
  display: inline-block; }

.symbolWrapper {
  position: relative; }
  .symbolWrapper .symbol-currency {
    display: block;
    right: 0;
    position: absolute;
    top: 10%;
    width: 3em; }
    .symbolWrapper .symbol-currency:before {
      content: ',- €';
      display: inline-block;
      line-height: 20px;
      margin: 0;
      padding: 2px 2px 3px;
      position: absolute;
      right: 15px;
      top: 3px;
      text-align: center;
      z-index: 12;
      vertical-align: top; }

.equity-invest_sum {
  padding: 2px 15px;
  background-color: #ededf3;
  width: 50px;
  font-size: 20px;
  font-weight: 700;
  margin-top: -9px; }

.form-group {
  align-items: center;
  display: flex;
  margin-bottom: 15px; }
  .form-group > * {
    flex: 2; }
  .form-group > div {
    display: flex; }
    .form-group > div > * {
      flex: 1;
      margin-left: 10px; }
      .form-group > div > *.flex-2 {
        flex: 2; }
    .form-group > div > :first-child {
      margin-left: 0; }
  .form-group label {
    flex: 1;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #86909d; }
  .form-group input[type="radio"] ~ label,
  .form-group input[type="checkbox"] ~ label {
    font-family: 'Open Sans', sans-serif;
    color: #91908f; }
  .form-group /deep/ input.error {
    border: 1px solid red !important; }
  .form-group__inline {
    padding: 2em;
    display: block; }
    .form-group__inline > *,
    .form-group__inline > div {
      display: inline-block; }
      .form-group__inline > * ~ *,
      .form-group__inline > div ~ * {
        margin-left: 3em; }
  .form-group__inline-small {
    display: block;
    font-size: 14px; }
    .form-group__inline-small > *,
    .form-group__inline-small > div {
      display: inline-block; }
      .form-group__inline-small > * ~ *,
      .form-group__inline-small > div ~ * {
        margin-left: 3em; }
  .form-group.description-rules > div {
    display: block; }
    .form-group.description-rules > div > * {
      margin-left: 0; }
  .form-group.description-rules .description {
    margin-top: 15px; }

.payload-btn /deep/ input[type='checkbox'],
.payload-btn /deep/ input[type='radio'] {
  position: absolute;
  opacity: 0; }

.payload-btn /deep/ input[type='checkbox'] + label,
.payload-btn /deep/ input[type='radio'] + label {
  color: #626262; }

.payload-btn /deep/ input[type='checkbox']:checked + label,
.payload-btn /deep/ input[type='radio']:checked + label {
  color: #afafaf; }

.payload-btn /deep/ input[type='checkbox']:checked + label::before,
.payload-btn /deep/ input[type='checkbox']:not(:checked) + label::before,
.payload-btn /deep/ input[type='radio']:checked + label::before,
.payload-btn /deep/ input[type='radio']:not(:checked) + label::before {
  border: 1px solid #b8c1ce;
  background: white;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  content: ' ';
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  margin-right: 1em;
  vertical-align: text-top;
  top: 0.2em;
  left: 0.3em; }

.payload-btn /deep/ input[type='checkbox'] + label,
.payload-btn /deep/ input[type='radio'] + label {
  color: #626262;
  display: block;
  padding-left: 1.7em;
  position: relative; }
  .payload-btn /deep/ input[type='checkbox'] + label ul,
  .payload-btn /deep/ input[type='radio'] + label ul {
    margin: 0; }
    .payload-btn /deep/ input[type='checkbox'] + label ul li,
    .payload-btn /deep/ input[type='radio'] + label ul li {
      font-size: 12px;
      list-style: square;
      margin: 0;
      margin-left: 15px;
      margin-bottom: 3px;
      padding: 0; }
      .payload-btn /deep/ input[type='checkbox'] + label ul li:first-child,
      .payload-btn /deep/ input[type='radio'] + label ul li:first-child {
        margin-top: 5px; }
      .payload-btn /deep/ input[type='checkbox'] + label ul li:before,
      .payload-btn /deep/ input[type='radio'] + label ul li:before {
        content: ""; }

.payload-btn /deep/ input[type=radio]:checked + label::before,
.payload-btn /deep/ input[type=radio]:not(:checked) + label::before {
  border-radius: 30px; }

.payload-btn /deep/ input[type='checkbox']:hover + label::before,
.payload-btn /deep/ input[type='radio']:hover + label::before {
  box-shadow: inset 0 0 1px 0 #626262; }

.payload-btn /deep/ input[type='checkbox']:checked + label::before,
.payload-btn /deep/ input[type='radio']:checked + label::before {
  background: var(--color-branding-dark, #274a7f);
  box-shadow: inset 0 0 0 4px white; }

.mobile .payload-btn input {
  min-width: auto !important; }

.payload-btn /deep/ input[type="radio"][disabled],
.payload-btn /deep/ input[type="checkbox"][disabled],
.payload-btn /deep/ input[type="checkbox"]:disabled,
.payload-btn /deep/ input[type="radio"].disabled,
.payload-btn /deep/ input[type="checkbox"].disabled,
.payload-btn /deep/ fieldset[disabled] input[type="radio"],
.payload-btn /deep/ fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed; }
  .payload-btn /deep/ input[type="radio"][disabled] + label,
  .payload-btn /deep/ input[type="checkbox"][disabled] + label,
  .payload-btn /deep/ input[type="checkbox"]:disabled + label,
  .payload-btn /deep/ input[type="radio"].disabled + label,
  .payload-btn /deep/ input[type="checkbox"].disabled + label,
  .payload-btn /deep/ fieldset[disabled] input[type="radio"] + label,
  .payload-btn /deep/ fieldset[disabled] input[type="checkbox"] + label {
    opacity: 0.8; }

.payload-btn input[type="text"][disabled],
.payload-btn input[type="text"]:disabled {
  cursor: not-allowed;
  background: white;
  color: #91908f; }

.mb-20 {
  margin-bottom: 20px; }
