
































































































































































































































































































.dot {
    height: 1em;
    width: 1em;
    background-color: #274a7f;
    border-radius: 50%;
    display: inline-block;

    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.dot2 {
    margin-left: 1.5em !important;
}

.dot3 {
    margin-left: 3em !important;
}

.loader-dots {
    position: relative;
    margin-left: 1em;

    span {
        margin: 0;
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
    }

    span:nth-child(2) {
        animation-delay: 0.2s;
    }

    span:nth-child(3) {
        animation-delay: 0.4s;
    }
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

.error {
    color: tomato;
}

.actioncode-container {
    display: inline-block;
    margin-bottom: 15px;
    width: 45%
}

.file-list {
    list-style-type: square;
    padding-left: 30px;
    margin-bottom: 15px;
}

.checkbox-container {
    .exporo-checkbox-root {
        margin-bottom: 15px;
    }
}
