




































.btn-log-to-see-wrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center
}
.tab__content {
    p {
        text-align: center;
        line-height: normal;
    }
}

