







































































.top {
    margin-bottom: -2px;
}
.bottom {
    margin-top: -2px;
}
.svg-inline {
    display: inline;
}
.svg-block {
    display: block;
    margin: -10px 0;

}
