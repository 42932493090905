








































@keyframes appear {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}


tr {
    border-radius: 3px;
    cursor: pointer;
    transition: all .3s ease;

    td {
        position: relative;
        &:not(:first-child) {
            padding-left: 15px;
            padding-right: 15px;
        }
        span + p {
            margin-left: 15px;
        }
        &:last-child:after {
                display: block;
                width: 30px;
                height: 30px;
                border-radius: 40px;
                border: 1px solid rgba(0, 0, 0, .3);
                background-color: #fff;
                content: "+";
                text-align: center;
                padding: 1px 0 0 0;
                font-size: 19px;
                font-weight: 300;
                position: absolute;
                right: 9px;
                top: 13px;
                color: rgba(0, 0, 0, .5);
                transition: all .3s ease;
                transform: scale(.8);
        }

    }

    &:hover td:last-child:after {
        border: 1px solid rgba(0, 0, 0, 0);
        color: #fff;
        transform: scale(1);
        background-color: #375884;
    }


}

tr {
    height: 60px;
    text-align: left;
    transition: background-color 2s ease;
    
    &:nth-child(odd) {
        background-color: #f4f5f8;
    }

    &:hover {
        background-color: #e7eaee;
    }
}


tr.open {
    height: 400px;
    width: 72%;
    position: fixed;
    top: 50%;
    margin-top: -200px;
    transition: all 300ms ease;

    .open {
        position: absolute;
        right: 0;
    }

    img {
        width: 200px;
        height: 200px;
    }
}

td {
    p {
        margin: 0;
        line-height: 60px;
        height: 60px;
    }
}



