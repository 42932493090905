








































































































































































































































































































































































































































.reset-global-styles {
    td:first-child {
        display: table-cell;
    }
}

table {

    .progress {
        height: 20px;
    }
    .breadcrumb {
        margin: 0 0 0 5px;
        padding: 0;
        list-style: none;
        overflow: hidden;
        font: 12px Sans-Serif;
    }

    .breadcrumb li {
        float: left;
        width: 25%;
    }
    .breadcrumb li a {

        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        border: 1px solid #90a2be;
        width: 100%;
        text-align: center;
        color: white;
        text-decoration: none !important;
        padding: 1px 5px 1px 45px;
        background: #90a2be;
        position: relative;
        display: block;
        float: left;

        &.in-progress {
            background: transparent !important;
            color: #90a2be;
        }
    }
    .breadcrumb li a:after {
        content: " ";
        display: block;
        width: 0;
        height: 0;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        border-left: 30px solid #90a2be;
        position: absolute;
        top: 50%;
        margin-top: -50px;
        left: 100%;
        z-index: 2;

        &.in-progress {
            border-left: 30px solid transparent !important;
            color: #90a2be;
        }
    }
    .breadcrumb li a:before {
        content: " ";
        display: block;
        width: 0;
        height: 0;
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        border-left: 30px solid white;
        position: absolute;
        top: 50%;
        margin-top: -50px;
        margin-left: 1px;
        left: 100%;
        z-index: 1;
    }

    .breadcrumb li:first-child a {
        padding-left: 10px;
    }

    .breadcrumb li:nth-child(2) a {
        background: darken(#90a2be, 5);

        &.in-progress {
            background: transparent !important;
            color: #90a2be;
        }
    }

    .breadcrumb li:nth-child(2) a:after {
        border-left-color: darken(#90a2be, 5);

        &.in-progress {
            background: transparent !important;
            color: #90a2be;
        }
    }

    .breadcrumb li:nth-child(3) a {
        background: darken(#90a2be, 10);

        &.in-progress {
            background: transparent !important;
            color: #90a2be;
        }
    }

    .breadcrumb li:nth-child(3) a:after {
        border-left-color: darken(#90a2be, 10);

        &.in-progress {
            background: transparent !important;
            color: #90a2be;
        }
    }

    .breadcrumb li:nth-child(4) a {
        background: darken(#90a2be, 15);

        &.in-progress {
            background: transparent !important;
            color: #90a2be;
        }
    }

    .breadcrumb li:nth-child(4) a:after {
        border-left-color: darken(#90a2be, 15);

        &.in-progress {
            background: transparent !important;
            color: #90a2be;
        }
    }

    .breadcrumb li:nth-child(5) a {
        background: darken(#90a2be, 20);

        &.in-progress {
            background: transparent !important;
            color: #90a2be;
        }
    }

    .breadcrumb li:nth-child(5) a:after {
        border-left-color: darken(#90a2be, 20);

        &.in-progress {
            background: transparent !important;
            color: #90a2be;
        }
    }

    .breadcrumb li:last-child a {
        background: darken(#90a2be, 25) !important;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        &.in-progress {
            background: transparent !important;
            color: #90a2be;
        }

        color: white;
        pointer-events: none;
        cursor: default;
    }

    .breadcrumb li:last-child a:after {
        border: 0;
    }

    .breadcrumb li a:hover {
        background: #90a2be;
    }

    .breadcrumb li a:hover:after {
        border-left-color: #90a2be !important;
    }

}

.btn-in-table {
    transform: scale(0.7);
    font-size: 14px;
    font-weight: bold;
}

.buy-bubble {
    background-color: #335586;
    border-radius: 15px 0 0 0;
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
    padding: 6px 8px;
    font-size: 12px;
    font-weight: bold;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.table-row-container {
    &:last-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.exporo-invest-list-root {
    width: 100%;
    padding-bottom: 80px;
}

@keyframes appear {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.list-mobile {
    align-items: center;
    margin-top: 10px;

    &-entry {
        border-radius: 5px;
        width: 100%;
        max-width: 720px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        animation: appear 300ms forwards;
        opacity: 0;
        transition: all 150ms ease;
        overflow: hidden;
        margin: auto auto;

        & + & {
            margin-top: 20px;
        }

        @for $i from 1 through 20 {
            &:nth-child(#{$i}) {
                animation-delay: 100ms * $i;
                transition-delay: 100ms * $i;
            }
        }
    }

    &-preview {
        position: relative;
        min-width: 180px;
        height: 120px;

        img {
            display: block;
            // overflow: hidden;
            border-radius: 0 0 0 5px;
            width: 200px;
            height: 130px;
            object-fit: cover;
        }
    }

    &-title {
        position: absolute;
        background-color: rgba(255, 255, 255, .85);
        box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
        border-radius: 50px;
        font-size: 11px;
        font-weight: bold;
        color: #335586;
        top: 7px;
        left: 7px;
        right: 7px;
        padding: 5px 5px;
        text-align: center;
        text-transform: uppercase;
    }

    &-details {
        display: inline-block;
        float: left;
        width: 100%;
        padding: 10px 15px 10px 20px;
        background-color: #fff;
        border-radius: 0 5px 5px 0;
        position: relative;
    }

    &-detail {
        display: inline-block;
        max-width: 100px;
        margin: 0;
    }

    &-value {
        font-size: 16px;
        font-weight: bold;
    }

    &-key {
        font-size: 11px;
        color: #aaa;
        margin-top: 2px;
    }

    &-expand {
        background-color: #335586;
        color: #fff;
        font-size: 20px;
        position: absolute;
        right: -8px;
        bottom: 45px;
        line-height: 1;
        width: 30px;
        height: 30px;
        display: block;
        border-radius: 50px;
        padding: 5px 9px;

        &:before {
            content: "+";
        }
    }
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sort-button {
    position: absolute;
    right: 4px;
    top: 16px;

    &-wrapper {
        width: 20px;
    }
}

table {
    width: 100%;
    border-collapse: unset;
    border-spacing: 0 0;

    &-column-container {
        min-width: 300px;
    }

    .id-row {
        padding-left: 10px;
    }

    thead {
        th {
            user-select: none;
            word-break: break-word;
            hyphens: auto;
            min-width: 100px;
            position: relative;
            text-align: left;

            &:first-child {
                border-radius: 5px 0 0 5px;
            }

            &:last-child {
                border-radius: 0 5px 5px 0;
            }

        }

        tr {
            height: 50px;
        }
    }

    tbody {
        user-select: none;

        tr {
            border-radius: 3px;
            cursor: pointer;
            transition: all .3s ease;

            td {
                position: relative;
                padding-left: 10px;
                padding-right: 10px;

                span + p {
                    margin-left: 15px;
                }
            }

            &:hover td:last-child:after {
                border: 1px solid rgba(0, 0, 0, 0);
                color: #fff;
                transform: scale(1);
                background-color: #375884;
            }


        }
    }

    tr {
        height: 60px;
        text-align: left;
        transition: background-color 2s ease;

        &:nth-child(odd) {
            background-color: #f4f5f8;
        }

        &:hover {
            background-color: #e7eaee;
        }
    }


    tr.open {
        height: 400px;
        width: 72%;
        position: fixed;
        top: 50%;
        margin-top: -200px;
        transition: all 300ms ease;

        .open {
            position: absolute;
            right: 0;
        }

        img {
            width: 200px;
            height: 200px;
        }
    }

    p {
        display: block;
        float: left;
    }

    div.open {
        width: 40px;
        height: 40px;
        background: #335586;
        color: white;
        font-size: 30px;
        float: right;
        box-shadow: 0 1px 10px 2px #00000059;
        line-height: 36px;
        text-align: center;
        margin-right: 10px;
        margin-top: 10px;
        border-radius: 20px;
    }

    th {
        padding-left: 15px;
        padding-right: 15px;
        height: 40px;
        color: white;
        background: #51709c;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: .6px;

        &:last-child {
            min-width: 130px;
        }

        &:nth-child(even) {
            background-color: #90a2be;
        }
    }
}

.info-card {
    position: relative;
    padding: 20px 20px 25px;
    flex-grow: 1;
    max-width: 450px;
    transition: box-shadow .3s ease;
    margin: 40px auto;
    text-align: center;

    &-button {
        margin-top: 20px
    }
}

.mobile-image {
    display: none;
}


@media screen and (max-width: 1200px) {

    .mobile-image {
        display: none;
    }

    table {
        th {
            padding-left: 7px;
            padding-right: 7px;

            &:first-child {
                min-width: auto;
                width: auto;
            }
        }

        td {
            padding-left: 7px;
            padding-right: 7px;
        }

    }

}

@media screen and (max-width: 992px) {

    .mobile-image {
        display: none;
    }

    .list-mobile {
        display: inline;
    }

    table {
        display: none;
    }

}

@media screen and (max-width: 720px) {

    .mobile-image {
        display: none;
    }

    .tablet-image {
        display: none;
    }


    .list-mobile {
        &-entry {
            max-width: 480px;
        }

        &-preview {
            height: 25px;

        }

        &-details {

            .page-portfolio &,
            .page-financing & {
                grid-template-columns: 1fr 1fr;
            }
        }

        &-value {
            font-size: 14px;
        }

        &-expand {
            right: -5px;
            bottom: -7px;
        }
    }


}


@media screen and (max-width: 720px) {

    .mobile-image {
        display: block !important;
    }

    .tablet-image {
        display: none !important;
    }

    .list-mobile {

        .list-mobile-details {

            &.payment {
                padding: 10px 20px 10px 10px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
            }

            .list-mobile-value {
                font-size: 12px;
            }

            .list-mobile-key {
                font-size: 10px;
            }

            img {
                position: absolute;
                object-fit: cover;
                width: 114px;
                height: 100%;
            }
        }

        &-title {
            position: relative;
            box-shadow: none;
            border-radius: 5px 5px 0 0;
            background-color: #335586;
            color: #fff;
            top: auto;
            left: auto;
            right: auto;
        }
    }
}

@media screen and (max-width: 720px) {

    .list-mobile-title {
        width: 100%;
        z-index: 999;
    }

}

@media screen and (min-width: 721px) {

    .list-mobile-title {
        width: 21%;
        z-index: 999;
    }

}

.hidden-sm {
    display: none;
}

$width: 100%;
$gutter: 0%;
$breakpoint-small: 33.75em; // 540px
$breakpoint-med: 45em; // 720px
$breakpoint-large: 60em; // 960px

.container {
    width: 100%;
    padding: 0;
}

.row {
    position: relative;
    width: 100%;
    margin: 0;

    &.inline {
        display: inline-block;
        float: left;
        width: 50%;
    }

    &.inline.small {
        display: inline-block;
        float: left;
        width: 30%;
    }
}

.row [class^="col"] {
    float: left;
    min-height: 0.125rem;
}

.row::after {
    content: "";
    display: table;
    clear: both;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
    width: $width;
}

.col-1-sm {
    width: ($width / 12) - ($gutter * 11 / 12);
}
.col-2-sm {
    width: ($width / 6) - ($gutter * 10 / 12);
}
.col-3-sm {
    width: ($width / 4) - ($gutter * 9 / 12);
}
.col-4-sm {
    width: ($width / 3) - ($gutter * 8 / 12);
}
.col-5-sm {
    width: ($width / (12 / 5)) - ($gutter * 7 / 12);
}
.col-6-sm {
    width: ($width / 2) - ($gutter * 6 / 12);
}
.col-7-sm {
    width: ($width / (12 / 7)) - ($gutter * 5 / 12);
}
.col-8-sm {
    width: ($width / (12 / 8)) - ($gutter * 4 / 12);
}
.col-9-sm {
    width: ($width / (12 / 9)) - ($gutter * 3 / 12);
}
.col-10-sm {
    width: ($width / (12 / 10)) - ($gutter * 2 / 12);
}
.col-11-sm {
    width: ($width / (12 / 11)) - ($gutter * 1 / 12);
}
.col-12-sm {
    width: $width;
}

@media only screen and (min-width: $breakpoint-med) {
    .col-1 {
        width: ($width / 12) - ($gutter * 11 / 12);
    }
    .col-2 {
        width: ($width / 6) - ($gutter * 10 / 12);
    }
    .col-3 {
        width: ($width / 4) - ($gutter * 9 / 12);
    }
    .col-4 {
        width: ($width / 3) - ($gutter * 8 / 12);
    }
    .col-5 {
        width: ($width / (12 / 5)) - ($gutter * 7 / 12);
    }
    .col-6 {
        width: ($width / 2) - ($gutter * 6 / 12);
    }
    .col-7 {
        width: ($width / (12 / 7)) - ($gutter * 5 / 12);
    }
    .col-8 {
        width: ($width / (12 / 8)) - ($gutter * 4 / 12);
    }
    .col-9 {
        width: ($width / (12 / 9)) - ($gutter * 3 / 12);
    }
    .col-10 {
        width: ($width / (12 / 10)) - ($gutter * 2 / 12);
    }
    .col-11 {
        width: ($width / (12 / 11)) - ($gutter * 1 / 12);
    }
    .col-12 {
        width: $width;
    }

    .hidden-sm {
        display: block;
    }
}

