<template>
  <div class="exporo-experimental-features">
    <exporo-modal name="experimental-features" width="600px" :onClose="onClose">
      <h1>{{ trans.get('query_settings.headline') }}</h1><br>

      <div class="toggles" v-if="savedSettings">
        <Toggle name="speec"
                :onToggle="onToggle"
                :value="savedSettings.speec">
          speech control (chrome only)
        </Toggle>
        <p>Press "r" on your keyboard to record command</p>
        <small>
          Possible commands are:
          <br>
          - Öffne das Cockpit
          <br>
          - Schließe das Cockpit
        </small>

        <Toggle name="spinner"
                :onToggle="onToggle"
                :value="savedSettings.spinner">
          {{ trans.get('query_settings.toggle') }}
        </Toggle>
        <Toggle name="stoTrade"
                :onToggle="onToggle"
                :value="savedSettings.stoTrade">
          STO-Tradeable wenn investiert in EXPORO TEST BOND:
        </Toggle>
      </div>
    </exporo-modal>

    <template v-if="showPPModal">
      <div class="modal-content"
           v-bind:class="{first: ppVariant == '1', second: ppVariant == '2', alternate: ppVariant == '3'}">
        <span v-if="this.wasUserAlreadyThere && this.matchingUser && this.shouldFixLeads" class="closePP"
              @click="gotToExclusive">&times;</span>
        <span v-else class="closePP" @click="() => showPPModal = false">&times;</span>
        <div class="header">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Ebene_1"
               data-name="Ebene 1" viewBox="0 0 70.87 46.71">
            <defs>
              <clipPath id="clip-path" transform="translate(0 0)">
                <rect class="cls-1" width="70.87" height="46.71"/>
              </clipPath>
            </defs>
            <polygon class="cls-2" points="46.7 11.58 43.85 13.68 39.46 12.26 39.46 9.23 46.7 11.58"/>
            <polygon class="cls-2" points="29.02 8.87 31.88 6.77 36.61 8.31 36.61 11.33 31.88 9.79 29.02 8.87"/>
            <polygon class="cls-2" points="18.41 18.13 21.27 16.71 21.27 24.46 18.41 26.55 18.41 18.13"/>
            <polygon class="cls-2" points="32.89 31.26 25.31 24.85 28.16 22.75 32.89 26.75 32.89 31.26"/>
            <polygon class="cls-2" points="43.85 13.68 46.7 11.58 46.7 24.83 43.85 26.93 43.85 13.68"/>
            <polygon class="cls-2"
                     points="32.89 22.83 31.01 20.67 29.04 18.39 28.16 17.39 25.67 14.52 28.52 12.43 29.03 13.01 31.89 16.3 35.75 20.73 32.89 22.83"/>
            <polygon class="cls-2"
                     points="25.31 14.7 21.27 16.71 18.41 18.13 21.27 16.03 25.31 14.02 28.16 12.61 28.52 12.43 25.67 14.52 25.31 14.7"/>
            <polygon class="cls-2" points="31.88 9.79 31.89 16.3 29.03 13.01 29.02 8.87 31.88 9.79"/>
            <polygon class="cls-2" points="28.16 12.61 25.31 14.02 25.31 2.1 28.16 3.02 28.16 12.61"/>
            <polygon class="cls-2" points="28.16 22.75 25.31 24.85 25.31 14.7 25.67 14.52 28.16 17.39 28.16 22.75"/>
            <polygon class="cls-2"
                     points="35.75 27.79 35.75 29.16 32.89 31.26 32.89 26.75 32.89 25.38 32.89 22.83 35.75 20.73 35.75 23.28 35.75 27.79"/>
            <polygon class="cls-2" points="29.04 22.12 31.01 20.67 32.89 22.83 32.89 25.38 29.04 22.12"/>
            <polygon class="cls-2"
                     points="39.46 12.26 39.46 26.42 36.61 28.52 36.61 24.01 36.61 11.33 36.61 8.31 36.61 5.77 39.46 3.67 39.46 9.23 39.46 12.26"/>
            <polygon class="cls-2" points="28.16 0 25.31 2.1 28.16 3.02 36.61 5.77 39.46 3.67 28.16 0"/>
            <polygon class="cls-2" points="31.01 20.67 29.04 22.12 29.04 18.39 31.01 20.67"/>
            <polygon class="cls-2"
                     points="0 38.7 0 46.51 4.42 46.51 4.42 45.74 0.78 45.74 0.78 42.68 4.42 42.68 4.42 41.91 0.78 41.91 0.78 39.47 4.45 39.47 4.45 38.7 0 38.7"/>
            <polygon class="cls-2"
                     points="15.68 38.7 13.81 41.74 11.95 38.7 11.04 38.7 13.35 42.48 10.87 46.51 11.78 46.51 13.8 43.21 15.82 46.51 16.73 46.51 14.26 42.47 16.57 38.7 15.68 38.7"/>
            <g class="cls-3">
              <path class="cls-2"
                    d="M24.46,39.47v2.64l1.32,0A3.58,3.58,0,0,0,26.94,42a1.19,1.19,0,0,0,.58-.48,1.23,1.23,0,0,0,.22-.73A1.32,1.32,0,0,0,27,39.6a3.45,3.45,0,0,0-1.14-.13Zm-.77-.77h1.54a8.67,8.67,0,0,1,1.8.12,2,2,0,0,1,1.09.68,2,2,0,0,1,.42,1.28,2,2,0,0,1-.41,1.29,2.07,2.07,0,0,1-1.14.68,9.5,9.5,0,0,1-2,.13h-.56v3.63h-.77Z"
                    transform="translate(0 0)"/>
              <path class="cls-2"
                    d="M39.48,39.26a3.1,3.1,0,0,0-1.63.46,3.2,3.2,0,0,0-1.21,1.21,3.39,3.39,0,0,0-.43,1.71,3.19,3.19,0,0,0,1,2.35,3.15,3.15,0,0,0,2.31,1,3.33,3.33,0,0,0,1.67-.44,3.2,3.2,0,0,0,1.21-1.21,3.49,3.49,0,0,0,.43-1.7,3.39,3.39,0,0,0-.43-1.68,3.27,3.27,0,0,0-1.22-1.2,3.18,3.18,0,0,0-1.66-.46m0-.76a4,4,0,0,1,2.95,1.19,4.15,4.15,0,0,1,0,5.82,3.9,3.9,0,0,1-2.89,1.2,4,4,0,0,1-2.92-1.19,4,4,0,0,1-1.19-2.88A4.11,4.11,0,0,1,36,40.55a3.92,3.92,0,0,1,1.47-1.5,4,4,0,0,1,2-.55"
                    transform="translate(0 0)"/>
              <path class="cls-2"
                    d="M51.62,39.47v2.65H53A3.28,3.28,0,0,0,54.11,42a1.23,1.23,0,0,0,.58-.47,1.36,1.36,0,0,0,0-1.45,1.26,1.26,0,0,0-.56-.46A3.42,3.42,0,0,0,53,39.47Zm-.77-.77h1.54a9.36,9.36,0,0,1,1.76.11,1.94,1.94,0,0,1,1.55,2,2.17,2.17,0,0,1-.29,1.12,1.87,1.87,0,0,1-.86.73,3.75,3.75,0,0,1-1.53.25l2.79,3.63h-1l-2.79-3.63h-.44v3.63h-.77Z"
                    transform="translate(0 0)"/>
              <path class="cls-2"
                    d="M66.76,39.26a3.13,3.13,0,0,0-1.64.46,3.24,3.24,0,0,0-1.2,1.21,3.4,3.4,0,0,0-.44,1.71,3.19,3.19,0,0,0,1,2.35,3.16,3.16,0,0,0,2.32,1,3.33,3.33,0,0,0,1.67-.44,3.24,3.24,0,0,0,1.2-1.21,3.38,3.38,0,0,0,.43-1.7,3.28,3.28,0,0,0-.43-1.68,3.24,3.24,0,0,0-1.21-1.2,3.2,3.2,0,0,0-1.66-.46m0-.76a4,4,0,0,1,3,1.19,4.15,4.15,0,0,1,0,5.82,3.92,3.92,0,0,1-2.89,1.2,4.06,4.06,0,0,1-4.11-4.07,4.2,4.2,0,0,1,.54-2.09,4,4,0,0,1,3.5-2"
                    transform="translate(0 0)"/>
            </g>
          </svg>
        </div>
        <div class="content-pp">
          <img src="https://webapp.exporo.de/popup.jpg" alt="">
          <div class="text">
            <h6>Wohnen im Narzissenweg</h6>
            <span>Ab 25.000€ in dieses Projekt <br> investieren - unverbindliche Informationen</span>
          </div>

          <a v-if="matchingUser" target="_blank" :href="'https://exclusive.exporo.de?user_id=' + matchingUser"><span>Jetzt ab 25.000€ beteiligen</span></a>
          <a v-else target="_blank" href="https://exclusive.exporo.de?user_id=-1"><span>Jetzt ab 25.000€ beteiligen</span></a>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import URLHelper from '../Helper/URLHelper';
import CookieHelper from '../Helper/CookieHelper';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import ExporoVue from '@/components/ExporoVue';
import ExporoModal from '@/components/Modal/ExporoModal.vue';
import Toggle from '@/components/Toggle/Toggle.vue';
import Controller from '@/components/Router/Controller';
import ExporoTokenService from '@/components/JWT/services/ExporoTokenService';
import BulletPoint from '@/components/QuerySettings/BulletPoint.vue';
import Events from '../../events';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/types';

const axios = require('axios');

@Component({
  components: {
    ExporoModal,
    Toggle,
    BulletPoint
  }
})
class QuerySettings extends ExporoVue {

  private savedSettings: any = null;
  private showPPModal: boolean = false;
  private ppVariant: any = '1';
  private matchingUser: any = null;
  private wasUserAlreadyThere: boolean = false;
  private shouldFixLeads: boolean = true;
  private checked: boolean = false;

  constructor() {
    super();
  }

  gotToExclusive() {
    window.location.href = `https://exclusive.exporo.de?user_id=${this.matchingUser}`;
  }

  onClose() {
    window.location.href = window.location.href.split('?')[0];
  }

  onToggle(isChecked: boolean, name: string) {

    if ('speec' === name) {

      if (isChecked) {
        navigator.permissions.query({name: 'microphone'}).then((permissionStatus: any) => {

          const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
          const speechRecognition = new SpeechRecognition();

          if ('granted' !== permissionStatus.state) {
            this.savedSettings.speec = false;
            speechRecognition.start();
          }

          permissionStatus.onchange = (event: any) => {
            this.savedSettings.speec = 'granted' === event.target.state;

            LocalStorage.addBoolean(ExporoVue.ACTIVATE_SPEEC_KEY, this.savedSettings.speec);
            speechRecognition.stop();
          };
        });
      } else {
        LocalStorage.addBoolean(ExporoVue.ACTIVATE_SPEEC_KEY, isChecked);
      }
    }

    if ('spinner' === name) {
      LocalStorage.addBoolean(ExporoVue.ACTIVATE_SPINNER_KEY, isChecked);
    }
  }


  checkPPModal(userId: any) {
    if (this.checked) return;

    this.matchingUser = userId;
    let ppModalAlreadyShown: number = LocalStorage.get(ExporoVue.PP_MODAL_ALREADY_SHOWN, 0);
    this.wasUserAlreadyThere = ppModalAlreadyShown !== 0;
    this.shouldFixLeads = LocalStorage.getBoolean('shouldFixLead', true);

    if (this.wasUserAlreadyThere && this.shouldFixLeads) {
      ppModalAlreadyShown = 0;
      LocalStorage.addBoolean('shouldFixLead', false);
    }

    const idAsNumber = Number(userId);
    const isGerman: boolean = 'de' === this.cctld.toLowerCase();

    if (isGerman && idAsNumber && ppModalAlreadyShown < 1 && window.location.href.indexOf('#/cockpit') > -1) {

      axios.get('https://kpis.dwh.exporo.de/private_placement/pp_investors.json').then((response: any) => {
        const ppInvestors: number[] = response.data.private_placement_investor_ids;
        // this.showPPModal = ppInvestors.indexOf(idAsNumber) === -1;

        if (this.showPPModal) {
          LocalStorage.remove(ExporoVue.PP_MODAL_ALREADY_SHOWN);
          this.$nextTick(() => {
            ppModalAlreadyShown++;
            LocalStorage.add(ExporoVue.PP_MODAL_ALREADY_SHOWN, ppModalAlreadyShown);
          });
        }
      });
    }

    this.checked = true;
  }

  mounted() {
    const userId = LocalStorage.get(ExporoVue.USER_ID_KEY, null);

    if (userId) {
      this.checkPPModal(userId);
    } else {
      Events.$on('userIdLoaded', () => {
        const userId = LocalStorage.get(ExporoVue.USER_ID_KEY, null);
        this.checkPPModal(userId);
      });
    }

    Events.$on('openCockpit', () => {
      const userId = LocalStorage.get(ExporoVue.USER_ID_KEY, null);
      this.checkPPModal(userId);
    });

    const ppModal = URLHelper.getQueryParam('ppModal');

    if (ppModal) {
      this.ppVariant = ppModal;
      this.showPPModal = true;
    }

    const jwtRedirect = URLHelper.getQueryParam('jwtRedirect');

    if (jwtRedirect) {
      const tokenService = new ExporoTokenService();
      tokenService.fetchToken((token: string) => {
        if (token) {
          window.location.href = `${jwtRedirect}?jwtToken=${token}`;
        }
      });
    }

    const aaid = URLHelper.getQueryParam('a_aid');
    if (aaid && null === CookieHelper.getCookie('affiliated')) {
      CookieHelper.setCookie('affiliated', aaid);
    }

    const a_aid_ref = URLHelper.getQueryParam('a_aid_ref');
    if (a_aid_ref && null === CookieHelper.getCookie('a_aid_ref')) {
      CookieHelper.setCookie('a_aid_ref', a_aid_ref);
    }

    const isPortfolioFeature = URLHelper.hasQueryParam('portfolio-v2');
    if (isPortfolioFeature && null === CookieHelper.getCookie('portfolio-v2')) {
      CookieHelper.setCookie('portfolio-v2', 'true');
    }

    const cockpitSettings = URLHelper.getQueryParam('cockpit');

    if (cockpitSettings && 'v2' === cockpitSettings) {
      CookieHelper.setCookie('exporo-cockpit-version', cockpitSettings);
    } else if (cockpitSettings && 'v2' !== cockpitSettings) {
      CookieHelper.setCookie('exporo-cockpit-version', 'v1');
    }

    const cockpitUserId = URLHelper.getQueryParam('userid');

    if (cockpitUserId) {
      LocalStorage.add(ExporoVue.USER_ID_KEY, cockpitUserId);
    }

    const debug = URLHelper.getQueryParam('debug');

    if (debug) {
      LocalStorage.addBoolean(ExporoVue.ACTIVATE_LOGS_KEY, '1' === debug);
    }

    const hasExperimentalFeatures = URLHelper.hasQueryParam('experimental-features');

    if (hasExperimentalFeatures) {

      const useSpinner = LocalStorage.getBoolean(ExporoVue.ACTIVATE_SPINNER_KEY, false);
      const useSpeec = LocalStorage.getBoolean(ExporoVue.ACTIVATE_SPEEC_KEY, false);

      this.savedSettings = {
        speec: useSpeec,
        spinner: useSpinner
      };

      this.getModalService().openModal('experimental-features', {
        height: 'auto',
        width: '600px',
        top: '10%'
      });
    }

  }
}

export default QuerySettings;
</script>

<style>
.cls-1 {
  fill: none;
}

.cls-2 {
  fill: #232524;
}

.cls-3 {
  clip-path: url(#clip-path);
}
</style>

<style lang="scss" scoped>
.header {
  height: 50px;
  background: white;

  svg {
    display: block;
    height: 100%;
    margin: auto auto;
    padding: 5px;
  }
}

.alternate {
  height: 160px !important;
  background-color: #9e9e9dd9 !important;

  .header {
    display: none;
  }

  .closePP {
    z-index: 99999;
    color: #ffffff;
    right: 0;
    top: 0;
  }
}

.second {
  background-color: #9e9e9dd9 !important;
}

.modal-content {
  z-index: 9999999999;
  position: absolute;
  background-color: #222222;
  margin: auto;
  border: 1px solid #888;
  height: 200px;
  width: 450px;
  top: 20px;
  right: 20px;

  .content-pp {
    padding: 20px;
    position: relative;

    img {
      width: 110px;
      border-radius: 4px;
      float: left;
    }

    .text {
      float: right;
      width: 275px;

      h6 {
        font-size: 20px;
        line-height: 18px;
        font-family: Oswald, sans-serif;
      }

      span {
        margin-top: 10px;
        font-size: 14px;
        width: 300px;
        line-height: 14px;
        font-family: "Open Sans", sans-serif;
        font-weight: normal;
      }

      h6, span {
        color: #ffffff;
        text-align: left;
        text-transform: initial;
      }
    }

    span, p {
      color: white;
    }

    a {
      display: block;
      border-style: none;
      border-radius: 100px;
      left: 150px;
      top: 90px;
      z-index: 5;
      width: 170px;
      height: 40px;
      position: absolute;
      box-shadow: none;
      text-shadow: none;
      color: #ffffff;
      font-size: 18px !important;
      line-height: 24px;
      font-weight: 300;
      font-family: Oswald, sans-serif;
      font-style: normal;
      text-align: center;
      background: rgba(210, 153, 56, 1) no-repeat;

      span {
        font-size: 14px;
        line-height: 40px;
      }

      &:hover {
        background: rgba(198, 157, 91, 1);
      }
    }

    p {
      height: 36px;
      padding-left: 40px;
      position: relative;
      margin: auto auto;
      line-height: 30px;
      width: 580px;
      color: rgb(255, 255, 255);
      font-weight: bold;
      font-size: 18px;
      font-style: normal;
      font-family: "Open Sans", sans-serif;

      svg {
        left: 0;
        position: absolute;
      }
    }

    > .bak_span:first-child {
      width: 552px;
    }

    span {
      display: block;
      font-family: Oswald, sans-serif;
      color: rgb(255, 255, 255);
      font-weight: bold;
      font-style: normal;
      font-size: 18px;
      line-height: 30px;
      margin: auto auto;
      text-align: center;
    }
  }
}


@media (max-width: 450px) {
  .modal-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999;
    width: 100%;
    margin-top: 200px;

    .content-pp {
      padding: 10px;

      a {
        left: 50%;
        margin-left: -150px;
        z-index: 5;
        width: 300px;
        position: absolute;
        bottom: 0;
      }

      > span:first-child {
        width: 100%;
        font-size: 24px;
      }

      p {
        font-size: 14px;
        line-height: 22px;
        height: 55px;
        width: 100%;
      }
    }
  }
}

.closePP {
  width: 30px;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  text-decoration: none;
}

.closePP:hover,
.closePP:focus {
  color: #000;
  cursor: pointer;
}

.btn-container {
  text-align: right;

  .btn {
    display: inline-block;
    background: var(--color-branding-dark, #274a7f);
    position: relative;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 10px;
    padding: 16px 30px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .5s ease-out;
  }
}
</style>
