

























































































.exporo-progress-root {
    opacity: 0;
    transition: opacity .1s ease-in;
    max-width: 400px;
    margin-top: 5px;
    padding: 10px;
    font-family: sans-serif;

    &.show {
        opacity: 1;
    }
    .info {
        font-size: 11px;
        line-height: 22px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        span {
            font-size: 16px;
        }
        p {
            margin: 0;
        }
    }
    .meter {
        overflow: hidden;
        border-radius: 5px;
        border-radius: var(--element-radius, 5px);
        margin-top: 5px;
        background-color: #e0e0e0;
        span {
            display: block;
            background-image: linear-gradient(-45deg, transparent, transparent 40%, rgba(255, 255, 255, 0.25) 48%, rgba(255, 255, 255, 0.25) 52%, transparent 60%);
            background-size: 500px 14px;
            animation: 20s infinite linear progressbartest;
            height: 10px;
            background-color: var(--color-branding, #86AC48);
            height: calc(5px * var(--element-spacing, 2));
        }
    }
    @keyframes progressbartest {
        from {
            background-position: 0 0
        }
        to {
            background-position: 2000px 0
        }
    }
}
