





























































.panel {
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    background-color: #fff;
    margin-top: 35px;

    &-content {
        transition: all .8s ease;
        overflow: hidden;
        padding: 45px 60px;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: .3px;

        &-footer {
            padding-top: 10px;
        }
        &-closed {
            padding-top: 0;
            padding-bottom: 0;
        }
    }


    &-headline {
        font-family: "Oswald", sans-serif;
        color: #555;
        line-height: 1.2;
        letter-spacing: .3px;
        text-transform: none;
        padding: 25px 17px 20px 60px;
        font-size: 16px;
        position: relative;
        cursor: pointer;

        &:hover .panel-headline-button:before {
            border: 1px solid rgba(0, 0, 0, 0);
            color: #fff;
            transform: scale(1);
            background-color: #375884;
        }
    
        &-button {
            &:before {
                font-family: sans-serif;
                display: block;
                width: 30px;
                height: 30px;
                border-radius: 40px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                background-color: #fff;
                content: "-";
                text-align: center;
                padding: 1px 0 0 0;
                font-size: 19px;
                font-weight: 300;
                position: absolute;
                right: 15px;
                top: 17px;
                color: rgba(0, 0, 0, 0.5);
                transition: all .3s ease;
                transform: scale(0.8);
            }

        }

        &-closed {
            .panel-headline-button:before {
                content: "+";
            }
        }
    }


    &-footer {
        border-top: 1px solid #EBEBEB;
        font-size: 16px;
        color: #555;
        padding: 12px 17px 10px 60px;
        font-family: "Oswald", sans-serif;
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        margin-top: -20px;

        &:hover .panel-footer-button:before {
            border: 1px solid rgba(0, 0, 0, 0);
            color: #fff;
            transform: scale(1);
            background-color: #375884;
        }

        &-more {
            &:before {
                content: attr(data-close);
            }
        }
        &-button {
            &:before {
                font-family: sans-serif;
                display: block;
                width: 30px;
                height: 30px;
                border-radius: 40px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                background-color: #fff;
                content: "-";
                text-align: center;
                padding: 1px 0 0 0;
                font-size: 19px;
                font-weight: 300;
                color: rgba(0, 0, 0, 0.5);
                transition: all .3s ease;
                transform: scale(0.8);
            }
        }


        &-closed {
            .panel-footer-more:before {
                content: attr(data-text)
            }
            .panel-footer-button:before {
                content: '+';
            }
        }

    }
}

@media screen and (max-width: 800px) {
    .panel {

        &-content {
            padding-left: 20px;
            padding-right: 20px;

            .is-expanded & {
                padding-left: 20px;
                padding-right: 20px;
            }

        }
    }
}

