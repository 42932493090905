
















































































































































































.exporo-select-root {
    padding-left: 0 !important;
    // transform: translateZ(0);

    input {
        display: none;
    }

    .exporo-select {
        *, :after, :before {
            box-sizing: border-box;
        }

        background: white;
        box-shadow: none;
        font-size: 14px;
        line-height: 12px;
        margin-right: 0;
        outline: 0;
        border: 1px solid #b8c1ce;
        border-radius: 3px;
        color: #5f5f5f;
        padding: 5px;
        display: block;
        min-width: 130px;
        width: 100%;
        @media screen and (max-width: 900px) {
            min-width: auto;
        }

        label {
            width: 100%;
            margin-bottom: 0;
        }

        span {
            background: #fff;
            border-color: rgba(0, 0, 0, 0.12);
            border-width: 0 0 1px 0;
            color: #91908f;
            display: block;
            position: relative;
            text-align: left;
            text-shadow: none;
            width: 100%;
            z-index: 1;
            outline: none;
            overflow: hidden;
            padding: 10px 0 10px 10px;
            cursor: pointer;
            border-radius: 3px;


            &:focus, &:hover {
                background: rgb(242, 244, 247);
            }
            &:after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                border-top: 2px solid var(--color-branding-alt, #274a7f);
                border-right: 2px solid var(--color-branding-alt, #274a7f);
                transform: rotate(135deg);
                float: right;
                margin-right: 10px;
            }
        }
        ul[role=listbox] {
            border-radius: 3px;
            background-color: white;
            list-style: none;
            line-height: 26px;
            overflow: hidden;
            cursor: pointer;
            margin: 0;
            max-height: 0;
            position: absolute;
            padding: 0;
            transform: translateY(-50%);
            transition: all 0.15s cubic-bezier(0.35, 0, 0.25, 1);
            min-width: 130px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;

            li {
                height: 48px;
                margin: 0;
                padding: 10px 16px;
                outline: none;
                overflow: hidden;

                &:focus, &:hover, &.active {
                    background: rgb(242, 244, 247);
                }
            }
        }

        &.active ul {
            max-height: 200px;
            overflow: auto;
            padding: 8px 0 16px 0px;
            z-index: 2;
            transition: all .2s ease;
        }
    }
    .mobile &{
        .exporo-select{
            min-width: auto;
        }

    }
}
