














































h2 {
    opacity: 0;
    animation: fade-in .3s forwards;
    margin-bottom: 25px;
}

section {
    margin-top: 40px;
}


@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

