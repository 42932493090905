
























































































































$brand-primary: #1e73be;
$brand-secondary: #274a7f;
$black: #333333;
$background: #f9f9f9;
$color-grey-base: #626262;

/* PRODUCT COLOR PALETTE */
$bestand: #86AC48;
$finanzierung: #3968AF;

.btn {
    &.cta-theme {
        background: white;
        color: $color-grey-base;
        font-size: 12px;
        margin-top: 45px;
        line-height: 21px;
        padding: 10px 50px 10px 30px;
        position: relative;
        text-align: left;

        &:after {
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            box-shadow: none;
            color: $brand-primary;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            width: 2em;
            margin-right: 0;
            text-align: center;
            line-height: 1em;
            margin-left: .5em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\e803';
            text-shadow: none;
            position: absolute;
            right: 0;
            top: 25%;
        }
    }

    &.bestand {
        background: white;
        color: $color-grey-base;

        &:after {
            color: $bestand;
        }
    }

    &.finanzierung {
        background: white;
        color: $color-grey-base;

        &:after {
            color: $finanzierung;
        }
    }
}
