




















































































































































































































































































































.investor-create-root {
    color: #626262;
    padding: 1em;

    /deep/ .tab {
        &__menue {
            li {
                display: inline-block;
                font-family: 'Oswald', sans-serif;
                font-size: 18px;
                list-style: none;
                margin: 0;
                padding: 1em 2em;
                text-transform: uppercase;
                transition: all 0.4s;

                &:first-child {
                    padding-left: 0;
                }

                &:before {
                    content: "";
                }

                &:hover,
                &.active {
                    text-align: center;

                    a {
                        color: var(--color-branding-dark, #274a7f);
                    }

                    &:after {
                        background: var(--color-branding-dark, #274a7f);
                        content: "";
                        display: block;
                        height: 2px;
                        margin: 0.5em auto;
                        width: 30px;
                    }
                }

                a {
                    color: #91908f;
                    font-weight: 400;
                }
            }
        }

        &__content {
            padding: 0.5em 2em;
        }
    }

    .description {
        display: block;
        margin-bottom: 15px;
        padding: 0 1em 0 0;

        & .btn {
            right: -17px;
        }

        &-highlight {
            background: #b8c1ce;
            border-radius: 3px;
            color: white;
            font-weight: bold;
            padding: 1em 3em 1em 1em;

            &:after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                border-top: 2px solid white;
                border-right: 2px solid white;
                transform: rotate(45deg);
                float: right;
                margin-right: -2em;
                margin-top: 5px;
            }
        }
    }

    a.description-highlight {
        cursor: pointer;
        color: white;

        &:hover {
            background: darken(#b8c1ce, 2);
            color: white;
        }
    }

    /deep/ .form-group {
        align-items: center;
        display: flex;
        margin-bottom: 15px;

        & > * {
            flex: 2;
        }

        & > div {
            display: flex;

            & > * {
                flex: 1;
                margin-left: 10px;

                &.flex-2 {
                    flex: 2;
                }
            }

            & > :first-child {
                margin-left: 0;
            }
        }

        label {
            flex: 1;
            font-family: 'Oswald', sans-serif;
            font-weight: 400;
            font-size: 18px;
            color: #86909d;
        }

        input[type="radio"],
        input[type="checkbox"] {
            ~ label {
                font-family: 'Open Sans', sans-serif;
                color: #91908f;
            }

        }

        &__inline {
            display: block;

            & > *,
            & > div {
                display: inline-block;

                ~ * {
                    margin-left: 3em;
                }
            }
        }
    }

    .btn-container {
        text-align: right;

        .btn {
            display: inline-block;
            background: var(--color-branding-dark, #274a7f);
            position: relative;
            border-radius: 50px;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 1px;
            line-height: 10px;
            padding: 16px 30px;
            text-decoration: none;
            text-transform: uppercase;
            transition: all .5s ease-out;
        }
    }

    .error-msg {
        color: red;
        font-size: 0;
        height: 0;
        opacity: 0;
        transition: all 0.4s;

        &.active {
            align-items: center;
            display: flex;
            background: #d75139;
            border-radius: 3px;
            color: white;
            font-size: 14px;
            font-weight: bold;
            height: 100%;
            margin-bottom: 15px;
            padding: 1em 1em 1em 3em;
            opacity: 1;

            &:before {
                content: '\26A0';
                font-size: 30px;
                width: 1.5em;
                margin-left: -1em;
            }
        }
    }

    &.mobile {
        padding: 0.5em;
        overflow: hidden;

        /deep/ .tab {
            &__menue {
                li {
                    padding-right: 0.5em;

                    & + li {
                        padding-left: 0.5em;
                    }
                }
            }

            &__content {
                padding: 0.5em;
            }
        }

        .form-group {
            display: block;
        }

        label:empty {
            margin: 0;
        }

        /*.scrollable-inside{
            display: block;
            max-height:100vh;
            overflow-y: scroll;
            margin-right: -10px;
            padding-right: 10px;
        }*/
    }

    @media screen and (max-width: 900px) {
        padding: 0.5em;
        overflow: hidden;
        /deep/ .tab {
            &__menue {
                li {
                    padding-right: 0.5em;

                    & + li {
                        padding-left: 0.5em;
                    }
                }
            }

            &__content {
                padding: 0.5em;
            }
        }
        .form-group {
            display: block;
        }
        label:empty {
            margin: 0;
        }
    }
}

.error-message {
    display: block;
    margin-top: -13px;
    margin-bottom: 15px;
    text-align: right;
    color: tomato;
}
