
































































































































































.cockpit-page {
    will-change: opacity, transform;
    z-index: 3;
}

.component-slide {
    &-leave {
        opacity: 1;

        &-to {
            opacity: 0;
        }

        &-active {
            transition: all .25s ease;
        }
    }
}

