


























































































































































.blurred {
    filter: blur(7px);
}
.modal {
    width: 200px;
    height: 200px;
    background-color: yellow;
}


