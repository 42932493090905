<template>
	<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		 viewBox="0 0 425.2 367.3" style="enable-background:new 0 0 425.2 367.3;" xml:space="preserve">
		<g>
	<path class="st0" d="M199.8,262.4c-5.5,0-11.1-2-15.5-6L80.4,162.7c-9.5-8.6-10.3-23.3-1.7-32.8c8.6-9.5,23.3-10.3,32.8-1.7
		l86.9,78.5L323.9,72.6C290.7,30.8,239.3,3.9,181.7,3.9C81.3,3.9,0,85.3,0,185.6s81.3,181.7,181.7,181.7S363.4,286,363.4,185.6
		c0-25.4-5.2-49.6-14.7-71.6l-132,141C212.1,259.9,206,262.4,199.8,262.4z"/>
			<path class="st0" d="M417.9,6.3c-9.4-8.8-24-8.3-32.8,1.1l-61.1,65.3c10,12.6,18.4,26.5,24.8,41.4l70.2-75
		C427.7,29.7,427.2,15,417.9,6.3z"/>
</g>
</svg>
</template>

<script lang="ts">
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

    @Component
    class BulletPoint extends Vue {

        constructor() {
            super();
        }
    }

    export default BulletPoint;
</script>

<style>
	.st0{fill:#D29938;}
</style>

<style lang="scss" scoped>
svg {
	width: 30px;
}
</style>
