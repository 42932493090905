<template>
    <div class="kpi-wrapper">
		<div class="inner">
			<div class="inline">
				<span v-if="value" class="text">{{ value }}</span>
				<span class="desc">{{ name }}</span>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
	import KPIStore from '@/components/KPIS/KPIStore';
	import {isString} from 'util';

    @Component
    class KPIS extends Vue {
    	@Prop({default: ''})
		private type!: string;

		@Prop()
		private name!: string;

		@Prop()
		private euro!: boolean;

    	private value: any = null;

        constructor() {
            super();
        }

        mounted() {
			KPIStore.getInstance().getKPIS(this.type, (kpi: any) => {
				this.value = this.convert(kpi.data);
			});
		}

		convert(value) {
        	let valueAsNumber: number = (Number(value) || 0);
        	let isPercentage: boolean = false;

        	if (valueAsNumber < 1) {
				valueAsNumber = valueAsNumber * 100;
				isPercentage = true;
			}

			let number = valueAsNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

			const numbers = number.split('.');

			if (numbers.length > 3) {

				const after = numbers[1].substring(0, 1);
				number = numbers[0] + (after !== '0' ? `,${after}` : '');
			}

			if (numbers.length >= 5) {
		  	const formatted = valueAsNumber.toLocaleString('de-DE');
				const split = formatted.split('.');
				number = split[0] + '.' + split[1];
			}

			if (Number(number.split('.')[numbers.length - 1]) === 0) {
				number = number.split('.00')[0];
			}

			if (isPercentage) {
				number = number.replace('.', ',');
			}

			if (this.euro) {
				number += ' €';
			}

			return number;
		}
    }

    export default KPIS;
</script>

<style>
	.exporo-kpis {
		display: flex;
		justify-content: space-between;
		position: relative;
		max-width: 1080px;
		margin: 0 auto;
		padding: 0 25px;
	}
</style>

<style lang="scss" scoped>
.kpi-wrapper {
	flex: 1;
	text-align: left;
	padding-right: 40px;

	.inner {
		position: relative;
		padding: 0;
		display: inline-block;
		text-align: left;

		.inline {
			display: inline-block;

			span {
				display: block;
				margin-bottom: 0;
				margin-top: 0;
				text-align: left;
				text-transform: none;
				font-family: 'Oswald', sans-serif;
				color: #626262;

				&.text {
					font-size: 40px;
					font-weight: 400;
					letter-spacing: 2px;
					line-height: 50px;
					max-width: 100%;
				}

				&.desc {
					font-size: 14px;
					font-weight: 400;
					letter-spacing: 1px;
					text-transform: uppercase;
				}
			}
		}
	}
}
</style>
