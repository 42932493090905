



























































.exporo-toast {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999999;
    cursor: pointer;

    .success {
        .error-msg.active {
            background: #86ac48;

            &:before {
                content: '\2714';
            }
        }
        .toast-progress {
            span {
                background: darken(#86ac48, 10);
            }
        }
    }

    .toast-message {
        color: #ccc;
        width: 100%;
        font-family: "Ubuntu Mono", sans-serif;
        min-width: 200px;
    }

    .toast-progress {
        height: 5px;
        width: 100%;
        background: #d3dad3;
        margin-top: -5px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;

        span {
            height: inherit;
            background: darken(#d75139, 10);
            display: block;
        }
    }

}


