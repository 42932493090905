





































































































































































































































































































/deep/ .right-arrow {
  position: absolute;
  top: 12px;
  left: 100%;
  margin-left: -50px;
}

.exporo-cockpit-content {
  position: relative;
  height: 100%;
  width: 100%;
  background: #f2f4f7;
  transition-duration: 400ms;
  transform: translateY(100%);
  z-index: 3;

  &.open {
    height: 100%;
    transform: translateY(0.0001%);
  }

  .dropbar {
    display: none;
  }

  @media screen and (max-width: 800px) {
    /deep/ .right-arrow {
      top: 16px;
      left: 100%;
      margin-left: -34px;
    }

    .exporo-cockpit-tab-bar-root {
      display: none;
    }
    .dropbar {
      display: block;
    }
  }

  .wrapper {
    position: relative;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    transform: translateZ(0.001px);

    &-bar {
      display: flex;
      justify-content: space-between;
      height: 40px;
      position: relative;
      top: -20px;
      z-index: 4;

      .wrapper-two-right-btns {
        .cockpitmenu {
          max-width: 90px;
          float: left;
        }

        .btn {
          float: left;
          height: 105%;
        }
      }
    }
  }
}
