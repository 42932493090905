






























































































.kpi-wrapper {
	flex: 1;
	text-align: left;
	padding-right: 40px;

	.inner {
		position: relative;
		padding: 0;
		display: inline-block;
		text-align: left;

		.inline {
			display: inline-block;

			span {
				display: block;
				margin-bottom: 0;
				margin-top: 0;
				text-align: left;
				text-transform: none;
				font-family: 'Oswald', sans-serif;
				color: #626262;

				&.text {
					font-size: 40px;
					font-weight: 400;
					letter-spacing: 2px;
					line-height: 50px;
					max-width: 100%;
				}

				&.desc {
					font-size: 14px;
					font-weight: 400;
					letter-spacing: 1px;
					text-transform: uppercase;
				}
			}
		}
	}
}
