




































































































































































































.exporo-bond-success {
    .text-center {
        text-align: center;
        margin: 20px auto 10px;
    }

    .suggestion {
        margin: 2em 0;
    }
}

/deep/ .subline {
    display: inline-block;
    font-size: 18px;
    padding: 0 25px;
    text-transform: none;
    width: 100%;
    max-width: initial;
    text-align: center;
    line-height: 1.5;
    margin: 20px auto 30px auto !important;

    &:before {
        border-bottom: 1px solid #1e73be;
        content: '';
        width: 65px;
        left: 50%;
        margin-left: -54px;
        margin-top: -15px;
        position: absolute;
    }
}
