










































.root-passwordchange {
    padding-bottom: 80px;
}

/*h2 {
    opacity: 0;
    animation: fade-in .3s forwards;
}*/

section {
    margin-top: 25px;
    opacity: 0;
    animation: fade-in .3s forwards .3s;
    padding: 1em;
}


@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

