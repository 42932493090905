





















































































































































































.exporo-offer-transaction {
    .block {
        display: block;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;

        /deep/ input[type="number"] {
            min-width: 200px;
        }
    }

    .headline {
        font-family: 'Oswald', sans-serif;
        font-size: 34px;
        color: #2E4B7B;
        line-height: 1.3;
        font-weight: 400;
        text-transform: uppercase;
    }

    label, .exporo-input-root {
        display: inline-block;
    }

    .exporo-input-root {

        margin-left: 20px;
    }

    label {
        width: 400px;
        text-align: left;
        @media screen and (max-width: 900px) {
            width: unset;
        }
    }

    .btn, small {
        float: right;
        display: block;
        margin-bottom: 20px;
    }
    button.btn {
        padding: 16px 30px;
    }

    .btn {
        color: white;

        &-disabled {
            background-color: #ccc;
        }
    }

    .error-msg {
        color: red;
        text-align: end;
    }

    .border {
        border-radius: 5px;
        padding-left: 2em;
        border: 1px solid;
    }

    .border-danger {
        border-color: #dc3545;
    }

    .text-danger {
        color: #dc3545;
    }
}
