


















































.exporo-invest-list-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    opacity: 0;
    animation: fade-in .3s forwards;
    z-index: 2;
    position: relative;
    flex-wrap: wrap;

    h2 {
        font-size: 24px;
        text-transform: none;
        line-height: 1.3;
        margin-bottom: 5px;
        opacity: 0;
        animation: fade-in .3s forwards;
    }

    .list-options {
        display: flex;
        // max-height: 60px;
        flex-direction: column;

        div {
            position: relative;
            margin: 3px;

            &:nth-child(1) {
                z-index: 4;
            }

            &:nth-child(2) {
                z-index: 3;
            }

            &:nth-child(3) {
                z-index: 2;
            }

            &:nth-child(4) {
                z-index: 1;
            }

            &:first-child {
                margin-left: 0
            }

            &:last-child {
                margin-right: 0
            }
        }

    }
}

@media (max-width: 960px) {

    h2 {
        text-align: center;
    }

    .list-options {
        margin-top: 8px;
        justify-content: center;
    }

    .exporo-invest-list-menu {

        flex-direction: column;
    }
}


@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

