






























.hero {
    position: relative;
    margin: auto;
}

.panel {
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    background-color: #fff;
    margin-top: 35px;

    &-content {
        transition: all .8s ease;
        overflow: hidden;
        padding: 45px 60px;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: .3px;

        &-footer {
            padding-top: 10px;
        }

        &-closed {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

}

.headline {
    font-size: 25px;
    font-family: "Oswald", sans-serif;
    padding-left: 40px;
    text-transform: uppercase;
    color: #fff;
    max-width: 270px;
    line-height: 1.4;
    animation: slidedown .3s forwards .1s;
}

@media screen and (max-width: 800px) {
    .panel {

        &-content {
            padding-left: 20px;
            padding-right: 20px;

            .is-expanded & {
                padding-left: 20px;
                padding-right: 20px;
            }

        }
    }
}
