



















.error-msg{
    font-size: 0;
    height:0;
    opacity: 0;
    transition: all 0.4s;
    &.active{
        align-items: center;
        display: flex;
        background: #d75139;
        border-radius: 3px;
        color: white;
        font-size: 14px;
        font-weight: bold;
        height: 100%;
        padding: 1em 1em 1em 3em;
        opacity: 1;
        &:before {
            content: '\26A0';
            font-size: 30px;
            width: 1.5em;
            margin-left: -1em;
        }
    }
}
