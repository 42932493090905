


























































































































































































































































.tab {
  &__menue {
    text-align: center;

    li {
      display: inline-block;
      font-family: 'Oswald', sans-serif;
      font-size: 18px;
      list-style: none;
      margin: 0;
      padding: 0 2em 1em;
      text-transform: uppercase;
      transition: all 0.4s;

      &:first-child {
        padding-left: 0;
      }

      &:before {
        content: "";
      }

      &:hover,
      &.active {
        text-align: center;

        a {
          color: var(--color-branding-dark, #274a7f);
        }

        &:after {
          background: var(--color-branding-dark, #274a7f);
          content: "";
          display: block;
          height: 2px;
          margin: 0.5em auto;
          width: 30px;
        }
      }

      a {
        color: #91908f;
        font-weight: 400;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }

      @media screen and (max-width: 900px) {
        padding-left: 0;
        &:last-child {
          padding-right: 0;
        }
      }
    }

    @media screen and (max-width: 900px) {
      padding-left: 0;
    }
  }

  &__content {
    .form-success {
      display: flex;
      overflow: hidden;
      margin-top: 30px;

      &-side {
        flex: 1;
      }

      &-main {
        flex: 2;
        display: flex;
        justify-content: flex-start;
      }

      &-text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 3px;
        border: 1px solid #1f9a31;
        font-size: 13px;
        color: #1f9a31;
        padding-right: 7px;

        &:before {
          content: "\2713";
          font-size: 18px;
          line-height: 1;
          padding: 3px 6px 3px 5px;
          color: #fff;
          background: #1f9a31;
          display: flex;
          align-items: center;
          margin-right: 7px;
        }
      }
    }
  }
}

/deep/ .btn-container {
  padding: 1em;
  text-align: center;

  button {
    padding: 15px 40px !important;
    text-align: center;

    &:hover {
      background: var(--color-branding-dark, #274a7f);
      color: white;
    }
  }
}

.link-container {
  text-align: center;
}

.error {
  color: tomato;
}
