



























































































.exporo-contact-root {

    #contact-btn {
        display: inline-block;
        background: var(--color-branding, #274a7f);
        position: relative;
        color: var(--color-branding-text, #fff);
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1px;
        line-height: 10px;
        text-decoration: none;
        text-transform: uppercase;
        transition: all .5s ease-out;
        float: right;
        margin-bottom: 20px;
        padding: calc(5px * var(--element-spacing, 3)) calc(10px * var(--element-spacing, 3));
        border-radius: var(--element-radius, 50px);
        border: 1px solid rgba(0, 0, 0, .1);
        &:focus {
            outline: none;
        } 
    }

    h1 {
        color: #838383 !important;
        text-align: left !important;
        padding-left: 10px;
        margin-bottom: 30px !important;
    }

    p {
        padding-left: 10px;
    }

    .form-group {
        input, textarea {
            margin-top: 10px;
            background: transparent;
            box-shadow: none;
            font-size: 14px;
            line-height: 17px;
            margin-right: 0;
            outline: 0;
            padding: 10px;
            max-width: 100%;
            width: 100%;
            border: 1px solid #5f5f5f;
            border-radius: 3px;
        }
    }

    .form:after {
        content: "";
        clear: both;
        display: block;
    }
}
