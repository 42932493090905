



























.dots {
    display: block;
    width: 100%;
    text-align: center;
    bottom: 20px;

    span {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        opacity: 0.2;
        display: inline-block;
        margin: 0 5px;
        cursor: pointer;
        transition: opacity 0.4s ease-in-out,width 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        background-color: #616161;

        &.active {
            opacity: 1;
        }
    }
}

