
































.btn {
    @media screen and (max-width: 370px){
        display: none;
    }
    padding-top: 3px;
    position: relative;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.icon-wrapper {
    margin-top: 3px;
    width: 40px;
    height: 40px;
    z-index: 999;
}

.text-wrapper{
    flex: -1;
}

.btn{
    padding: 0px 30px 0px 3px;
}

.btn.mobile {
    padding: 0px;
}
.cls-1{fill:#274a7f;}
.cls-2{fill:#fff;}
.cls-3{fill:#e2b226;}
.cls-4{fill:#3d4c84;}
.cls-5{fill:none;}
