









































.button {
    font-size: 13px;
    text-transform: uppercase;
    border: 1px solid #dbdbdb;
    display: inline-block;
    border-radius: 30px;
    background-color: #fff;
    color: #626262;
    cursor: pointer;
    box-shadow: none;
    pointer-events: auto;
    transition: background-color .3s ease, box-shadow .3s ease;
    z-index: 2;
    user-select: none;
    position: relative;
    padding-left: 4px;

    &.back:after {
        content: '\21A9';
        color: #000;
        position: absolute;
        right: 7px;
        top: 3px;
        font-size: 16px;
        height: 32px;
        line-height: 32px;
    }

    &.back {
        padding-left: 0;
        .button-inner {
            padding: 8px 30px 6px 15px;
        }
    }

    &:hover {
        .button-inner {
            background: #eff2f5;
        }
    }

    &:active {

        color: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, .12);
        
        .button-inner,
        &:hover .button-inner {
            background-color: #335586;
        }

    }

    &-inner {
        padding: 8px 15px 6px;
        border-radius: 30px;
        transition: all .3s ease;
    }


    &.dot {
        .button-inner {
            &:before {
                transition: all .3s ease;
                display: inline-block;
                background: #375488;
                content: "";
                height: 5px;
                width: 5px;
                border-radius: 10px;
                margin: 0 6px 2px -6px;
            }
        }
        &:active .button-inner {
            &:before {
                background-color: #fff;
            }
        }
    }
    &.chevron {
        .button-inner {
            &:after {
                transition: all .3s ease;
                display: inline-block;
                content: "";
                height: 8px;
                width: 8px;
                margin: -1px -6px 2px 6px;
                transform: rotateZ(45deg);
                border: 0 solid #375488;
                border-radius: 2px;
                border-right-width: 2px;
                border-bottom-width: 2px;
            }
        }
        &.focus .button-inner {
            &:after {
                border-color: #fff;
            }
        }
    }
    &.filled {
        width: 100%;
        text-align: center;
    }
}

