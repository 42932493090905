































svg {
	width: 30px;
}
