




























































































































































































// @TODO Chris: JS Styling
/*/deep/ table.vgt-table {*/
/*    max-width: initial !important;*/
/*}*/

/*/deep/ .vgt-responsive {*/
/*    width: 100vw;*/
/*    overflow: auto;*/
/*}*/

.kpi-panel-wrapper {
    page-break-inside: avoid;
}

.overflow {
    overflow: auto;
}

.kpi-panel-content {
    margin: 0 0 35px 0;
    transition: all .8s ease;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: .3px;
    max-width: 840px;
    width: 840px;
}

.kpi-panel-title {
    font-family: "Oswald", sans-serif;
    font-size: 19px;
    color: #545454;
    max-width: 500px;
    line-height: 1.2;
    letter-spacing: .3px;
    padding-bottom: 30px;
}

/deep/ table.vgt-table {
    display: flex;
    font-size: 12px;
    border-collapse: collapse;
    background-color: #fff;
    width: auto;
    table-layout: auto;
    border: none;
    padding: 0 20px;

    thead th {
        background-color: #FAFAFA;
    }
}

/deep/ thead {
    tr {
        display: block;
        float: left;
        width: auto;
        max-width: 232px;
    }
}

/deep/ tbody {
    tr {
        display: block;
        float: left;
        min-width: 50px;
        width: 150px;
        max-width: 172px;
    }
}


/deep/ th, td {
    display: block
}

/deep/ .vgt-table.bordered th {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    font-weight: 100;

    &:first-child {
        border-bottom: 3px solid #dcdfe6;
        font-weight: 700;
        color: #545454;
    }

    &:nth-child(9) {
        border-top: 2px solid #dcdfe6;
        font-weight: 700;
        color: #545454;
    }

    &:nth-child(12) {
        border-top: 2px solid #dcdfe6;
        font-weight: 700;
        color: rgb(103, 163, 55);
    }

    &:nth-child(13) {
        font-weight: 700;

        color: rgb(103, 163, 55);
    }

    &:nth-child(14) {
        color: rgb(103, 163, 55);
    }
}

/deep/ .vgt-table.bordered td {
    border: none;
    border-bottom: 1px solid #dcdfe6;

    &:first-child {
        border-bottom: 3px solid #dcdfe6;
        font-weight: 700;
        color: #545454;
    }

    &:nth-child(9) {
        border-top: 2px solid #dcdfe6;
        font-weight: 700;
        color: #545454;
    }

    &:nth-child(12) {
        border-top: 2px solid #dcdfe6;
        font-weight: 700;
        color: rgb(103, 163, 55);
    }

    &:nth-child(13) {
        font-weight: 700;
        color: rgb(103, 163, 55);
    }

    &:nth-child(14) {
        color: rgb(103, 163, 55);
    }
}

/deep/ .vgt-right-align {
    text-align: right;
    display: block;
}

/deep/ .vgt-left-align {
    text-align: left;
    display: block;
}

/deep/ .vgt-left-align-bold {
    text-align: left;
    display: block;
    border-top: 2px solid #dcdfe6;
}

/deep/ .bordered th {
    border-bottom: 2px solid #dcdfe6;
    font-weight: 700;
}

/deep/ .text-right {
    text-align: right;
}
