

























































































































































.error-message {
    display: block;
    margin-bottom: 15px;
    text-align: right;
    color: tomato;
}

button {
    margin-top: 1em;
}

.form-group {
    &__flex {
        margin-bottom: 5px;
        margin-top: 15px;
    }
}

.description {
    display: block;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 0 1em 0 0;

    p {
        display: inline-block;
    }

    &-highlight {
        background: #b8c1ce;
        border-radius: 3px;
        color: white;
        font-weight: bold;
        padding: 1em 3em 1em 1em;

        & * {
            color: white !important;
        }
    }

    &-small {
        font-size: 9px;
        margin-top: 10px;
    }
}

