





























































.exporo-progressbartest {
    opacity: 0;
    transition: opacity .1s ease-in;
    &.show {
        opacity: 1; }

    &-info {
        display: flex;
        justify-content: space-between; }

    &-meter {
        height: 10px;
        background-color: #ededf3; }

    &-meterbar {
        height: 100%;
        background-color: #626262; } }


.style-exporo {
    max-width: 400px;
    margin-top: 5px;
    padding: 10px;
    font-family: sans-serif;

    .exporo-progressbartest-info {
        font-size: 16px;
        line-height: 22px;
        font-weight: bold; }
    .exporo-progressbartest-remaining {
        font-size: 11px; }

    .exporo-progressbartest-meter {
        overflow: hidden;
        border-radius: 5px;
        margin-top: 5px; }
    .exporo-progressbartest-meterbar {
        background-color: #86AC48;
        background-image: linear-gradient(-45deg, transparent, transparent 40%, rgba(255, 255, 255, 0.25) 48%, rgba(255, 255, 255, 0.25) 52%, transparent 60%);
        background-size: 500px 14px;
        animation: 20s infinite linear progressbartest; }

    @keyframes progressbartest {
        from {
            background-position: 0 0; }
        to {
            background-position: 2000px 0; } } }


.style-custom {

    .exporo-progressbartest-meterbar {
        background-color: var(--color-branding, #626262); }
    .exporo-progressbartest-meter {
        height: var(--size-height, 10px); } }

