






























































































































































































































.checkout-root{

    .form-group + .validation-error{
        margin-top: -15px;
        + .form-group{
            margin-top: 25px;
        }
    }

    .information{
        display: flex;
        max-width: 100%;
        box-shadow: 0 1px 1px rgba(0,0,0,0.05);
        margin-top: 5px;
        background-color: white;
        background-color: var(--color-background, white);
        border-radius: 3px;
        border: 1px solid #ededf3;
        padding: 1em;
        position: relative;
        a{
            color: #a5afbd;
        }
        &__left{
            width: 50%;
            padding-right: 1em;
            position: relative;
        }
        &__right{
            width: 50%;
            border-left: 1px solid #ededf3;
            padding-left: 1em;
        }
        .container-headline{
            font-weight: bold;
            font-family: 'Oswald', sans-serif;
            font-size: 18px;
            color: #86909d;
        }
        .user-edit{
            position: absolute;
            bottom: 0;
            right: 1em;
            a{
                color: #b8c1ce;
                font-size:10px;
                &:hover{
                    color: var(--color-branding-dark, #a5afbd);
                }
            }
        }
        &-headline{
            text-transform: uppercase;
        }
        &-highlight{
            background: #b8c1ce;
            border-radius: 3px;
            border: none;
            box-shadow: none;
            color: white;
            font-weight: bold;
            padding: 1em 3em 1em 1em;
            a{
                color: white;
            }
        }
        &-container{
            box-shadow: 0 1px 1px rgba(0,0,0,0.05);
            border: 1px solid #ededf3;
            margin-bottom: 20px;
            padding: 1em 0 0;
            .information{
                border :none;
                box-shadow: none;
            }
            &-headline{
                font-weight: bold;
                font-size: 18px;
                text-transform: uppercase;
                text-shadow: none;
                padding-left: 1em;
            }
        }
        + .form-group{
            margin-top: 1em;
        }
    }
    .body-inner .information{
        border: 0;
        box-shadow:none;
        padding: 0;
    }
    .exporo-accordion{
        margin-bottom: 20px;
    }

    &.mobile{
        padding: 0.5em;
        overflow: hidden;
        .information{
            display: block;
            &__left,
            &__right{
                border: none;
                padding-left: 0;
                width: 100%;
            }
            &__right{
                margin-top: 1em;
            }
        }
    }
    @media screen and (max-width: 900px) {
        padding: 0.5em;
        overflow: hidden;
        .information{
            display: block;
            &__left,
            &__right{
                border: none;
                padding-left: 0;
                width: 100%;
            }
            &__right{
                margin-top: 1em;
            }

        }
    }
}

