






































































































































.form-group{
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    .validation-error {
        display: flex;
        margin-bottom: 15px;
        p {
            font-weight: 400;
            display: flex;
            align-items: center;
            border-radius: 3px;
            border: 1px solid #e64c4c;
            margin: 5px 0 0 1px;
            font-size: 13px;

            &:before {
                content: "\26A0";
                font-size: 18px;
                line-height: 1;
                padding: 3px 6px 3px 5px;
                color: #fff;
                background: #e64c4c;
            }
        }
        span {
            padding: 0 7px;
            color: #e64c4c;
        }
    }
    &>*{
        flex:2;
    }
    &>div{
        display: flex;
        &>*{
            flex:1;
            &.flex-2{
                flex: 2;
            }
        }
        &>:first-child{
            margin-left: 0;
        }
    }
    label{
        flex: 1;
        font-family: 'Oswald', sans-serif;
        font-weight: 400;
        font-size: 18px;
        color: #86909d;
    }
    input{
        &:focus::placeholder,
        &::placeholder,
        &:focus::-webkit-input-placeholder,
        &[disabled],
        &::-webkit-input-placeholder{
            color: #91908f;
            opacity: 1;
        }
    }
    input[type="radio"],
    input[type="checkbox"]{
        ~ label{
            font-family: 'Open Sans', sans-serif;
            color: #86909d;
        }

    }
    &__inline{
        display: block;
        &>*,
        &>div{
            display: inline-block;
            ~ *{
                margin-left: 3em;
            }
        }
    }
}
