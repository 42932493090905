






























.cr-instance-container{
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' ><path transform='scale(-0.15, 0.15) rotate(0, 1500, 1500) translate(-800 +300)' fill='black' style='fill-opacity: .05;' d='M146 680q146 0 184-146 38-140-40-302-80-168-224-204-32-8-66-8l0 70q112 0 182 108 54 86 26 146-16 36-62 36-60 0-103 44t-43 106 43 106 103 44z m420 0q146 0 184-146 38-140-40-302-80-168-224-204-32-8-66-8l0 70q112 0 182 108 54 86 26 146-16 36-62 36-60 0-103 44t-43 106 43 106 103 44z'/></svg>");;
    background-repeat: no-repeat;
    background-position: bottom right;
    font-weight: 400;
    * {
        margin: 10px 0;
        font-family: 'Open Sans', sans-serif;
        text-align: center;
    }
    .place {
        font-weight: 400;
        margin-left: 5px;
    }
    .content{
        margin-top: 20px;
        font-style: italic;
    }
    .content.mobile {
        margin-top: 0;
    }
    .author {
        font-weight: 800;
    }
}

