














































































.container {
    width: 70%;
    margin: 0 auto 6px;
    max-width: 100%;
    cursor: default;
}
.progressbar {
    counter-reset: step;

    li {
        list-style-type: none;
        width: 25%;
        float: left;
        font-size: 12px;
        position: relative;
        text-align: center;
        color: #7d7d7d;

        div {
            text-transform: initial;
        }

        &:before {
            width: 25px;
            height: 25px;
            content: counter(step);
            counter-increment: step;
            line-height: 21px;
            border: 2px solid #bbb;
            display: block;
            text-align: center;
            margin: 0 auto 10px auto;
            border-radius: 50%;
            background-color: white;
        }

        &:after {
            width: 70%;
            height: 3px;
            content: '';
            position: absolute;
            background-color: #bbb;
            top: 12px;
            left: -35%;
            border-radius: 20px;
        }

        &:first-child:after {
            content: none;
        }

        .active {
            color: green;
        }
    }

    li.active:before {
        border-color: #55b776;
        content: '\2713';
        background-color: #55b776;
        color: white;
        font-size: 18px;
    }

    li.active + li:after {
        background-color: #55b776;
    }

    li.in-progress + li:after {
        background-image: linear-gradient(90deg, #55b776, #55b776 50%, #bbb 50%);
    }
}


.progress {
    height: 100px;
    border-bottom: 20px solid #fff;

    &:hover {
        background-color: initial;
    }

    &:last-child {
        border-bottom: 0 solid #fff;
    }
}
