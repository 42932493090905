









































.root{
    *{
        display: inline-table;
    }
    .cr-instance{
        width: 40%;
        margin: 0 5% 0 5%;
    }
    .mobile {
        width: 80%;
        margin: 0 10%;
    }
}

