















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/deep/ .text-left {
  text-align: left;
}

/deep/ .text-right {
  text-align: right;
}

.right-arrow {
  margin-left: -40px;

  svg {
    background: #51709c;
    fill: white;
    width: 35px;
    height: 35px;
    border-radius: 18px;
    padding: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: #90a2be;
    }
  }
}

/deep/ .vgt-selection-info-row {
  background: #b8c1ce;
  padding: 5px 16px;
  font-size: 13px;
  border-radius: 4px;
  color: white;
  font-weight: 700;
  margin-bottom: 5px;

  a {
    color: white !important;


    &:hover {
      text-decoration: none !important;
    }

    &:before {
      content: '|';
    }
  }
}

/deep/ .right-action {
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-weight: bolder;
  height: 24px;
  float: left;
  margin-right: 7px;
  margin-top: 0;

  p {
    float: left;
    margin-top: 2px;
    margin-right: 7px;
  }
}

/deep/ .chevron.right:after {
  border-left: 6px solid #274a7f !important;
}


/deep/ .vgt-wrap__footer .footer__navigation__page-btn.disabled .chevron.left:after, .vgt-wrap__footer .footer__navigation__page-btn.disabled:hover .chevron.left:after {
  border-right-color: #606266;
}

/deep/ .vgt-wrap__footer .footer__navigation__page-btn .chevron.left::after {
  border-right: 6px solid #274a7f;
  margin-left: -3px;
}

/deep/ .vgt-table thead th.sorting-desc:after {
  border-top: none !important;
  border-bottom: 0.4em solid white !important;
}

/deep/ .vgt-table thead th.sorting-desc:before {
  border-top: 0.4em solid rgba(255, 255, 255, .5) !important;
  border-bottom: none !important;
}


/deep/ th.sorting-asc:after {
  border-top: none;
  border-bottom: 0.4em solid rgba(255, 255, 255, .5) !important;
}

/deep/ th.sorting-asc:before {
  border-top: 0.4em solid white !important;
  border-bottom: none;
}

/deep/ .vgt-table th:before {
  content: "";
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  right: 6px;
  top: 35%;
  border-top: 0.4em solid rgba(255, 255, 255, .5);
  border-left: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
  border-bottom: none;
  transform: rotate(180deg);
}

/deep/ .vgt-table th:after {
  content: "";
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  right: 6px;
  top: 55%;
  border-top: none;
  border-left: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
  border-bottom: 0.4em solid rgba(255, 255, 255, .5);
  transform: rotate(180deg);
}

/deep/ .vgt-checkbox-col:after,
/deep/ .vgt-checkbox-col:before {
  content: none !important;
}

/deep/ .vgt-checkbox-col:after,
/deep/ .vgt-checkbox-col:before {
  content: none !important;
}

/deep/ table {

  &.vgt-table {
    border: 1px solid #dcdfe6 !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }

  th.vgt-checkbox-col {
    background: transparent !important;
  }

  thead {
    th.sorting-desc, th.sorting {
      color: white;
    }

    th:first-child {
      background: #51709c !important;
      border-bottom: none !important;
    }
  }


  tbody {
    span {
      font-family: Open Sans, sans-serif;
      -webkit-font-smoothing: antialiased;
      color: #626262;
      font-size: 12px;
      letter-spacing: .6px;
      font-weight: 700;
      text-align: left;
    }
  }

  .progress {
    height: 20px;
  }

  img {
    will-change: height;
    -webkit-transition: height .5s ease-in-out;
    transition: height .5s ease-in-out;
    width: 70px;
    height: 50px;
    -o-object-fit: cover;
    object-fit: cover;
    margin: 0 !important;
    border-radius: 3px;
    background-color: #ccc;
  }

  td {
    vertical-align: middle;
    padding: 0;
  }

  .breadcrumb {
    margin: 0 0 0 5px;
    padding: 0;
    list-style: none;
    overflow: hidden;
    font: 12px Sans-Serif;
  }

  .breadcrumb li {
    float: left;
    width: 25%;
  }

  .breadcrumb li a {

    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    border: 1px solid #90a2be;
    width: 100%;
    text-align: center;
    color: white;
    text-decoration: none !important;
    padding: 1px 5px 1px 45px;
    background: #90a2be;
    position: relative;
    display: block;
    float: left;

    &.in-progress {
      background: transparent !important;
      color: #90a2be;
    }
  }

  .breadcrumb li a:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid #90a2be;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;

    &.in-progress {
      border-left: 30px solid transparent !important;
      color: #90a2be;
    }
  }

  .breadcrumb li a:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }

  .breadcrumb li:first-child a {
    padding-left: 10px;
  }

  .breadcrumb li:nth-child(2) a {
    background: darken(#90a2be, 5);

    &.in-progress {
      background: transparent !important;
      color: #90a2be;
    }
  }

  .breadcrumb li:nth-child(2) a:after {
    border-left-color: darken(#90a2be, 5);

    &.in-progress {
      background: transparent !important;
      color: #90a2be;
    }
  }

  .breadcrumb li:nth-child(3) a {
    background: darken(#90a2be, 10);

    &.in-progress {
      background: transparent !important;
      color: #90a2be;
    }
  }

  .breadcrumb li:nth-child(3) a:after {
    border-left-color: darken(#90a2be, 10);

    &.in-progress {
      background: transparent !important;
      color: #90a2be;
    }
  }

  .breadcrumb li:nth-child(4) a {
    background: darken(#90a2be, 15);

    &.in-progress {
      background: transparent !important;
      color: #90a2be;
    }
  }

  .breadcrumb li:nth-child(4) a:after {
    border-left-color: darken(#90a2be, 15);

    &.in-progress {
      background: transparent !important;
      color: #90a2be;
    }
  }

  .breadcrumb li:nth-child(5) a {
    background: darken(#90a2be, 20);

    &.in-progress {
      background: transparent !important;
      color: #90a2be;
    }
  }

  .breadcrumb li:nth-child(5) a:after {
    border-left-color: darken(#90a2be, 20);

    &.in-progress {
      background: transparent !important;
      color: #90a2be;
    }
  }

  .breadcrumb li:last-child a {
    background: darken(#90a2be, 25) !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &.in-progress {
      background: transparent !important;
      color: #90a2be;
    }

    color: white;
    pointer-events: none;
    cursor: default;
  }

  .breadcrumb li:last-child a:after {
    border: 0;
  }

  .breadcrumb li a:hover {
    background: #90a2be;
  }

  .breadcrumb li a:hover:after {
    border-left-color: #90a2be !important;
  }

}

/deep/ .btn-in-table {
  transform: scale(0.7);
  font-size: 14px;
  font-weight: bold;
}

/deep/ .buy-bubble {
  background-color: #335586;
  border-radius: 15px 0 0 0;
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: bold;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/deep/ .table-row-container {
  &:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/deep/ .exporo-invest-list-root {
  width: 100%;
  padding-bottom: 80px;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/deep/ .list-mobile {
  align-items: center;
  margin-top: 10px;

  &-entry {
    border-radius: 5px;
    width: 100%;
    max-width: 720px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: appear 300ms forwards;
    opacity: 0;
    transition: all 150ms ease;
    overflow: hidden;
    margin: auto auto;

    & + & {
      margin-top: 20px;
    }

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        animation-delay: 100ms * $i;
        transition-delay: 100ms * $i;
      }
    }
  }

  &-preview {
    position: relative;
    min-width: 180px;
    height: 120px;

    img {
      display: block;
      // overflow: hidden;
      border-radius: 0 0 0 5px;
      width: 200px;
      height: 130px;
      object-fit: cover;
    }
  }

  &-title {
    position: absolute;
    background-color: rgba(255, 255, 255, .85);
    box-shadow: 0 2px 5px rgba(0, 0, 0, .15);
    border-radius: 50px;
    font-size: 11px;
    font-weight: bold;
    color: #335586;
    top: 7px;
    left: 7px;
    right: 7px;
    padding: 5px 5px;
    text-align: center;
    text-transform: uppercase;
  }

  &-details {
    display: inline-block;
    float: left;
    width: 100%;
    padding: 10px 15px 10px 20px;
    background-color: #fff;
    border-radius: 0 5px 5px 0;
    position: relative;
  }

  &-detail {
    display: inline-block;
    max-width: 100px;
    margin: 0;
  }

  &-value {
    font-size: 16px;
    font-weight: bold;
  }

  &-key {
    font-size: 11px;
    color: #aaa;
    margin-top: 2px;
  }

  &-expand {
    background-color: #335586;
    color: #fff;
    font-size: 20px;
    position: absolute;
    right: -8px;
    bottom: 45px;
    line-height: 1;
    width: 30px;
    height: 30px;
    display: block;
    border-radius: 50px;
    padding: 5px 9px;

    &:before {
      content: "+";
    }
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sort-button {
  position: absolute;
  right: 4px;
  top: 16px;

  &-wrapper {
    width: 20px;
  }
}

/deep/ .vgt-global-search,
/deep/ .vgt-wrap__footer {
  border: none !important;
}

/deep/ .vgt-table thead th {

  vertical-align: middle;

  &:hover {
    background-color: #90a2be;
  }
}

/deep/ table {
  width: 100%;
  border-collapse: unset;
  border-spacing: 0 0;
  border: none !important;

  &-column-container {
    min-width: 300px;
  }

  /deep/ .id-row {
    padding-left: 10px;
  }


  /deep/ thead {
    th {

      user-select: none;
      /*max-width: 170px;*/
      word-break: break-word;
      hyphens: auto;
      /*min-width: 100px;*/
      position: relative;

      &:first-child {
        /*width: 250px;*/
        border-radius: 5px 0 0 0;
      }

      &:last-child {
        border-radius: 0 5px 0 0;
      }

      &.id {
        width: 100px;
        min-width: 100px;
      }

    }

    tr {
      height: 50px;
    }
  }

  tbody {
    user-select: none;

    tr {
      border-radius: 3px;
      cursor: pointer;
      transition: all .3s ease;

      td {
        border: none !important;
        position: relative;
        border-bottom: 1px solid #dcdfe6 !important;

        &:not(:first-child) {
          padding-left: 10px;
          padding-right: 10px;
        }

        span + p {
          margin-left: 15px;
        }
      }

      &:hover td:last-child:after {
        border: 1px solid rgba(0, 0, 0, 0);
        color: #fff;
        transform: scale(1);
        background-color: #375884;
      }


    }
  }

  tr {
    height: 60px;
    text-align: left;
    transition: background-color 2s ease;

    &:hover {
      background-color: #e7eaee;
    }
  }


  tr.open {
    height: 400px;
    width: 72%;
    position: fixed;
    top: 50%;
    margin-top: -200px;
    transition: all 300ms ease;

    .open {
      position: absolute;
      right: 0;
    }

    img {
      width: 200px;
      height: 200px;
    }
  }

  p {
    display: block;
    float: left;
  }

  td {
    p {
      margin: 0;
      line-height: 60px;
      height: 60px;
    }
  }

  div.open {
    width: 40px;
    height: 40px;
    background: #335586;
    color: white;
    font-size: 30px;
    float: right;
    box-shadow: 0 1px 10px 2px #00000059;
    line-height: 36px;
    text-align: center;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 20px;
  }

  th {
    padding-right: 21px;
    height: 40px;
    color: white;
    border: none !important;
    background: #51709c;
    /*text-transform: uppercase;*/
    font-size: 11px;
    letter-spacing: .6px;

    span {
      font-size: 12px;

    }

    &:first-child {
      // width: 200px;
      // border: 1px solid #dcdfe6 !important;
    }

    &:last-child {
      min-width: 130px;
    }

    &:nth-child(even) {
      background-color: #51709c;
    }
  }
}

.info-card {
  // background-color: #fff;
  // border-radius: 10px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  padding: 20px 20px 25px;
  flex-grow: 1;
  max-width: 450px;
  transition: box-shadow .3s ease;
  margin: 40px auto;
  text-align: center;

  &-button {
    margin-top: 20px
  }
}

.mobile-image {
  display: none;
}


@media screen and (max-width: 1200px) {

  .mobile-image {
    display: none;
  }

  table {
    th {
      padding-left: 7px;
      padding-right: 7px;

      &:first-child {
        min-width: auto;
        width: auto;
      }
    }

    td {
      padding-left: 7px;
      padding-right: 7px;
    }

  }

}

@media screen and (max-width: 992px) {

  .mobile-image {
    display: none;
  }

  .list-mobile {
    display: inline;
  }

  table {
    display: none;
  }

}

@media screen and (max-width: 800px) {

  .right-arrow {
    svg {
      width: 26px;
      height: 26px;
    }
  }
}

@media screen and (max-width: 720px) {

  .mobile-image {
    display: none;
  }

  .tablet-image {
    display: none;
  }


  .list-mobile {
    &-entry {
      max-width: 480px;
    }

    &-preview {
      height: 25px;

      img {
        // height: 90px;
      }
    }

    &-details {

      .page-portfolio &,
      .page-financing & {
        grid-template-columns: 1fr 1fr;
      }
    }

    &-value {
      font-size: 14px;
    }

    &-expand {
      right: -5px;
      bottom: -7px;
    }
  }

  [data-key] {
    // display: none;
  }

}


@media screen and (max-width: 720px) {

  .mobile-image {
    display: block !important;
  }

  .tablet-image {
    display: none !important;
  }

  .list-mobile {

    .list-mobile-details {
      // padding: 10px 20px 10px 130px;

      &.payment {
        padding: 10px 20px 10px 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }

      .list-mobile-value {
        font-size: 12px;
      }

      .list-mobile-key {
        font-size: 10px;
      }

      img {
        position: absolute;
        object-fit: cover;
        width: 114px;
        height: 100%;
      }
    }

    &-title {
      position: relative;
      box-shadow: none;
      border-radius: 5px 5px 0 0;
      background-color: #335586;
      color: #fff;
      top: auto;
      left: auto;
      right: auto;
    }
  }
}

@media screen and (max-width: 720px) {


  .list-mobile-title {
    width: 100%;
    z-index: 999;
  }

}

@media only screen and (max-width: 720px) {

  /deep/ thead th {
    min-width: 150px !important;
  }

  /deep/ thead th:first-child {
    min-width: 10px !important;
    background-color: #bfdcf5;
  }

  /deep/ thead th:last-child {
    min-width: 10px !important;
  }

  /deep/ thead th:nth-child(odd) {
    background-color: #90a2be;
  }

  /deep/ table.vgt-table {
    width: 100% !important;
    max-width: 100% !important;
    background-color: transparent;
  }

  /deep/ .vgt-table.bordered th.vgt-row-header {
    background-color: transparent;
  }

}

@media screen and (min-width: 721px) {

  .list-mobile-title {
    width: 21%;
    z-index: 999;
  }

}

.hidden-sm {
  display: none;
}

$width: 100%;
$gutter: 0%;
$breakpoint-small: 33.75em; // 540px
$breakpoint-med: 45em; // 720px
$breakpoint-large: 60em; // 960px

/deep/ .container {
  width: 100%;
  padding: 0;
}

/deep/ .row {
  position: relative;
  width: 100%;
  margin: 0;

  &.inline {
    display: inline-block;
    float: left;
    width: 50%;
  }

  &.inline.small {
    display: inline-block;
    float: left;
    width: 30%;
  }
}

.row [class^="col"] {
  float: left;
  /*margin: 0.5rem 2%;*/
  min-height: 0.125rem;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: $width;
}

.col-1-sm {
  width: ($width / 12) - ($gutter * 11 / 12);
}

.col-2-sm {
  width: ($width / 6) - ($gutter * 10 / 12);
}

.col-3-sm {
  width: ($width / 4) - ($gutter * 9 / 12);
}

.col-4-sm {
  width: ($width / 3) - ($gutter * 8 / 12);
}

.col-5-sm {
  width: ($width / (12 / 5)) - ($gutter * 7 / 12);
}

.col-6-sm {
  width: ($width / 2) - ($gutter * 6 / 12);
}

.col-7-sm {
  width: ($width / (12 / 7)) - ($gutter * 5 / 12);
}

.col-8-sm {
  width: ($width / (12 / 8)) - ($gutter * 4 / 12);
}

.col-9-sm {
  width: ($width / (12 / 9)) - ($gutter * 3 / 12);
}

.col-10-sm {
  width: ($width / (12 / 10)) - ($gutter * 2 / 12);
}

.col-11-sm {
  width: ($width / (12 / 11)) - ($gutter * 1 / 12);
}

.col-12-sm {
  width: $width;
}

@media only screen and (min-width: $breakpoint-med) {
  .col-1 {
    width: ($width / 12) - ($gutter * 11 / 12);
  }
  .col-2 {
    width: ($width / 6) - ($gutter * 10 / 12);
  }
  .col-3 {
    width: ($width / 4) - ($gutter * 9 / 12);
  }
  .col-4 {
    width: ($width / 3) - ($gutter * 8 / 12);
  }
  .col-5 {
    width: ($width / (12 / 5)) - ($gutter * 7 / 12);
  }
  .col-6 {
    width: ($width / 2) - ($gutter * 6 / 12);
  }
  .col-7 {
    width: ($width / (12 / 7)) - ($gutter * 5 / 12);
  }
  .col-8 {
    width: ($width / (12 / 8)) - ($gutter * 4 / 12);
  }
  .col-9 {
    width: ($width / (12 / 9)) - ($gutter * 3 / 12);
  }
  .col-10 {
    width: ($width / (12 / 10)) - ($gutter * 2 / 12);
  }
  .col-11 {
    width: ($width / (12 / 11)) - ($gutter * 1 / 12);
  }
  .col-12 {
    width: $width;
  }

  .hidden-sm {
    display: block;
  }
}

