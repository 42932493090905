


























.investment-plan-20 {
    padding: 20px;
    margin-left: 25px;
}

li {
    font-size: 16px;
    margin-bottom: 10px;
}

.white-text {
    color: white;
}

.btn {
    border-radius: 5px;
    margin: 0 20px 20px 0;
    &--secondary {
        color: black;
        background-color: white;
        border: 1px solid;
        border-color: rgb(30,55,107);
    }
}
