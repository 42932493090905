


















































































































































































.mobile {
    label[for="email"],
    label[for="password"],
    input[type="email"],
    input[type="password"] {
        width: 100% !important;
    }

    .links {
        height: 100px !important;

        a {
            float: inherit !important;
            display: block;
            margin-top: 10px;
        }
    }
}

