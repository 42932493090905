






















































.checkbox-label {
    margin-bottom: 2.5px;
}

.exporo-checkbox-root {
    .exporo-checkbox {
        position: absolute;
        opacity: 0;

    }

    /deep/ input[type='checkbox'] + label {
        color: #626262;
        display: block;
        padding-left: 2.7em;
        position: relative;

        ul {
            margin: 0;
            padding-left: 0;

            li {
                font-size: 12px;
                list-style: square;
                margin: 0;
                margin-left: 15px;
                margin-bottom: 3px;
                padding: 0;

                &:first-child {
                    margin-top: 5px;
                }

                &:before {
                    content: "";
                }
            }
        }
    }

    /deep/ input[type='checkbox']:checked + label {
        color: lighten(#626262, 30);
    }

    /deep/ input[type='checkbox'] + label::before {
        background: #fff;
        border: 1px solid #b8c1ce;
        border-radius: 3px;
        content: "";
        display: block;
        height: 16px;
        vertical-align: text-top;
        width: 16px;
        position: absolute;
        top: 0.2em;
        left: 0.3em;
    }

    /deep/ input[type='checkbox']:checked + label::before,
    /deep/ input[type='checkbox']:not(:checked) + label::before {
        border: 1px solid #b8c1ce;
        background: white;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, .1);
        content: ' ';
    }

    /deep/ input[type='checkbox'] + label {
        margin: 0;
        cursor: pointer;
    }

/deep/ input[type='checkbox']:hover + label::before {

    box-shadow: inset 0 0 1px 0 #626262;
}

/deep/ input[type='checkbox']:checked + label::before {
    background: var(--color-branding-dark, #274a7f);
    box-shadow: inset 0 0 0 4px white;
}

/deep/ input[type="checkbox"][disabled],
/deep/ input[type="checkbox"]:disabled,
/deep/ fieldset[disabled] input[type="checkbox"] {
    cursor: not-allowed;

    + label {
        cursor: not-allowed;
        opacity: 0.8;
    }
}
}

