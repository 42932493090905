<template>
    <div class="exporo-bond-success">
        <h2 class="text-center">{{ trans.get('checkout.VermAnlG.store_success.headline') }}</h2>

        <p v-html="response.text" ></p>

        <div v-if="response.identUrl" >
            <div>
                <p class="font-size-16" v-if="response.identRequired">
                    {{ trans.get('checkout.VermAnlG.store_success.ident_required_description') }}
                </p>
                <p class="font-size-16" v-else>
                    {{ trans.get('checkout.VermAnlG.store_success.description') }}
                </p>

                <div class="clearfix mt-50 text-center">
                    <a ref="identButton" class="btn" :href="response.identUrl" target="_blank">
                        {{ trans.get('checkout.VermAnlG.store_success.ident_button') }}
                    </a>
                </div>
            </div>
        </div>

        <div v-if="!response.identUrl">
            <div id="zl-wrapper"></div>
        </div>
    </div>
</template>

<script lang="ts">
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import {HOST} from '../../Rest/services/RestApiService';
    import ExporoVue from '@/components/ExporoVue';
    import RequestCallback from '@/components/Rest/RequestCallback';
    import RestError from '@/components/Rest/models/RestError';
    import {AxiosResponse} from 'axios';
    import UserRequestApi from '@/components/Rest/requests/UserRequestApi';
    import LoadingAnimation from '@/components/LoadingAnimation/LoadingAnimation.vue';


    interface Zenloop {
        recipient: {};
    }

    @Component({
        components: {
            LoadingAnimation
        }
    })
    class VerAnlGStoreSuccess extends ExporoVue implements RequestCallback {

        @Prop()
        private response!: {investmentId: number, identRequired: boolean, identUrl: string|null, text: string};

        private userRequestApi: UserRequestApi;

        private windowRef: any;

        private identUrl: string | null = null;

        private isLoading: boolean = false;

        constructor() {
            super();

            this.userRequestApi = this.restApi.create(new UserRequestApi());
        }

        getBaseHost() {

            return HOST;
        }

        getIdentUrl() {
            if (this.response.identUrl && null === this.identUrl && false === this.isLoading) {
                this.isLoading = true;
                this.userRequestApi.getIdentUrl(this).addParam('url', this.response.identUrl.split(HOST)[1]);
            }
        }

        mounted() {
            if (this.response.identUrl) {
                const zenloopScript: any = document.createElement('script');
                zenloopScript.onload = () => {
                    const Zenloop: any = (window as any).Zenloop || {};
                    Zenloop.recipient = {
                        identity: `CROWD-${this.response.investmentId}`,
                        identity_type: 'custom'
                    };
                };

                zenloopScript.async = true;
                zenloopScript.setAttribute('src', 'https://zenloop-website-overlay-production.s3.amazonaws.com/loader/zenloop.load.min.js?survey=NWJzME9YV1R3R1FKZ1UrcjVCb1JGMGRscGlPTHVPMkhEa0pjby9OTnN4ND0%3D');
                zenloopScript.setAttribute('id', 'zl-website-overlay-loader');

                const wrapper = document.getElementById('zl-wrapper');
                if (wrapper) {
                    wrapper.appendChild(zenloopScript);
                }
            }
        }

        onFailure(error: RestError, id?: string): void {
            this.isLoading = false;
        }

        onSuccess(response: AxiosResponse, id?: string): void {

            if (UserRequestApi.GET_IDENT_KEY === id && response.data.hasOwnProperty('redirectUrl')) {
                this.identUrl = response.data.redirectUrl;
                const identButton: any = this.$refs.identButton;

                identButton.href = this.identUrl;
                identButton.target = '_blank';
                identButton.click();

                this.isLoading = false;
            }
        }

    }

    export default VerAnlGStoreSuccess;
</script>

<style>
    .zl-score.zl-promoter {
        border-color: #7ec03b !important;
    }

    .zl-score.zl-promoter:hover {
        background-color: #7ec03b !important;
        color: white !important;
    }

    .zl-score.zl-detractor {
        border-color: #d60005 !important;
    }

    .zl-score.zl-detractor:hover {
        background-color: #d60005 !important;
        color: white !important;
    }

    .zl-score.zl-passive {
        border-color: #ffc02c !important;
    }

    .zl-score.zl-passive:hover {
        background-color: #ffc02c !important;
        color: white !important;
    }
</style>

<style lang="scss" scoped>
    .exporo-bond-success {
        .text-center {
            text-align: center;
            margin: 20px auto 10px;
        }

        .suggestion {
            margin: 2em 0;
        }
    }

    .subline {
        display: inline-block;
        font-size: 18px;
        padding: 0 25px;
        text-transform: none;
        width: 100%;
        max-width: initial;
        text-align: center;
        line-height: 1.5;
        margin: 20px auto 30px auto!important;

        &:before {
            border-bottom: 1px solid #1e73be;
            content: '';
            width: 65px;
            left: 50%;
            margin-left: -54px;
            margin-top: -15px;
            position: absolute;
        }
    }
</style>
