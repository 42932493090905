






































































.exporo-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    display: none;

    &.open {
        display: block;
    }
}


.exporo-spinner {
    width: calc(var(--element-spacing, 1) * 300px);
    height: calc(var(--element-spacing, 1) * 300px);
    max-width: 100%;
    margin: 25% auto /* default, if vh fails */;
    margin: 30vh auto;
    display: block;
    &:before {
        opacity: calc(1 - var(--element-exporo-spinner, 1));
        width: calc(100% * (1 - var(--element-exporo-spinner, 1)) );
        height: calc(100% * (1 - var(--element-exporo-spinner, 1)) );
        content: "";
        display: block;
        border-radius: 300px;
        animation: load8 1s infinite linear;
        border-top: 20px solid rgba(255, 255, 255, 0.2);
        border-right: 20px solid rgba(255, 255, 255, 0.2);
        border-bottom: 20px solid rgba(255, 255, 255, 0.2);
        border-left: 20px solid var(--color-branding, #ffffff);
        box-sizing: border-box;
        overflow: hidden;
    }
    svg {
        opacity: var(--element-exporo-spinner, 1);
        width: calc(100% * var(--element-exporo-spinner, 1));
        height: calc(100% * var(--element-exporo-spinner, 1));
        overflow: hidden;

    }
}
.exporo-spinner {
    width: 150px;
    height: auto;
    max-width: 100%;
    margin: 25% auto /*default, if vh fails */;
    margin: 20vh auto;
    display: block;
    &:before {
        content:"";
        display: block;
        width: 300px;
        height: 300px;
        border-radius: 300px;
        animation: load8 1s infinite linear;
        position: absolute;
        border-top: 1.1em solid rgba(255, 255, 255, 0.2);
        border-right: 1.1em solid rgba(255, 255, 255, 0.2);
        border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
        border-left: 1.1em solid #ffffff;

    }
}
/*@keyframes load8 {*/
    /*0% {*/
        /*transform: rotate(0deg);*/
    /*}*/
    /*100% {*/
        /*transform: rotate(360deg);*/
    /*}*/
/*}*/
/*.exporo-spinner .path {*/
    /*stroke-width: 0.3;*/
    /*fill-opacity: 0;*/
    /*stroke-dasharray: 100;*/
    /*stroke-dashoffset: 100;*/
    /*-webkit-animation-name: dashIn;*/
    /*animation-name: dashIn;*/
    /*-webkit-animation-duration: 2s;*/
    /*animation-duration: 2s;*/
    /*-webkit-animation-timing-function: linear;*/
    /*animation-timing-function: linear;*/
    /*-webkit-animation-delay: 0s;*/
    /*animation-delay: 0s;*/
    /*-webkit-animation-direction: alternate;*/
    /*animation-direction: alternate;*/
    /*-webkit-animation-iteration-count: infinite;*/
    /*animation-iteration-count: infinite;*/
    /*-webkit-animation-fill-mode: forwards;*/
    /*animation-fill-mode: forwards;*/
    /*-webkit-animation-play-state: running;*/
    /*animation-play-state: running;*/
/*}*/
/*.alternate .path {*/
    /*-webkit-animation-direction: alternate;*/
    /*animation-direction: alternate;*/
/*}*/
/*.even .path {*/
    /*-webkit-animation-direction: normal;*/
    /*animation-direction: normal;*/
/*}*/
/*.even .path:nth-child(even) {*/
    /*animation-direction: reverse;*/
/*}*/
/*.mix .path {*/
    /*-webkit-animation-direction: alternate;*/
    /*animation-direction: alternate;*/
/*}*/
/*.mix .path:nth-child(1) {*/
    /*-webkit-animation-delay: 0s;*/
    /*animation-delay: 0s;*/
/*}*/
/*.mix .path:nth-child(2) {*/
    /*-webkit-animation-delay: 2s;*/
    /*animation-delay: 2s;*/
/*}*/
/*.mix .path:nth-child(3) {*/
    /*-webkit-animation-delay: 0s;*/
    /*animation-delay: 0s;*/
/*}*/
/*.mix .path:nth-child(4) {*/
    /*-webkit-animation-delay: 3s;*/
    /*animation-delay: 3s;*/
/*}*/
/*.mix .path:nth-child(5) {*/
    /*-webkit-animation-delay: 0s;*/
    /*animation-delay: 0s;*/
/*}*/
/*.mix .path:nth-child(6) {*/
    /*-webkit-animation-delay: 4s;*/
    /*animation-delay: 4s;*/
/*}*/
/*.mix .path:nth-child(7) {*/
    /*-webkit-animation-delay: 0s;*/
    /*animation-delay: 0s;*/
/*}*/
/*.mix .path:nth-child(8) {*/
    /*-webkit-animation-delay: 2s;*/
    /*animation-delay: 2s;*/
/*}*/
/*.mix .path:nth-child(9) {*/
    /*-webkit-animation-delay: 0s;*/
    /*animation-delay: 0s;*/
/*}*/
/*.mix .path:nth-child(10) {*/
    /*-webkit-animation-delay: 1s;*/
    /*animation-delay: 1s;*/
/*}*/
/*.mix .path:nth-child(11) {*/
    /*-webkit-animation-delay: 0s;*/
    /*animation-delay: 0s;*/
/*}*/
/*.mix .path:nth-child(12) {*/
    /*-webkit-animation-delay: 7s;*/
    /*animation-delay: 7s;*/
/*}*/
/*.mix .path:nth-child(13) {*/
    /*-webkit-animation-delay: 0s;*/
    /*animation-delay: 0s;*/
/*}*/
/*.mix .path:nth-child(14) {*/
    /*-webkit-animation-delay: 5s;*/
    /*animation-delay: 5s;*/
/*}*/
/*.mix .path:nth-child(15) {*/
    /*-webkit-animation-delay: 0s;*/
    /*animation-delay: 0s;*/
/*}*/
/*@-webkit-keyframes dashIn {*/
    /*0% {*/
        /*stroke-dashoffset: 100;*/
        /*fill-opacity: 0;*/
        /*stroke-width: 0.3;*/
    /*}*/
    /*15% {*/
        /*stroke-dashoffset: 100;*/
        /*fill-opacity: 0;*/
    /*}*/
    /*20% {*/
        /*fill-opacity: 0;*/
    /*}*/
    /*30% {*/
        /*fill-opacity: 0;*/
    /*}*/
    /*40% {*/
        /*fill-opacity: 0.6;*/
    /*}*/
    /*60% {*/
        /*fill-opacity: 0.8;*/
        /*stroke-width: 0;*/
    /*}*/
    /*80% {*/
        /*fill-opacity: 0.9;*/
    /*}*/
    /*100% {*/
        /*stroke-dashoffset: 0;*/
        /*fill-opacity: 1;*/
        /*stroke-width: 0;*/
    /*}*/
/*}*/
/*@keyframes dashIn {*/
    /*0% {*/
        /*stroke-dashoffset: 100;*/
        /*fill-opacity: 0;*/
        /*stroke-width: 0.3;*/
    /*}*/
    /*15% {*/
        /*stroke-dashoffset: 100;*/
        /*fill-opacity: 0;*/
    /*}*/
    /*20% {*/
        /*fill-opacity: 0;*/
    /*}*/
    /*30% {*/
        /*fill-opacity: 0;*/
    /*}*/
    /*40% {*/
        /*fill-opacity: 0.6;*/
    /*}*/
    /*60% {*/
        /*fill-opacity: 0.8;*/
        /*stroke-width: 0;*/
    /*}*/
    /*80% {*/
        /*fill-opacity: 0.9;*/
    /*}*/
    /*100% {*/
        /*stroke-dashoffset: 0;*/
        /*fill-opacity: 1;*/
        /*stroke-width: 0;*/
    /*}*/
/*}*/

.path {
    stroke-width: .3;
    fill-opacity: 1;
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    /*animation-name: dashIn;*/
    animation-duration: .8s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-play-state: running
}

.path:nth-child(15) {
    animation-delay: 0.45s
}

.path:nth-child(14) {
    animation-delay: 0.42s
}

.path:nth-child(13) {
    animation-delay: 0.39s
}

.path:nth-child(12) {
    animation-delay: 0.36s
}

.path:nth-child(11) {
    animation-delay: 0.33s
}

.path:nth-child(10) {
    animation-delay: 0.3s
}

.path:nth-child(9) {
    animation-delay: 0.27s
}

.path:nth-child(8) {
    animation-delay: 0.24s
}

.path:nth-child(7) {
    animation-delay: 0.21s
}

.path:nth-child(6) {
    animation-delay: 0.18s
}

.path:nth-child(5) {
    animation-delay: 0.15s
}

.path:nth-child(4) {
    animation-delay: 0.12s
}

.path:nth-child(3) {
    animation-delay: 0.09s
}

.path:nth-child(2) {
    animation-delay: 0.06s
}

.path:nth-child(1) {
    animation-delay: 0.03s
}

@keyframes dashIn {
    0% {
        stroke-dashoffset: 100;
        fill-opacity: 0;
        stroke-width: .3
    }

    15% {
        stroke-dashoffset: 100;
        fill-opacity: 0
    }

    20% {
        fill-opacity: 0
    }

    30% {
        fill-opacity: 0
    }

    40% {
        fill-opacity: .6
    }

    60% {
        fill-opacity: .8;
        stroke-width: 0
    }

    80% {
        fill-opacity: .9
    }

    100% {
        stroke-dashoffset: 0;
        fill-opacity: 1;
        stroke-width: 0
    }
}
