
























































































































































































































































































































































































































































































































































































.component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .1s ease;
}

.component-fade-enter, .component-fade-leave-to {
    opacity: 0;
}

.v--modal-overlay .v--modal-background-click {
    min-height: initial !important;
}

.v--modal-box {
    margin: 20px 0 !important;
}

/deep/ .validation-error {
    display: flex;
    margin-bottom: 15px;

    p {
        font-weight: 400;
        display: flex;
        border-radius: 3px;
        border: 1px solid #e64c4c;
        margin: 5px 0 0 1px;
        font-size: 13px;

        &:before {
            content: "\26A0";
            font-size: 18px;
            line-height: 1;
            padding: 3px 6px 3px 5px;
            color: #fff;
            background: #e64c4c;
            display: flex;
            align-items: center;
        }
    }

    span {
        padding: 3px 7px 0;
        color: #e64c4c;
    }

}

.checkout-root {
    font-weight: normal;
    padding-left: 20px;
    padding-right: 40px;
    min-height: 1000px;

    /*/deep/ .checkout-btn {
        background-color: transparent;
        color: #5f5f5f;
        font-size: 15px;
        font-family: "Oswald", sans-serif;

        &.finish {
            border: 1px solid #626262;
            border-radius: 50px;
            font-size: 16px;
            padding: 10px 25px;
            text-transform: uppercase;
            -webkit-transition: .2s;
            transition: .2s;
            background: var(--color-branding-dark, #274a7f);
            color: #fff;
            display: block;
            line-height: 1;
            font-weight: 400;
            letter-spacing: 1px;
            text-decoration: none;
            margin: auto auto;

            .right {
                border: 2px solid #fff;

                &:after {
                    border-top: 2px solid #fff;
                    border-right: 2px solid #fff;
                }
            }
        }

        .left {
            display: inline-block;
            width: 25px;
            height: 25px;
            border: 2px solid #333;
            border-radius: 50%;
            margin-right: 10px;

            &:before {
                content: '';
                display: inline-block;
                width: 8px;
                height: 8px;
                border-top: 2px solid #333;
                border-right: 2px solid #333;
                -moz-transform: rotate(-135deg);
                -webkit-transform: rotate(-135deg);
                transform: rotate(-135deg);
                margin-top: 6px;
                margin-left: -4px;
            }
        }

        .right {
            display: inline-block;
            width: 25px;
            height: 25px;
            border: 2px solid #333;
            border-radius: 50%;
            margin-left: 10px;

            &:after {
                content: '';
                display: inline-block;
                width: 8px;
                height: 8px;
                border-top: 2px solid #333;
                border-right: 2px solid #333;
                -moz-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                margin-top: 6px;
                margin-left: -4px;
            }
        }
    }*/

    &.mobile {
        padding: 0.5em;

        /deep/ .steps h2 {
            max-width: 80%;
        }

        section {

            input[name="vib[signature]"] {
                width: 100%;
                min-width: initial;
            }

            &::before {
                width: 0 !important;
            }

            h2 {
                width: 80% !important;
            }

            .root {
                margin-left: 0 !important;
            }
        }
    }

    @media screen and (max-width: 900px) {
        padding: 0.5em;

        /deep/ .steps h2 {
            max-width: 80%;
        }
        section {

            input[name="vib[signature]"] {
                width: 100%;
                min-width: initial;
            }

            &::before {
                width: 0 !important;
            }

            h2 {
                width: 80% !important;
            }

            .root {
                margin-left: 0 !important;
            }
        }
    }

    h1 {
        font-family: 'Oswald', sans-serif;
        font-weight: 400;
        font-size: 20px !important;
        text-transform: uppercase;
        margin-top: 20px;
        color: var(--color-branding-dark, #274a7f);
        line-height: 1.1;
        -webkit-font-smoothing: antialiased;
        text-align: left;
    }

    /deep/ strong {
        font-weight: bold;
    }

    /deep/ section.step-divider {
        position: relative;
        margin-bottom: -35px;
        padding-bottom: 30px;
    }

    /deep/ section > h2 {
        cursor: pointer;
        width: max-content;
    }

    /deep/ section.step-divider:before {
        position: absolute;
        left: 19px;
        content: ' ';
        background-color: #b8c1ce;
        width: 3px;
        height: 100%;
        opacity: 0.5;
    }

    /deep/ .steps {
        position: relative;

        h2 {
            position: relative;
            color: var(--color-branding-dark, #274a7f);
            font-size: 18px !important;
            margin: 10px 50px 25px 50px;

            &:before {
                background-color: #ffffff;
                border-radius: 100%;
                content: '';
                display: block;
                height: 30px;
                margin-left: -45px;
                margin-top: -5px;
                position: absolute;
                top: 0;
                width: 30px;
                border: 1px solid var(--color-branding-dark, #274a7f);
            }

            &:after {
                color: #274a7f;
                content: attr(data-nr);
                font-size: 18px !important;
                left: 0;
                margin-left: -34px;
                margin-top: 0;
                position: absolute;
                top: 0;
            }

            &.active {
                color: var(--color-branding-dark, #274a7f) !important;

                &:before {
                    background-color: var(--color-branding-dark, #274a7f);
                }

                &:after {
                    color: #ffffff;
                }
            }
        }
    }

    &.finish {
        min-height: initial;
    }

    .links {
        overflow: auto;
        margin-top: 50px;

        button {
            float: left;
            background-color: var(--color-branding, #1e73be);
            color: var(--color-branding-text, #fff);
            border-radius: 0;
            font-size: 12px;
            line-height: 12px;
            min-width: 150px;
            padding: 7px 12px;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: 400;
            cursor: pointer;

            &:hover {
                background-color: var(--color-branding, #274a7f);
            }

            &:focus, &:visited {
                outline-offset: -2px;
            }

            &:first-child {
                float: right;
            }
        }
    }

    #close-btn {
        color: var(--color-branding-dark, #274a7f);
        font-size: 31px;
        position: absolute;
        right: 11px;
        text-decoration: none;
        top: -6px;
        cursor: pointer;
    }

    /deep/ p {
        color: #626262;
        line-height: 1.5;
    }

    /deep/ .form-group {
        margin-bottom: 20px;
        overflow: unset;

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        .error {
            display: block;
            color: red;
            // margin-top: 40px;
        }
    }

    /deep/ .form-group__flex {
        align-items: center;
        display: flex;
        margin-bottom: 15px;

        & > * {
            flex: 2;
        }

        & > div {
            display: flex;

            & > * {
                flex: 1;

                &.flex-2 {
                    flex: 2;
                }
            }
        }

        label {
            flex: 1;
            font-family: 'Oswald', sans-serif;
            font-weight: 400;
            font-size: 18px;
            color: #86909d;
        }

        input[type="radio"],
        input[type="checkbox"] {
            ~ label {
                font-family: 'Open Sans', sans-serif;
                color: #86909d;
            }

        }

        &__inline {
            display: block;

            & > *,
            & > div {
                display: inline-block;

                ~ * {
                    margin-left: 3em;
                }
            }
        }
    }

    /deep/ .description {
        display: block;
        margin-bottom: 20px;
        margin-top: 20px;
        padding: 0 1em 0 0;

        p {
            display: inline-block;
        }

        &-highlight {
            background: #b8c1ce;
            border-radius: 3px;
            color: white;
            font-weight: bold;
            padding: 1em 3em 1em 1em;

            & * {
                color: white !important;
            }
        }

        &-small {
            font-size: 9px;
        }

        &-medium {
            margin-bottom: 10px;
        }
    }

    /deep/ .ml-50 {
        margin-left: 50px;

        .mobile & {
            margin-left: 0;
        }

        @media screen and (max-width: 900px) {
            margin-left: 0;
        }
    }
}
