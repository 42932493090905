


































































/deep/ .lightbox {
    z-index: 9999999 !important;
}
/deep/ .vgs {
    z-index: 9999999;
}

/deep/ .lightbox .lightbox-close {
    right: 20px;
    font-size: 5rem;
}

/deep/ .lightbox-arrow-right {
    right: 10px;
}

/deep/ .lightbox-arrow-right svg {
    height: 50px;
    width: 50px;
}

/deep/ .lightbox-arrow-right svg circle {
    fill: rgba(20, 20, 20, 1);
}

/deep/ .lightbox-arrow-left {
    left: 10px;
}

/deep/ .lightbox-arrow-left svg {
    height: 50px;
    width: 50px;
}

/deep/ .lightbox-arrow-left svg circle {
    fill: rgba(20, 20, 20, 1);
}
