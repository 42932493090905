












































* {
    user-select: none;
}
ul {
    margin-top: 25px;
    list-style: none;
    font-size: 14px;
    color: #787878;
    line-height: 1.5;
    letter-spacing: .3px;
    padding-left: 0px;

    .download-item {
        margin-bottom: 20px;
        padding-left: 50px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            display: inline-block;
            width: 15px;
            height: 14px;
            left: 0px;
            top: 4px;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAAAXNSR0IArs4c6QAAAfZJREFUKBV9Uk1IVFEUPufdaYZRCAmRQCg3Ek2g49BOHWeQiPHZomWrgjYlSOAiahEELly1UDCirWSrWkTP16JwnBIipjcjtZA2ZVQE0w/B2MOYuafvjlx5SnY359zvnO+793z3MkWW/2LtmDT0FWI6SUIplD4J0XPk825+oGpbvVLlAokMsQWWipWrwjQNIG6xSIQGzRWy6Sm/VD2HfIFI3rbI/kpwTYhnIs37pHwLpCnc5DMLDStvNTgqmh9BZecW+zCX6tn0xfjG1x4QzxfyAx8cbjiTYDmGgLu9Q/ixlyxCT+ud8bOHXq134Ixvhmh6QJKCbWbtjCqtBiHy02Iw5mWbdJxJhmFsKwyfCemJcrl8YJssdMQ2CuuJ0/m+dSI1YgSE5HVSEqdqXb+1qiuPmfuZOPl9M3F4mxyZlZmue6XgkjvS90a0k42369FaLhW21/48gCM5ewiRjpk8JiwfoXbcFlj4tlcMfrm5/vsG84qVRYiO27qINJvdbV/MPgZFH07tkIEZ1xe8lepBFn0CO/OukcXLY729WwZwSNEc5tORKmE2xSR3kExG8VaueNZijjuY2WCRmxb4X4SBD93h9GPb03rfsVxmGj/sBp6lYQv/iHc3OxO7Rtj1q56U1jJNrS8zSwY+pPDX38PMAKL3cIC/V/AvJoe10PZ+ikkAAAAASUVORK5CYII=');
        }
    }

    &.is-download li:before {
        margin-left: 25px;
        width: 18px;
        height: 21px;
        top: -2px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAWCAYAAAAinad/AAAAAXNSR0IArs4c6QAAArBJREFUOBGVVF9IU2EUP+fbvUm1/urrXiKiXsorC4bN6SBDZyE99BpBkQ9ZQVFBb0YUIgRRD/15iIgeQ8ici4g59aFoco0IgmEEgaWUBi6n7t57Ot/Vb21uuvk97Hzn/H7n95193zkXoMyKJsye6KDZW4bmwlp5EhmEuKs8D0BUQqqUg6sRXyVG9wFgPQqaBFssIFCA0Bm3PN6+9uDe2VJ5BWLRVKoKJmY7mXiRhXwyQQhqtkD7Khx7XPoElEHAhxsd6AqHjT8yplZO7E0yuS371xNnwFCgtCvFchjRbwGe4y1NB4ZVzL2zZDKpZ9MixkFXiADeK0KxpU9c3RwgVjtovx2IjwUVxxWbSnvuMhhYDn5oazQCXPIpFnUU0bUEj1tDRi3vny3FUXfQ6Y3HP3ulL2Lx0d2E0LEEur8Ho0Njna2NxlMQ2E6WmEdh2UR0OdJknI2NjIWYdVLxEbEmI7LnpK85As9wFYUtQnSPBSHSUHtfJbG9MzBsNpENMRaoyovzozgn2O/G/kHTRARZevFCPB8JLQkyr5kP7QOEAqHlJEpPpnTsT5jTTNpRrKQi9IDbZISb4glbXUVX2rSe2YQ8d/OrnLaSv6bvmRdeAUhTa7IqBL9VZxc1IvzCd+Z2+3KeuV2vOVRf78uU0uFXFdEh8xFPwen/OP3o8PuzGgp8CUTNOYBgz8ziry6+y7lcLG8zkDDlyx/mq8lfL6SjOSieI9m3GNviogibeX8ln1mw579RoEOwoGlwW3LE0Yb9M4xeKkhYn3PjSLBuQqbkDuF76Obuu7o+HXrN49XKTcyTl9f5kZBxjQf4ZoVi/A7Qkw4ZbUpI5uUqUyLR+McAoH2dkZYSTcoV0Dty4EJbuC6pcpQtElOA/Cz9zGzwoWXtFAg6OPa0tRW/H/P7S76yzPsHG7LxNZdUQX0AAAAASUVORK5CYII=');
    }
}

.list {
    transition: all 1s ease-out;
    margin-top: 25px;
    &-group {
        margin-left: 25px;
    }
}

.chevron {
    &:before {
        transition: all .3s ease;
        position: absolute;
        content: "";
        height: 8px;
        width: 8px;
        margin: 6px 6px 2px 6px;
        transform: rotateZ(-45deg);
        border: 0 solid #375488;
        border-radius: 2px;
        border-right-width: 2px;
        border-bottom-width: 2px;
    }

    &-open:before {
        transition: all .3s ease;
        position: absolute;
        content: "";
        height: 8px;
        width: 8px;
        margin: 6px 6px 2px 6px;
        transform: rotateZ(45deg);
        border: 0 solid #375488;
        border-radius: 2px;
        border-right-width: 2px;
        border-bottom-width: 2px;
    }
}

.headline {
    font-family: "Oswald", sans-serif;
    font-size: 19px;
    color: #545454;
    line-height: 1.2;
    letter-spacing: .3px;
    text-transform: none;
}
